import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { getAllUsers, getUserSummary } from "../../redux/slices/users/thunk";
import style from "./usersummary.module.css";
import notify from "../../hook/useNotification";

const UserSummary = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedUser, setSelectedUser] = useState('0');

  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem('user'))
  let query = `user_id=${loggedUser._id}`;

  const userSummary = useSelector(state => state.usersReducer.usersummary);
  //console.log('loggedUser', loggedUser._id)
  useEffect(() => {
      if (!loggedUser.isAdmin) dispatch(getUserSummary(query));
      dispatch(getAllUsers());
      
  }, []);

  const handleSearch = async () => {
    if(loggedUser?.isAdmin || loggedUser?.is_accountant){
      if(selectedUser == '0') return notify("من فضلك اختر المستخدم", "error")
    }

    //let query = `user_id=${loggedUser._id}`;
    let query = ``;
    if (loggedUser?.isAdmin || loggedUser?.is_accountant) query += `user_id=${selectedUser}`;
    else query += `user_id=${loggedUser._id}`;

    query += `&date_from=${startDate}&date_to=${endDate}`;
    await dispatch(getUserSummary(query));
    //console.log('query', query)
  }
  const cancelSearch = async () => {
    if (loggedUser?.isAdmin || loggedUser?.is_accountant){
      if( selectedUser == '0') return notify("من فضلك اختر المستخدم", "error")
    }

    let query = '';

    if (loggedUser?.isAdmin || loggedUser?.is_accountant) {
        query += `user_id=${selectedUser}`;
    } else {
        query += `user_id=${loggedUser._id}`;
    }

    // Reset start date
    setStartDate('');
    setEndDate('');

    try {
        // Dispatch the action and await the result
        await dispatch(getUserSummary(query));
    } catch (error) {
        // Handle any errors that occur during dispatch
        console.error('Error fetching user summary:', error);
    }
};

  const { sumOfCards, money_for_company, priceOfDeposits, sumOfSalesCommission } = userSummary;
  //console.log(sumOfCards);
  let users = useSelector(state => state.usersReducer.users);

  console.log('users',users);
  
  users = users?.filter(el => el.liquidation_user && !el.isAdmin);

 

  return (
    <div className={style.container}>

      <div className={style.box2}>
        {
          (loggedUser?.isAdmin || loggedUser?.is_accountant) &&
          <div className={style.box2Child2}>
            <div className={`${style.text2} mx-1`}> اسم المستخدم </div>
            <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)} className="inputsReduisAndPadding2 custom-select" >
              <option value={'0'} >   اختر المستخدم </option>
              {
                users?.map((el, i) =>
                  <option  key={i} value={el?._id}> {el?.fullname} </option>
                )
              }
            </select>
          </div>
        }


        <div className= {style.box2Child2}>
          <div className={`${style.text2} mx-1`}>من تاريخ</div>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className={`inputsReduisAndPadding2 ${style.btn2}`} />
        </div>

        <div className={style.box2Child2} >
          <div className={`${style.text2} mx-1`}>إلى تاريخ</div>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className={`inputsReduisAndPadding2 ${style.btn2}`} />
        </div>
        <div className={style.submit} onClick={() => handleSearch()} >فلترة</div>
        <div className={style.submit} onClick={() => cancelSearch()} >الغاء الفلترة</div>
      </div>

      <div className={style.line}></div>

      <div className={style.box4}>
        <div className={style.box4container}>
          <div className={style.box4Child}>
            <div className={style.box4Child1Prag}>
              مجموع العضويات المصدرة المستحقة
            </div>
            <div className={style.box4Child1Card}>
              <span>{sumOfCards}</span> <span>بطاقة</span>
            </div>
          </div>

          <div className={style.box4Child}>
            <div className={style.box4Child2Prag}>
              مجموع المبالغ المستحقة على العضويات
            </div>
            <div className={style.box4Child2Card}>
              <span>{money_for_company}</span> <span>ريال</span>
            </div>
          </div>
        </div>
        <div className={style.box4container}>
          <div className={style.box4Child}>
            <div className={style.box4Child3Prag}>مجموع مبالغ الإيداعات</div>
            <div className={style.box4Child3Card}>
              <span>{priceOfDeposits}</span> <span>ريال</span>
            </div>
          </div>

          <div className={style.box4Child}>
            <div className={style.box4Child4Prag}>
              عمولة المندوب على العضويات المصدرة خلال الفترة
            </div>
            <div className={style.box4Child4Card}>
              <span>{sumOfSalesCommission}</span> <span>ريال</span>
            </div>
          </div>
        </div>
      </div>

      <div className={style.line}></div>
    </div>
  );
};

export default UserSummary;
