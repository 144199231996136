import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FaBars, FaUsers, FaKey, FaRegAddressCard, FaHome } from "react-icons/fa";
import { NavLink, useLocation, Navigate, useSearchParams } from "react-router-dom";
import { AiOutlineSound } from "react-icons/ai";
import { LuMenuSquare } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { FaArrowRightToCity, FaCartShopping } from "react-icons/fa6";
import { MdOutlineMedicalServices, MdOutlineSummarize, MdOutlineVerifiedUser } from "react-icons/md";
import { LuCopyPlus } from "react-icons/lu";
import { GrScorecard } from "react-icons/gr";
import { BiAbacus } from "react-icons/bi";
import { LuFlashlight } from "react-icons/lu";
import { CiMenuBurger, CiWallet } from "react-icons/ci";
import { useSelector } from "react-redux";
import { BsArrowDownUp } from "react-icons/bs";

export default function SideBar() {
  const location = useLocation();
  const { pathname } = location;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const[size,setSize]=useState(window.innerWidth);
  const [searchParams] = useSearchParams();

  //console.log('size',size);

  useEffect(()=>{

    if(size<560){
      setIsOpen(true);
  }
  else setIsOpen(false);
  
    window.addEventListener("resize", ()=>{
      var w = window.innerWidth;
      //console.log('size size size')
      if(size<560){
          setIsOpen(true);
      }
      else setIsOpen(false);

      setSize(window.innerWidth);
    });
   // window.addEventListener
  },[size]);


  // console.log("searchParams.get('section')", searchParams.get('section'));
  // console.log('pathname', pathname);

 

  // const userPermissions = JSON.parse(localStorage.getItem("user"))?.availableRoutesArr;
  const loggedUser=JSON.parse(localStorage.getItem('user')); 

  const{screens}=useSelector(state=>state.screensReducer);

  //console.log('screens',screens);
  // if(userPermissions==undefined)  window.location.href="/";
  //<FaRegAddressCard />
  // console.log("userPermissions",userPermissions);
//if(loggedUser.isAdmin || loggedUser.liquidation_user ||  loggedUser?.is_accountant) {
let routes = []; 
if(!loggedUser?.father_id){
  routes.push(
    {
      path:"/home",
      name:'الرئيسية',
      icon:<FaHome />,
       screenID:"665f0dd470673dba321d15ef"
    },
   { 
      path: "/users",
      name: 'المستخدمين',
      icon: <FaUsers />,
      screenID:"665f0e6f70673dba321d1606"
    },
    {
      path: "/members",
      name: 'العضويات',
      icon: <FaRegAddressCard />,
      screenID:"665f0dee70673dba321d15f3"
    },
    {
      path: "/new-member",
      name: 'عضوية جديدة',
      icon: <LuCopyPlus />,
      screenID:"665f0e0970673dba321d15f7"
    },

    {
      path: "/check-member",
      name: 'التحقق من العضوية',
      icon: <MdOutlineVerifiedUser />,
      screenID:"665f0e2a70673dba321d15fa"
    },
    {
      path:"/deposites",
      name:'الايداعات',
      icon:<GrScorecard />,
      screenID:"665f0e9370673dba321d160d"
    },
    {
      path:"/archive",
      name:'ارشيف الايداعات',
      icon:<BiAbacus />,
      screenID:"665f0eb070673dba321d1616"
    },
    {
      path:"/liquidation?liquidation-type=new",
      name:"التصفية",
      icon:<LuFlashlight />,
      screenID:"665f0f0a70673dba321d1622"
    },
    {
      path: "/usersummary",
      name: 'ملخص المستخدم ',
      icon: <MdOutlineSummarize />,
      screenID:"66cc3ef3750aaa0510bd5c01"
    },
    {
      path:"/wallet",
      name:"المحفظة",
      icon:<CiWallet />,
      screenID:"665f0f9470673dba321d1630"
    },
   
    
    {
      path: "/offers",
      name: 'العروض',
      icon: <LuMenuSquare />,
      screenID:"665f0e3e70673dba321d15fd"

    },
    {
      path: "/orders?requestStatus=new_request",
      name: 'الطلبات',
      icon: <FaCartShopping />,
      screenID:"665f0e4d70673dba321d1600"
    },
    {
      path: "/medical-system",
      name: "الشبكة الطبية",
      icon: <MdOutlineMedicalServices />,
      screenID:"665f0e6070673dba321d1603"
    },
    {
      path: "/permissions",
      name: 'الصلاحيات',
      icon: <FaKey />,
      screenID:"665f0e8470673dba321d1609"
    },
    {
      path: "/setting",
      name: 'الاعدادات',
      icon: <IoSettingsOutline />,
      screenID:"665f0fa570673dba321d1633"
    },
    {
      path: "/setting/banks?section=Bank",
      name: 'اضافة نوع بنك',
      icon: <IoSettingsOutline />,
      class: 'active-yellow',
      key: 'Bank',
      screenID:"665f0fb670673dba321d163a"
    },
    {
      path: "/setting/banks?section=Deposite",
      name: 'اضافة نوع عملية ايداع',
      icon: <IoSettingsOutline />,
      class: 'active-yellow',
      key: 'Deposite',
      screenID:"665f0fc870673dba321d163d"
    },
    {
      path: "/setting/profile?section=Profile",
      name: 'الصفحة الشخصية',
      icon: <IoSettingsOutline />,
      class: 'active-yellow',
      key: 'Profile',
      screenID:""
    },
    {
      path: "/countries",
      name: 'إضافة بلد',
      icon: <FaArrowRightToCity />,
      screenID:"66b9b5355bfa66cb94a1d3bb"
    } 
  )
}
 
  if (loggedUser?.is_accountant) {
    routes.push({
      path: "/withDrawRequests",
      name: 'طلبات السحب',
      icon: <BsArrowDownUp />,
      screenID: ""
    });
  }

  if (loggedUser?.liquidation_user) {
    routes.push(
      {
        path: "/marketing",
        name: 'التسويق بالعمولة',
        icon: <AiOutlineSound />,
        screenID:"665f0f8070673dba321d162d"
      },
    );
  }
  if(loggedUser?.father_id){
    routes.push(
      {
        path:"/home",
        name:'الرئيسية',
        icon:<FaHome />,
         screenID:"665f0dd470673dba321d15ef"
      },
      {
        path: "/members",
        name: 'العضويات',
        icon: <FaRegAddressCard />,
        screenID:"665f0dee70673dba321d15f3"
      },
      {
        path: "/new-member",
        name: 'عضوية جديدة',
        icon: <LuCopyPlus />,
        screenID:"665f0e0970673dba321d15f7"
      },
  
      {
        path: "/check-member",
        name: 'التحقق من العضوية',
        icon: <MdOutlineVerifiedUser />,
        screenID:"665f0e2a70673dba321d15fa"
      },
      {
        path: "/orders?requestStatus=new_request",
        name: 'الطلبات',
        icon: <FaCartShopping />,
        screenID:"665f0e4d70673dba321d1600"
      },
      {
        path: "/setting/profile?section=Profile",
        name: 'الصفحة الشخصية',
        icon: <IoSettingsOutline />,
        class: 'active-yellow',
        key: 'Profile',
        screenID:""
      },
    );
  }

  

  const userPermissions=useSelector(state=>state.permissionsReducer.userScreens);

  if(loggedUser?.isAdmin==false){

    routes=routes?.filter(el=>{
        if(el?.screenID=="") return el;
        let pageGroup=userPermissions?.permissions?.find(ele=>ele?._id==el?.screenID);

        if(pageGroup?._id) return el;
    })
  }
  

  // console.log('userPermissions',userPermissions);

  // let pageGroup=userPermissions?.permissions?.find(el=>el?._id==screenID);

  return (
    <>
       {size<560&&<div
       onClick={()=>setIsOpen(()=>!isOpen)} 
       className="d-flex justify-content-end my-2" style={{cursor:'pointer'}}
       > <CiMenuBurger /> </div> } 

        <div className="d-flex">
      <motion.div
        className={`sidebarMenu ${isOpen ? 'sideBarBurger' : ''} `}
      >
        <section className="routes">
          {routes.map((route, index) =>
            // userPermissions?.find(el=>el.nameEn==route?.name)
            // &&

            <NavLink
              to={route.path}
              key={index}
              className={`link ${(searchParams.get('section') === route?.key) && route?.class ? route?.class : ''}`}
            >
              <div
                className={`icon ${pathname.includes(route.path) ? 'activeicon' : ''} ${(searchParams.get('section') === route?.key) && route?.class ? route?.class : 'activeiconGray'}`}
                style={{ borderLeft: 'none' }}
              >{route.icon}</div>
              <AnimatePresence>
                {true && (
                  <motion.div
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>

          )}
        </section>

      </motion.div>

    </div>
    </>
    
  )
}
