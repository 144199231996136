import { Button, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { addBalanceToWallet } from "../../redux/slices/wallet/thunk";

export default function SendLiquidationModal({ showModal, setShowModal, latestLiquidation, HandleSendLiquidation }) {

    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));


    const handleAddRecordToWallet = async () => {
        try {
            const data = {
                "type": "add",
                "deposits": latestLiquidation?.liquidation_balance_end,
                "sales_id": user?._id
            };
            await dispatch(addBalanceToWallet({ data }));
    
            let liquidationData = {
                status: 'send',
                user_id: user?._id,
                liquidation_balance_end: 0,
                wallet_Data:latestLiquidation?.liquidation_balance_end
            }
    
            HandleSendLiquidation(liquidationData);   
        } catch (error) {
            console.error(error.message)
        }
    }

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} >

            <Modal.Body>
                <div className="label d-block">
                    هل تريد ايداع
                    للمحفظة الخاصة بك
                    <br /> <h5 className="d-flex justify-content-center"> ({latestLiquidation?.liquidation_balance_end}) </h5>
                </div>

                <div className="d-flex justify-content-between">
                    <Button onClick={() => handleAddRecordToWallet()} variant="primary" style={{ backgroundColor: "#0080FB" }}>
                        موافق
                    </Button>

                    <Button onClick={() => {
                        let liquidationData = {
                            status: 'send',
                            user_id: user?._id,
                            // wallet_Data:latestLiquidation?.liquidation_balance_end

                        }
                        HandleSendLiquidation(liquidationData);
                    }} variant="primary" style={{ backgroundColor: "rgba(246, 149, 33, 1)", border: 'none', width: '16%' }}>
                        لا
                    </Button>

                    <Button onClick={() => setShowModal(false)} variant="danger">
                        الغاء
                    </Button>
                </div>

            </Modal.Body>

        </Modal>
    )
}
