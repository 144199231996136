import React, { useEffect, useRef, useState } from 'react';


const CustomSelectInput = ({
  options,
  value,
  onChange,
  placeholder,
  classnam,
  label,
  lableClass,
  labelKey = 'fullname',
  valueKey = 'id',
}) => {
  const [inputValue, setInputValue] = useState(value ? value : '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(()=>options);
  const containerRef = useRef(null);

  console.log('inputValue',inputValue);
  console.log('filteredOptions', filteredOptions);
  console.log('value',value);

  const firstRenderRef = useRef(true);
  useEffect(() => {
    setInputValue(value ? value[labelKey] : '');
  }, [value, labelKey]);

  useEffect(() => {
    // if(firstRenderRef.current==true){
    //   firstRenderRef.current=false;
    //   return;
    // }
    if (!options || options.length === 0) return;

    console.log('options in use effect', options);

    if (inputValue == undefined) {
        setFilteredOptions(options);
    }
    else {
      setFilteredOptions(
        options?.filter(option => {

          // if(option[labelKey]==undefined ) return;

          // console.log('option[labelKey]',option[labelKey]);

          //  if(inputValue=='')  return option[labelKey].toLowerCase();
          //  else
          return option[labelKey]?.toLowerCase()?.includes(inputValue?.toLowerCase());

        }
          // if(option[labelKey]) 
        )
      );
    }


  }, [inputValue, options]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsDropdownOpen(true);

    if (!options.some(option => option[labelKey] === newValue)) {
      onChange(newValue?.trim());
    } else {
      onChange(null);
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option[labelKey]);
    setIsDropdownOpen(false);
    onChange(option);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 100);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleBlur();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // setFilteredOptions(
    //   options.filter(option =>{

    //     if(option[labelKey]==undefined ) return;

    //     console.log('option[labelKey]',option[labelKey]);

    //  //  return option[labelKey].toLowerCase().includes(inputValue.toLowerCase())
    //  return option[labelKey].toLowerCase();

    //   }
    //    // if(option[labelKey]) 
    //   )
    // );

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  return (
    <div className={`d-flex flex-column ${classnam}`} ref={containerRef}>
      <div className={`label ${lableClass}`}>
        {label}
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsDropdownOpen(true)}
        onBlur={handleBlur}
        placeholder={placeholder}
        style={{ height: "45px" }}
      />
       {isDropdownOpen && (
        <ul className="dropdown-list">
          {filteredOptions.length > 0 ? (
            filteredOptions.map(option => (
              <li key={option[valueKey]} onClick={() => handleOptionClick(option)}>
                {option[labelKey]}
              </li>
            ))
          ) : (
            <li>لا يوجد خيارات</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomSelectInput;
