import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getAllDepositesForUser,
  getAllDepositesForUserWithPagination,
} from "../../redux/slices/deposites/thunk";
import Select from "../Utilities/Select";
import { getAllBanks } from "../../redux/slices/banks/thunk";
import { getAllUsers } from "../../redux/slices/users/thunk";
import CustomDropdown from "../Utilities/CustomDropdown";

export default function SearchComponentHeader({
  archive_page,
  pageType = null,
}) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const[searchWord,setSearchWord]=useState('');
  // const[searchType,setSearchType]=useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  // console.log('search.....', location.pathname);
  //console.log('deposite-type',searchParams.get('deposite-type'));

  //const active_search=searchParams.get('deposite-type')

  const handleSearchByDate = async () => {
    console.log("ppppppppppppppppo");
    const userID = user?._id;
    const query = `sales_id=${userID}&startDate=${startDate}&endDate=${endDate}&page=1`;

    searchParams.set("startDate", startDate);
    searchParams.set("endDate", endDate);
    setSearchParams(searchParams);
    await dispatch(getAllDepositesForUserWithPagination(query));
  };

  const canclelFilter = async () => {
    const userID = user?._id;
    const query = `${userID}&page=1`;
    searchParams.delete("deposite-type");
    searchParams.delete("startDate");
    searchParams.delete("endDate");

    setSearchParams(searchParams);

    await dispatch(getAllDepositesForUserWithPagination(query));
  };

  const handleSearchParams = async (type) => {
    searchParams.set("deposite-type", type);
    setSearchParams(searchParams);
    const userID = user?._id;

    let query = `user_id=${userID}&status_of_deposit=${type}&page=1`;
    if(startDate && endDate){
      query =`user_id=${userID}&status_of_deposit=${type}&startDate=${startDate}&endDate=${endDate}&page=1`
    }


    if (user.isAdmin) query = `status_of_deposit=${type}&page=1`;
   
    else{
      if(!user.is_accountant) query = `user_id=${userID}&status_of_deposit=${type}&page=1`;
      else query = `status_of_deposit=${type}&page=1`;
    } 

    await dispatch(getAllDepositesForUserWithPagination(query));
  };

  console.log("archive_page", archive_page);
  return (
    <div>
      <div
        className="d-flex justify-content-between my-3"
        style={{ flexWrap: "wrap" }}
      >
        <div
          className="d-flex align-items-end gap-3"
          style={{ flexWrap: "wrap" }}
        >
          <button
            onClick={() => handleSearchParams("accepted")}
            className={`add_desposite_btn membersBtnsWithHeight ${
              searchParams.get("deposite-type") == "accepted"
                ? "add_bank_btn_active"
                : ""
            } `}
          >
            إيداعات مقبولة
          </button>
          <button
            onClick={() => handleSearchParams("refused")}
            className={`add_desposite_btn membersBtnsWithHeight ${
              searchParams.get("deposite-type") == "refused"
                ? "add_bank_btn_active"
                : ""
            } `}
          >
            {" "}
            إيداعات مرفوضة{" "}
          </button>
        </div>

        <div className="depositesSearchResponsive" style={{ flexWrap: "wrap" }}>
          <div className="d-flex " style={{ gap: "38px", flexWrap: "wrap" }}>
            <div className="d-flex flex-column">
              <div className="title my-1">تاريخ البدء</div>
              <div className="d-flex flex-column">
                <input
                  className="title dateResponsive inputsReduisAndPadding2"
                  type="date"
                  onChange={(e) =>
                    setStartDate(e.target.value.replace(/-/g, "/"))
                  }
                />
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="title my-1" >تاريخ نهاية البحث</div>
              <div className="d-flex flex-column">
                <input
                  className="title dateResponsive inputsReduisAndPadding2"
                  type="date"
                  onChange={(e) =>
                    setEndDate(e.target.value.replace(/-/g, "/"))
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex " style={{ gap: "38px", flexWrap: "wrap" }}>
            {/* لو مش محاسب */}
            {!pageType && (
              <>
                <div className="d-flex flex-column justify-content-end">
                  <button
                    onClick={() => handleSearchByDate()}
                    className="filter-deposite-btn membersBtnsWithHeight"
                  >
                    {" "}
                    فلترة{" "}
                  </button>
                </div>

                <div className="d-flex flex-column justify-content-end">
                  <button
                    onClick={() => canclelFilter()}
                    className="filter-deposite-btn cancel membersBtnsWithHeight"
                  >
                    {" "}
                    الغاء الفلتر{" "}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {pageType == "accountant" && (
        <AccountantComponent
          type={pageType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      )}
    </div>
  );
}

// const ArchiveComponent = ({ archive_page, handleSearchParams, searchParams }) => {
//     return (
//         <div>
//             {
//                 !archive_page &&
//                 <div className="d-flex align-items-end gap-3">
//                     <button
//                         onClick={() => handleSearchParams('accepted')}
//                         className={`add_desposite_btn ${searchParams.get('deposite-type') == 'accepted' ? 'add_bank_btn_active' : ''} `}
//                     >
//                         إيداعات مقبولة
//                     </button>
//                     <button
//                         onClick={() => handleSearchParams('refused')}
//                         className={`add_desposite_btn ${searchParams.get('deposite-type') == 'refused' ? 'add_bank_btn_active' : ''} `}
//                     > إيداعات مرفوضة </button>
//                 </div>
//             }
//         </div>
//     );
// };

const AccountantComponent = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  // console.log('pageType', type);

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [operationType, setOperationType] = useState(null);
  const [bankID, setBankID] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDepositeStatus, setSelectedDepositeStatus] = useState(0);
  const [depositOperationType, setDepositOperationType] = useState(null);

  useEffect(() => {
    dispatch(getAllBanks());
  }, [dispatch]);

  const user = JSON.parse(localStorage.getItem("user"));

  const operationTypes = [
    { _id: "كاش" },
    { _id: "صرافة بنك" },
    { _id: "جهاز شبكة" },
    { _id: "إيداع بنكي" },
    { _id: "تحويل بنكي" },
  ];
  //pending -  accepted  -  refused
  const depositeStatus = [
    { _id: "accepted", nameAr: "مقبول" },
    { _id: "pending", nameAr: "معلق" },
    { _id: "refused", nameAr: "مرفوض" },
  ];
  const { banks } = useSelector((state) => state.banksReducer);
  const users = useSelector((state) => state.usersReducer.users);

  let banks_typeBanks = [];
  banks_typeBanks = banks?.filter((el) => el.settingsType == "bankType");
  const banks_deposite = banks?.filter((el) => el.settingsType === "depositProcessType");


  const handleSearch = async () => {
    // const userID = user?._id;
    let query = `page=1`;

    // setSearchParams(searchParams);

    //start_date=${startDate}&end_date=${endDate}
    if (startDate) {
      query = `${query}&start_date=${startDate}`;
      searchParams.set("startDate", startDate);
    }
    if (endDate) {
      query = `${query}&end_date=${endDate}`;
      searchParams.set("endDate", endDate);
    }
    if (bankID) {
      query = `${query}&bank_id=${bankID}`;
      searchParams.set("bank_id", bankID);
    }
    if (operationType) {
      query = `${query}&operation_type=${operationType}`;
      searchParams.set("operation_type", operationType);
    }
    if (selectedDepositeStatus) {
      searchParams.set("status_of_deposit", selectedDepositeStatus);
      query = `${query}&status_of_deposit=${selectedDepositeStatus}`;
    }
    console.log("selectedUsers", selectedUsers);

    if (selectedUsers.length > 0) {
      console.log("iiiiiiiiiiiiiiiiii");
      searchParams.set("user_id", selectedUsers);
      query = `${query}&user_id=${selectedUsers}`;
    }

    if (searchParams.get("deposite-type") !== null){
      query += `&status_of_deposit=${searchParams.get("deposite-type")}`;
      if(startDate && endDate){
        query +=`&startDate=${startDate}&endDate=${endDate}`
      }
    }

    setSearchParams(searchParams);

    await dispatch(getAllDepositesForUserWithPagination(query));
  };

  const canclelFilter = async () => {
    setOperationType(0);
    setBankID(0);
    setSelectedUsers([]);
    setSelectedDepositeStatus(0);
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    searchParams.delete("user_id");
    searchParams.delete("operation_type");
    searchParams.delete("bank_id");
    searchParams.delete("status_of_deposit");

    searchParams.set("page", 1);

    setSearchParams(searchParams);

    const userID = user?._id;
    let query = `${userID}&page=1`;

    await dispatch(getAllDepositesForUserWithPagination(query));

    searchParams.delete("deposite-type");
    setSearchParams(searchParams);
  };
  console.log("banks_typeBanks", banks_typeBanks);
  console.log("banks_deposite?.filter((el) => el.status === true)",banks_deposite?.filter((el) => el.status === true))

  return (
    <div
      className="d-flex justify-content-between my-3"
      style={{ flexWrap: "wrap", marginBottom: "10px" }}
    >
      <div
        className="d-flex flex-column justify-content-end"
        style={{ marginBottom: "10px" }}
      >
        <Select
          label={"اسم المستخدم"}
          value={selectedUsers}
          onChange={(e) => setSelectedUsers(e.target.value)}
          options={users}
          defaultOption={"اسم المستخدم"}
          labelSelect="fullname"
          width={"153px"}
          classnam={"inputsReduisAndPadding"}
        />
        {/* <label htmlFor="users"> اسم المستخدم </label>
                <select id="users" multiple>
                    {users?.map((el,i)=>
                    <option key={i} value={el?._id} size='5'> {el?.fullname} </option>
                    )}
                </select> */}
      </div>
      <div
        className="d-flex flex-column justify-content-end"
        style={{ marginBottom: "10px" }}
      >
      <Select
          name="offer"
          value={operationType}
          onChange={(e) => {
            const selectedOperation = e.target.value;
            setOperationType(selectedOperation);
            const selectedBank = banks_deposite.find((el) => el._id === selectedOperation);
            if (selectedBank) {
              setDepositOperationType(selectedBank.deposit_operation_type);
            }
          }}
          label="نوع العملية"
          options={banks_deposite?.filter((el) => el.status === true)}
          width="100%"
          labelSelect="nameAr"
          defaultOption="نوع العملية"
          border="1px solid rgba(132, 203, 0, 1)"
          classnam="inputsReduisAndPadding2"
        />
      </div>
      <div
        className="d-flex flex-column justify-content-end"
        style={{ marginBottom: "10px" }}
      >
        {/* <Select
          value={bankID}
          onChange={(e) => setBankID(e.target.value)}
          options={banks_typeBanks}
          label={"البنك"}
          defaultOption={"البنك"}
          width={"153px"}
          classnam={"inputsReduisAndPadding"}
        /> */}
        <div style={{ width: "153px" }}>
          <CustomDropdown
            label="البنك"
            value={bankID}
            onChange={(value) => setBankID(value)}
            options={banks_typeBanks}
            placeholder="البنك"
            labelKey="nameAr"
            valueKey="_id"
            width="153px"
          />
        </div>
      </div>
      <div
        className="d-flex flex-column justify-content-end"
        style={{ marginBottom: "10px" }}
      >
        <Select
          value={selectedDepositeStatus}
          onChange={(e) => setSelectedDepositeStatus(e.target.value)}
          label={"حالة الايداع"}
          defaultOption={"الحالة"}
          options={depositeStatus}
          width={"153px"}
          classnam={"inputsReduisAndPadding"}
        />
      </div>

      <div
        className="d-flex flex-column justify-content-end "
        style={{ marginBottom: "10px" }}
      >
        <button
          onClick={() => handleSearch()}
          className="filter-deposite-btn membersBtnsWithHeight"
        >
          {" "}
          فلترة{" "}
        </button>
      </div>

      <div
        className="d-flex flex-column justify-content-end "
        style={{ marginBottom: "10px" }}
      >
        <button
          onClick={() => canclelFilter()}
          className="filter-deposite-btn cancel membersBtnsWithHeight"
          style={{ width: "132px" }}
        >
          {" "}
          الغاء الفلتر{" "}
        </button>
      </div>
    </div>
  );
};
