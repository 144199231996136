import PrintAndDownload from "../../Utilities/PrintAndDownload";
import UsersHeader from "../../Utilities/UsersHeader";
import AddDepositeForm from "./AddDepositeForm";

export default function AddDepositeComponent({type}) {

  return (
    <div>
      <UsersHeader title={"اضف ايداع جديد"} backgroundColorBtn={'#0080FB'} />
      {/* <div className="d-flex justify-content-end my-4 firstContainer">
        <PrintAndDownload hasExcel={true} uploadLabel="رفع الملف" />
      </div> */}

      <AddDepositeForm type={type} />

    </div>
  )
}
