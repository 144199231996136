import { useDispatch, useSelector } from "react-redux";
import UsersHeader from "../Utilities/UsersHeader";
import DepositesTable from "./DepositesTable";
import SearchComponent from "./SearchComponent";
import { useEffect } from "react";
import { getAllDepositesForUser, getAllDepositesForUserWithPagination } from "../../redux/slices/deposites/thunk";
import { useSearchParams } from "react-router-dom";
import Pagination from "../Utilities/Pagination";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";


export default function AllDepositesComponent({ type }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {

    let page = 1;
    if (searchParams.get('page') == null || searchParams.get('page') == 0) {
      page = 1;
      searchParams.set('page', 1);
      setSearchParams(searchParams);
    }
    else {
      page = searchParams.get('page');
    }

    if (type == 'accountant' || user?.isAdmin) {
      //const userID=user?._id;
      let query = `page=${page}`;
      dispatch(getAllDepositesForUserWithPagination(query));
    }
    else {
      //مندوب
      const userID = user?._id;
      let query = `user_id=${userID}&page=${page}`;
      dispatch(getAllDepositesForUserWithPagination(query));
    }


    searchParams.delete('deposite-type');
    setSearchParams(searchParams);

  }, [dispatch]);

  const deposites = useSelector(state => state.depositesReducer.depositesWithPaginatioon);

  const nextPage = async (page) => {
    if (page == 0) return;

    searchParams.set('page', page);
    setSearchParams(searchParams);
    if (type == 'accountant' || user?.isAdmin) {
      //const userID=user?._id;
      let query = `page=${page}`;

      if (searchParams.get('startDate') !== null) {
        query += `${query}&start_date=${searchParams.get('startDate')}`;
        // searchParams.set('startDate',startDate);
      }
      if (searchParams.get('endDate') !== null) {
        query += `${query}&end_date=${searchParams.get('endDate')}`;
        //searchParams.set('endDate',endDate);
      }
      if (searchParams.get('bank_id') !== null) {
        query += `${query}&bank_id=${searchParams.get('bank_id')}`;
        // searchParams.set('bank_id',bankID);
      }
      if (searchParams.get('operation_type') !== null) {
        query += `${query}&operation_type=${searchParams.get('operation_type')}`;
        //searchParams.set('operation_type',operationType);
      }
      if (searchParams.get('status_of_deposit') !== null) {
        //searchParams.set('status_of_deposit',selectedDepositeStatus);
        query += `${query}&status_of_deposit=${searchParams.get('status_of_deposit')}`;
      }

      if (searchParams.get('user_id') !== null) {
        //console.log('iiiiiiiiiiiiiiiiii')
        //searchParams.set('user_id',selectedUsers);
        query += `${query}&user_id=${searchParams.get('user_id')}`;
      }

      if (searchParams.get('deposite-type') !== null) query += `&status_of_deposit=${searchParams.get('deposite-type')}`;
      dispatch(getAllDepositesForUserWithPagination(query));
    }
    else {
      //مندوب
      const userID = user?._id;
      let query = `user_id=${userID}&page=${page}`;
     // let query = `page=${page}&`;

      if (searchParams.get('startDate') !== null) {
        query =+ `${query}&start_date=${searchParams.get('startDate')}`;
        // searchParams.set('startDate',startDate);
      }
      if (searchParams.get('endDate') !== null) {
        query =+ `${query}&end_date=${searchParams.get('endDate')}`;
        //searchParams.set('endDate',endDate);
      }
      if (searchParams.get('bank_id') !== null) {
        query =+ `${query}&bank_id=${searchParams.get('bank_id')}`;
        // searchParams.set('bank_id',bankID);
      }
      if (searchParams.get('operation_type') !== null) {
        query =+ `${query}&operation_type=${searchParams.get('operation_type')}`;
        //searchParams.set('operation_type',operationType);
      }
      if (searchParams.get('status_of_deposit') !== null) {
        //searchParams.set('status_of_deposit',selectedDepositeStatus);
        query =+ `${query}&status_of_deposit=${searchParams.get('status_of_deposit')}`;
      }

      // if (searchParams.get('user_id') !== null) {
      //   //console.log('iiiiiiiiiiiiiiiiii')
      //   //searchParams.set('user_id',selectedUsers);
      //   query = `${query}&user_id=${searchParams.get('user_id')}`;
      // }

      if (searchParams.get('deposite-type') !== null) query += `&status_of_deposit=${searchParams.get('deposite-type')}`;

      dispatch(getAllDepositesForUserWithPagination(query));
    }
  }

  const screenID="665f0e9370673dba321d160d";
   const loggedUser = JSON.parse(localStorage.getItem('user'));
   let hasEdit = false;
  let hasAdd = false;

  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);


  //  let access = false;
  //  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  //  else access=true;


  console.log('deposites', deposites);
  let navigate_url = 'add';
  // let navigate_url= type=='accountant' ? '/deposites/accountant/add' : 'add'
  return (
    <div>
      {/* {type=='accountant'&& <h5> </h5>} */}
      {
        hasAdd!=undefined&& <UsersHeader hasAdd={hasAdd} title={"اضف ايداع جديد"} navigateTo={navigate_url} backgroundColorBtn={'rgba(254, 170, 0, 1)'} />

      }
      <div className="permissionsLayout mt-3">
        <SearchComponent deposites={deposites?.data} pageType={type} />
        <div style={{ overflow: "auto" }}>
          <DepositesTable type={'deposites'} deposites={deposites} pageType={type} hasEdit={hasEdit}/>
        </div>

        <div>
          <Pagination onPress={nextPage} pageCount={deposites?.num_of_pages} />
        </div>
      </div>
    </div>
  )
}
