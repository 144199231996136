import { useDispatch, useSelector } from "react-redux";
import HeadSection from "../Utilities/HeadSection";
import { useEffect } from "react";
import { getAllWithDrawsForAccountant } from "../../redux/slices/withDraws/thunk";
import WithDrawsTable from "./WithDrawsTable";

export default function AccountantWithDrwasComponent() {
    const dispatch=useDispatch();
    const loggedUser= JSON.parse(localStorage.getItem('user'));

    useEffect(()=>{
        let query=`accountant_id=${loggedUser?._id}`;
        dispatch(getAllWithDrawsForAccountant(query));
    },[]);
    
    const{withDraws}=useSelector(state=>state.withDrawsReducer);
    console.log('withDraws',withDraws);
  return (
    <div className="firstContainer mx-2">
        <HeadSection label={"طلبات السحب"} />
        <WithDrawsTable withDraws={withDraws} />
    </div>
  )
}
