
export default function BtnGroup({ onSubmit, handleClose, hasAdd, hasEdit }) {
    return (
        <div className="d-flex justify-content-center mt-3" style={{ gap: '64px' }}>
            {hasAdd && <button className="customButton save" onClick={() => onSubmit()}>حفظ</button>}
           {hasEdit&& <button className="customButton edit" onClick={() => onSubmit(true)}>تعديل</button>} 
            <button className="customButton close" onClick={() => handleClose()}>اغلاق</button>
        </div>
    )
}
