import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useInsertData } from "../../../hooks/useInsertData";
import { useUpdateData } from "../../../hooks/useUpdateData";


//get countries
export const getAllCountries=createAsyncThunk(
    "/citiesSlice/countries",
    async()=>{
        try {
            const response=await useGetDataToken("/api/v1/countries");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return error;
        }
    }
);
// export const getoneCountryById=createAsyncThunk(
//     "/citiesSlice/country",
//     async()=>{
//         try {
//             const response=await useGetDataToken("/api/v1/countries/id");
//             return response;
//         } catch (error) {
//             if (error.message == "Network Error")
//                 return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
//             else
//                 return error;
//         }
//     }
// );

export const addCountry = createAsyncThunk(
    'citiesSlice/addCountry',
    async({data})=>{
        try{
            const response = await useInsertData("/api/v1/countries", data);
            notify("تم الاضافة بنجاح", "success");
            //const response=await useGetDataToken("/api/v1/countries");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return error;
        }
        }
  );
//get all cities
export const getAllCities=createAsyncThunk(
    "/citiesSlice/cities",
    async (countryID='')=>{
        try {
            if(countryID=='') return [];
        const response=await useGetDataToken(`/api/v1/cities?country=${countryID}`);
        return response;            
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return error;
        }
    }
);
//add city
export const addCity=createAsyncThunk(
    "/citiesSlice/addCity",
    async ({data})=>{
        try {
            
        const response=await useInsertData(`/api/v1/cities`, data);
        notify("تم الاضافة بنجاح", "success");

        return response;            
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return error;
        }
    }
);
//edit city
export const editCity=createAsyncThunk(
    "/citiesSlice/editCity",
    async ({data, id})=>{
        try {
        const response=await useUpdateData(`/api/v1/cities/${id}`, data);
        notify("تم التعديل بنجاح", "success");
        return response;            
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return error;
        }
    }
);

//reset cities
export const resetCities=createAsyncThunk(
    "/citiesSlice/resetCities",
    async()=>{
        try {
            //
            const cities=[];
            return cities;

        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return error;
            }
        }
);
export const editCountry =createAsyncThunk(
    "/citiesSlice/editCountry",
    async({id,data})=>{
        try {
            const response = await useUpdateData(`/api/v1/countries/${id}`, data);
            notify("تم التعديل بنجاح  ", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.message, "error");
        }
    }
);

