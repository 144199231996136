import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useInsertData } from "../../../hooks/useInsertData";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";

export const addOneMemberShip = createAsyncThunk(
  "/membersSlice/addOne",
  async ({ data }) => {
    try {
      const response = await useInsertData("/api/v1/memberships", data);
      notify("تم الاضافة  بنجاح", "success");

      //console.log('response add',response);
      return { ...response, main_card: data?.main_card };
    } catch (error) {
      //console.log('error......',error);
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data, "error");
    }
  }
);

export const getAllMembers = createAsyncThunk(
  "/membersSlice/getAll",
  async (query = "") => {
    try {
      const response = await useGetDataToken(`/api/v1/memberships?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data, "error");
    }
  }
);

export const verifyMemberShip = createAsyncThunk(
  "/membersSlice/verify",
  async ({ data }) => {
    try {
      // console.log('data',data);
      const response = await useInsertData(
        "/api/v1/memberships/verifyMembership",
        data
      );
      if (response?._id)
        notify("تم هذه العضوية موجودة بالفعل تم التحقق بنجاح", "success");

      return response;
    } catch (error) {
      //console.log(error.response);
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data.message, "error");
    }
  }
);


export const renewMemberShip = createAsyncThunk(
  "/membersSlice/renew",
  async ({ data }) => {
    try {
      const response = await useInsertData(
        "/api/v1/memberships/renewMembership",
        data
      );
      if (response?._id) notify("تم تحديث العضوية بنجاح", "success");
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data.message, "error");
    }
  }
);

export const exportMemberShip = createAsyncThunk(
  "/membersSlice/exportOneMemberShip",
  async ({ data }) => {
    try {
      console.log("data", data);

      const response = await useInsertData(
        "/api/v1/memberships/exportMembership",
        data
      );
      console.log("response",response)
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data.message, "error");
    }
  }
);

export const getReceipt = createAsyncThunk(
  "/membersSlice/getReceipt",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/home/receipt/${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data, "error");
    }
  }
);
export const getReport = createAsyncThunk(
  "/membersSlice/getReport",
  async (query) => {
    try {
      const response = await useGetDataToken(
        `/api/v1/memberships/salesReport?${query}`
      );
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data, "error");
    }
  }
);

export const updateMemberShip = createAsyncThunk(
  "/membersSlice/updateMemberShip",
  async ({ data, id }) => {
    try {
      console.log("data",data, id);
      const response = await useUpdateData(`/api/v1/memberships/${id}`, data);
      notify("تم التعديل بنجاح", "success");
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data,"error");
    }
  }
);

export const resetVerifiedMemberShip = createAsyncThunk(
  "/membersSlice/resetVerifiedMemberShip",
  async () => {
    const verifiedMemberShip = null;
    return verifiedMemberShip;
  }
);


export const getAllMembersWithPagination = createAsyncThunk(
  "/membersSlice/getAllMembersWithPagination",
  async (query = "") => {
    try {
      if(query=='?') return;
      const response = await useGetDataToken(`/api/v1/memberships?${query}`);
      return response;
    } catch (error) {
      if (error.message == "Network Error")
        return notify(
          "حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ",
          "error"
        );
      else return notify(error.response.data, "error");
    }
  }
);
export const resetNewMambership = createAsyncThunk(
  "/membersSlice/resetNewMambership",
  () => {
    const response=null;

    return response;
  }
)