import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useInsertData } from "../../../hooks/useInsertData";
import { useUpdateData } from "../../../hooks/useUpdateData";

const user=JSON.parse(localStorage.getItem('user'));

export const getAllOffers = createAsyncThunk(
    "/offersSlice/offers",
    async (query,editPage=false) => {
        console.log("editpage",editPage)
        try {
            let api=`/api/v1/offers`;
           // /getoffers
           if(user?.liquidation_user && editPage==true) api+='/getoffers';

            api+=`?${query}`;
            const response = await useGetDataToken(api);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");
        }
    }
);
export const getAllOffersforMandob = createAsyncThunk(
    "/offersSlice/offers/getAllOffersforMandob",
    async (query) => {
        try {
            let api=`/api/v1/offers/getoffers`;
           // /getoffers

            api+=`?${query}`;
            const response = await useGetDataToken(api);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");
        }
    }
);

export const addOneOffer = createAsyncThunk(
    "/offersSlice/addoffer",
    async ({ data }) => {
        try {
            // console.log('data',data);
            const response = await useInsertData("/api/v1/offers", data);
            notify(" تم اضافة العرض بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data,"error");
        }
    }
);

export const updateOneOffer = createAsyncThunk(
    "/offersSlice/updateoffer",
    async ({id,data}) => {
        try {
            const response = await useUpdateData(`/api/v1/offers/${id}`, data);
            notify(" تم التعديل بنجاح ", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data,"error");
        }
    }
);