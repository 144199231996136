import React from "react";
import { NavDropdown } from "react-bootstrap";
import { editOneLiquidation } from "../../redux/slices/liquidation/thunk";
import { useDispatch } from "react-redux";
import blue_box from "../../Images/blue-box.png";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function LiquidationsSentSalesTable({ liquidation, status }) {
  console.log("status", status)
  const navigate = useNavigate();
  console.log("liquidationnn",liquidation)
  return (
    <div className="permissionsLayout w-100" style={{overflow:"auto"}}>
      <div className="permissionsTable" style={{overflow:"auto"}}>
        <div className="header mt-2" style={{minWidth:"1000px", width:"100%"}} > التصفيات الواردة</div>
        <table className="pt-2" id="liquidationTable" style={{minWidth:"1000px", width:"100%"}}>
          <thead style={{ background: "rgba(242, 246, 255, 1)" }}>
            <tr>
              <th> رقم التصفية </th>
              <th> اسم المستخدم </th>
              <th> تاريخ بداية التصفية </th>
              <th> تاريخ نهاية التصفية </th>
              <th> الايداعات </th>
              <th> المستحق </th>
              <th> عمولة المندوب </th>
              <th> قبول /رفض </th>
              <th> حفظ </th>
              <th> استعراض </th>
            </tr>
          </thead>
          <tbody className="sacondBackground">
            {liquidation?.filter((el) => el.status !== "new") // Filter items where status is not "new"
            .map((el, i) => (
              <tr key={i}>
                <td>{el?.liquidation_number} </td>
                <td>{el?.sales_id?.fullname}</td>
                <td>{el?.date_from?.split("T")[0]} </td>
                <td>{el?.date_to?.split("T")[0]} </td>
                <td>{el?.money_for_deposits}</td>
                <td>{el?.money_for_company}</td>
                <td>{el?.sales_commission}</td>
                <td>
                { (el?.status == "send" || el?.status == "new") &&  <DropDownControl
                    class_name={"deposite-accepted-refused"}
                    title={"قبول / رفض"}
                    item={el}
                    
                  />}
                 { el?.status == "accepted" && <div  className="deposite-accepted-refused">  مقبولة </div> }
                </td>
                <td>
                  <img src={blue_box} alt="" />
                </td>
                <td>
                  <div
                    className="d-flex justify-content-center"
                    style={{ height: "47%", cursor: "pointer" }}
                  >
                    <FaEye
                      onClick={() => navigate(`show/${el?._id}`, { state: el })}
                      color="rgba(249, 32, 122, 1)"
                      size={30}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function DropDownControl({ title, class_name, item , disabled}) {
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const HandleUpdateStatus = async (status) => {
    let data = {
      status: status,
      user_id: loggedUser?._id,
    };
    await dispatch(
      editOneLiquidation({
        id: item?._id,
        data,
      })
    );
  };

  return (
    <NavDropdown
      title={title}
      className={`${class_name}`}
      style={{ fontFamily: "Cairo" }}
      disabled={disabled}
    >
      <NavDropdown.Item
        onClick={(e) => HandleUpdateStatus(e.target.id)}
        id={"accepted"}
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        قبول
      </NavDropdown.Item>
      <NavDropdown.Item
        id={"refused"}
        onClick={(e) => HandleUpdateStatus(e.target.id)}
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        رفض
      </NavDropdown.Item>
    </NavDropdown>
  );
}
