import React, { useEffect, useState } from "react";
import style from "./NewTasfia.module.css";
import { useDispatch, useSelector } from "react-redux";
import { editOneLiquidation, getNewLiqidationForSalesUser } from "../../../redux/slices/liquidation/thunk";
import { Spinner } from "react-bootstrap";
import SendLiquidationModal from "../../wallet/SendLiquidationModal";
import LiquidationSubModal from "./LiquidationSubModal";

export default function LastLiquidationInfo({ latestLiquidation, isLodaing, selectedLiquidation }) {

  const [isSending, setIsSending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLiquidationSubModal, setShowLiquidationSubModal] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const dispatch = useDispatch();
  console.log("selectedLiquidation",selectedLiquidation.status)

  useEffect(() => {
    console.log('vvvvvvvvvvvvvvvvvvvvvvv');
    const query = `user_id=${user?._id}`;
    //console.log('query',query);
    setIsSending(true);
    if(latestLiquidation?.status == 'new'){
    dispatch(getNewLiqidationForSalesUser({ id: latestLiquidation?._id, query }));
    }
    setIsSending(false);
    
  }, [selectedLiquidation?._id]);


  const HandleSendLiquidation = async (data) => {

    setIsSending(true);
      await dispatch(editOneLiquidation({
        id: latestLiquidation?._id,
        data
      }));
    
    setIsSending(false);
  }

  console.log('latestliquidation render', latestLiquidation);

  const salesLiquidationUser = useSelector(state => state.liquidationReducer.salesLiquidation);

  return (
    <div className={style.container}>
      {isLodaing && <Spinner />}

      {showModal && salesLiquidationUser?.liquidation_balance_end > 0 && <SendLiquidationModal showModal={showModal} setShowModal={setShowModal} latestLiquidation={salesLiquidationUser} HandleSendLiquidation={HandleSendLiquidation} />}
      {showLiquidationSubModal && <LiquidationSubModal showModal={showLiquidationSubModal} setShowModal={setShowLiquidationSubModal} latestLiquidation={salesLiquidationUser} HandleSendLiquidation={HandleSendLiquidation} />}

      <div className={style.box1}>
        <div className={style.box1Child1}>
          {latestLiquidation?.status == 'new' ? 'تصفية جديدة' : 'التصفية'} </div>
        {/* <div className={style.box1Child2}>
          <span> من {' '} {selectedLiquidation?.status=="new" ? salesLiquidationUser?.date_from?.split('T')[0] :selectedLiquidation?.date_from?.split('T')[0] } </span>
          <span> الي {' '} {selectedLiquidation?.status=="new" ? salesLiquidationUser?.date_to?.split('T')[0] :selectedLiquidation?.date_to?.split('T')[0] } </span>

        </div> */}
      </div>

      <div className={style.box2}>
        <div className={style.box2Child}>
          <div className={style.text1}>مبيعات</div>
          <div className={style.btn1}>{latestLiquidation?.status=="new" ? salesLiquidationUser?.sales_id?.fullname : selectedLiquidation?.sales_id?.fullname}</div>
        </div>

        <div className={style.box2Child}>
          <div className={style.text1}>من تاريخ</div>
          <div className={style.btn1}>{latestLiquidation?.status=="new" ? salesLiquidationUser?.date_from?.split('T')[0] : selectedLiquidation?.date_from?.split('T')[0]}</div>
        </div>

        <div className={style.box2Child}>
          <div className={style.text1}>إلى تاريخ</div>
          <div className={style.btn1}>{latestLiquidation?.status=="new" ? salesLiquidationUser?.date_to?.split('T')[0] : selectedLiquidation?.date_to?.split('T')[0]}</div>
        </div>
       
      </div>
   <div className="d-flex justify-content-center my-3">
   {
          latestLiquidation?.status == 'new' &&
          <div className={style.submit} onClick={() => {
            // رصيد التصفية موجب او سالب
            salesLiquidationUser?.liquidation_balance_end > 0 ? setShowModal(true) : setShowLiquidationSubModal(true);
          }}>

            {isSending && <Spinner />}  إرسال
          </div>
        }

   </div>
      {/* <br  /> */}
      <div className={style.line}></div>

      <div className={style.box3}>
        <div className={style.box3Child1}>رصيد التصفية السابقة</div>
        <div className={style.box3Child2}>{latestLiquidation?.status=="new"? salesLiquidationUser?.liquidation_balance_start : selectedLiquidation?.liquidation_balance_start } ريال</div>
        <div className={style.box3Btn}>عليه</div>
      </div>



      {/* ----------------------------------------------------------------------------------- */}
      <div className={style.box4}>
        <div className={style.box4Child}>
          <div className={style.box4Child1Prag}>
            {" "}
            مجموع العضويات {<br />} المصدرة المستحقة
          </div>
          <div className={style.box4Child1Card}>
            <span>{latestLiquidation?.status=="new" ? salesLiquidationUser?.num_of_cards : selectedLiquidation?.num_of_cards}</span> <span>بطاقة</span>
          </div>
        </div>

        <div className={style.box4Child}>
          <div className={style.box4Child2Prag}>
            مجموع المبالغ {<br />}المستحقة علي العضويات
          </div>
          <div className={style.box4Child2Card}>
            <span>{latestLiquidation?.status=="new" ? salesLiquidationUser?.money_for_company : selectedLiquidation?.money_for_company }</span> <span>ريال</span>
          </div>
        </div>


        <div className={style.box4Child}>
          <div className={style.box4Child3Prag}>
            مجموع مبالغ {<br />}الإيداعات
          </div>
          <div className={style.box4Child3Card}>
            <span>{latestLiquidation?.status=="new" ? salesLiquidationUser?.money_for_deposits : selectedLiquidation?.money_for_deposits}</span> <span>ريال</span>
          </div>
        </div>


        <div className={style.box4Child}>
          <div className={style.box4Child4Prag}>
            عمولة المندوب على العضويات المصدرة خلال الفترة
          </div>
          <div className={style.box4Child4Card}>
            <span>{latestLiquidation?.status=="new" ? salesLiquidationUser?.sales_commission : selectedLiquidation?.sales_commission}</span> <span>ريال</span>
          </div>
        </div>
        <div className={style.box4Child}>
          <div className={style.box4Child5Prag}>
            رصيد المحفظة  {<br />}لدى المندوب
          </div>
          <div className={style.box4Child5Card}>
            <span>{latestLiquidation?.status=="new" ? salesLiquidationUser?.current_balance : selectedLiquidation?.current_balance}</span> <span>ريال</span>
          </div>
        </div>


      </div>
      <div className={style.line}></div>
      <div className={style.box5}>
        <div className={style.box5Child1}>رصيد التصفية </div>
        <div className={style.box5Child2}>{latestLiquidation?.status=="new" ? salesLiquidationUser?.liquidation_balance_end : selectedLiquidation?.liquidation_balance_end} ريال</div>
        <div className={style.box5Btn}    style={{
    border: salesLiquidationUser?.liquidation_balance_end >= 0 && latestLiquidation?.liquidation_balance_end >= 0 
      ? "#84cb00 1px solid" 
      : "#f0142f 1px solid",
    color: salesLiquidationUser?.liquidation_balance_end >= 0 && latestLiquidation?.liquidation_balance_end >= 0 
      ? "#84cb00" 
      : "#f0142f",
  }}
  >{salesLiquidationUser?.liquidation_balance_end >= 0 && latestLiquidation?.liquidation_balance_end >= 0  ? 'له' : 'عليه' }</div>
      </div>
    </div>
  )
}
