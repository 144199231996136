import HeadSection from "../../Utilities/HeadSection";
import Input from "../../Utilities/Input";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import { useState } from "react";
import EyeButton from "../../Utilities/EyeButton";
import Select from "../../Utilities/Select";
import MultiSelect from "../../Utilities/MultiSelect";
import EditProfileHook from "../../../hook/setting/EditProfileHook";

export default function PersonalProfileComponent() {
  const [oneUser, formik, isLoading, permissionGroups, onSubmit] = EditProfileHook();
  const [type, setType] = useState('password');

  if (isLoading) return;
  let account_type = '';
  if (oneUser?.isAdmin) account_type = 'مدير نظام';
  if (oneUser?.is_accountant) account_type = 'محاسب';
  if (oneUser?.liquidation_user) account_type = 'مدير مبيعات';
  if (oneUser?.userMarketer) account_type = 'مشرف';
  //oneUser.isAdmin
  return (
    <div className="firstContainer mx-2">
      <HeadSection label={"الملف الشخصي"} />

      <div className="d-flex justify-content-end my-4" >
        <PrintAndDownload />
      </div>

      <div className="permissionsLayout">
        <Input
          name={"fullname"}
          label={"الاسم الثلاثي"}
          value={formik.values.fullname} onChange={formik.handleChange}
          width={"100%"}
          classnam={"inputsReduisAndPadding2"}
        />
        <Input
          name={"email"}
          type="email"
          label={"البريد الالكتروني"}
          value={formik.values.email} onChange={formik.handleChange}
          width={"100%"}
          classnam={"inputsReduisAndPadding2"}

        />
        <div className="inputContainerProfile">
          <Input
            name={"password"}
            type={type}
            label={"كلمة المرور"}
            value={formik.values.password} onChange={formik.handleChange}
            width={"97%"}
            classnam={"inputsReduisAndPadding2"}
            />
          <EyeButton type={type} setType={setType} circle={true} />
        </div>

        <div className="d-flex justify-content-between seetingpProfileMobileResponsive">

          <Input
            name={"isAdmin"}
            label={"نوع الحساب"}
            value={account_type}
            disabled={true}
            width={'30%'}
            classnam={"inputsReduisAndPadding2"}

          />
          {
            oneUser?.liquidation_user && <>
              <Input
                name={"number_of_supervisors_allowed"}
                label={"عدد المشرفين"}
                value={formik.values.number_of_supervisors_allowed}
                disabled={true}
                width={'30%'}
                classnam={"inputsReduisAndPadding2"}

              />
              <Input
                name={"wallet_credit_limit"}
                label={"الحد الائتماني"}
                value={formik.values.wallet_credit_limit}
                disabled={true}
                width={'30%'} 
                classnam={"inputsReduisAndPadding2"}

              />
            </>
          }

        </div>
<div classnam="minWidthMultiSelector">
        {permissionGroups && <MultiSelect labelForOption={'nameAr'} label={'الصلاحيات'} selectedOptionsIDS={oneUser?.permissions} options={permissionGroups} width={'100%'} disabled={true}/>}
</div>

        <div className="d-flex" style={{ gap: '32px' }}>
          <button className="addButton w-50" onClick={() => onSubmit()}  > تعديل </button>
          <button className="addButton w-50" onClick={() => onSubmit()} > حفظ </button>
        </div>

        {/* <MultiSelect disabled={!haveEdit} label={'الصلاحيات'} selectedOptionsIDS={selectedPermissionsIDS} setSelectedOptionsIDS={setSelectedPermissionsIDS} options={permissionGroups} /> */}

        {/* <Select name={'periodType'} label={'نوع الفترة'} value={formik.values.periodType} onChange={formik.handleChange} options={periodTypeOptions} width={'492px'} height={'50px'} /> */}
        {/* <Input formik={formik} name={'offerNameAr'} label={'اسم العرض باللغة العربية'} value={formik.values.offerNameAr} onChange={formik.handleChange} UserIcon={<FaRegAddressCard />} width={'492px'} height={'50px'} /> */}
      </div>
    </div>
  );
}
