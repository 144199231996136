import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useInsertData } from "../../../hooks/useInsertData";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";
import { useDeleteData } from "../../../hooks/useDeleteData";

//liquidationSlice

//add liquidation
export const addNewLiquidation = createAsyncThunk(
    "/liquidationSlice/addNewLiquidation",
    async ({ data }) => {
        try {
            const response = await useInsertData("/api/v1/liquidation", data);
            notify("تم الاضافة بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else {
                console.log('error', error.response);
                return notify(error.response.data.error, "error");
            }

        }
    }
);

//edit liquidation
export const editOneLiquidation = createAsyncThunk(
    "/liquidationSlice/editOneLiquidation",
    async ({ id, data }) => {
        try {
            const response = await useUpdateData(`/api/v1/liquidation/${id}`, data);
            notify("تم تعديل التصفية بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data, "error");
        }
    }
);

//get all
export const getAllLiquidation = createAsyncThunk(
  "/liquidationSlice/getAllLiquidation",
  async (query) => {
    try {
      const response = await useGetDataToken(`/api/v1/liquidation?${query}`);
      return response;
    } catch (error) {}
  }
);

//search
export const getLiquidationForSearch = createAsyncThunk(
  "/liquidationSlice/getLiquidationForSearch",
  async (id) => {
    try {
      const response = await useGetDataToken(`/api/v1/liquidation/${id}`);
      return response;
    } catch (error) {}
  }
);

//delete
export const deleteOneLiquidation = createAsyncThunk(
    "/liquidationSlice/deleteOneLiquidation",
    async ({id,data}) => {
        try {
            const response=await useInsertData(`/api/v1/liquidation/${id}`, data);
            notify("تم حذف التصفية بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data.error, "error");
        }
    }
);

// اخر تصفية للمندوب
export const getNewLiqidationForSalesUser=createAsyncThunk(
    "/liquidationSlice/getNewLiqidationForSalesUser",
    async({id,query})=>{
        try {
            console.log('query',query);
            const response=await useGetDataToken(`/api/v1/liquidation/${id}?${query}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data.error, "error");
        }
    }
);
