import React, { useRef, useEffect } from "react";
import { Chart } from "chart.js/auto";
import styles from "./TotalDepositsComponent.module.css";

const TotalDepositsComponent = ({ data }) => {
  const chartRef = useRef(null);

  const defaultMonths = [
    "Jun 19",
    "Jul 19",
    "Aug 19",
    "Sep 19",
    "Oct 19",
    "Nov 19",
    "Dec 19",
    "Jan 20",
    "Feb 20",
    "Mar 20",
    "Apr 20",
    "May 20",
  ];

  const summation = (revenues) => {
    return revenues.reduce((acc, curr) => acc + curr, 0);
  };

  useEffect(() => {
    let chartInstance = null;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: defaultMonths,
          datasets: [
            {
              data,
              borderColor: "rgba(56, 170, 100, 1)",
              borderWidth: 2,
              fill: true,
              backgroundColor: "rgba(56, 170, 100, 0.3)",
              tension: 0,
              pointBorderWidth: 0,
              pointHoverRadius: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]);

  return (
    <div className={styles.DeliveredCardGraphComponentMasterContainer}>
      <div className={styles.DeliveredCardGraphComponentContainer}>
        <div className={styles.infoContainer}>
          <p>مجموع الايداعات</p>
          <h2>SAR {summation(data)}</h2>
          <p className={styles.percentage}>
            1.3% <span>⬇</span> than last year
          </p>
        </div>
        <div className={styles.chartContainer}>
          <canvas ref={chartRef}></canvas>
        </div>
      </div>
    </div>
  );
};

export default TotalDepositsComponent;
