import { useSearchParams } from "react-router-dom";
import Filter from "../../Utilities/Filter";
import PdfBox from "../../Utilities/PdfBox";
import SelectBankOrDepositeBtn from "./SelectBankOrDepositeBtn";
import BanksTabsComponent from "./BanksTabsComponent";
import {
  addOneBank,
  getAllBanks,
  updateOneBank,
} from "../../../redux/slices/banks/thunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import DepositeTabsComponent from "./DepositeTabsComponent";

// addNewBank

export default function BanksOrDepositesComponent() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllBanks());
  }, []);

  let initialValues = {
    _id: "",
    settingsType: "",
    nameAr: "",
    nameEn: "",
    bankAccountNumber: "",
    deposit_operation_type: "0",
    deposit_operation_type_banks: "0",
    status: true,
  };

  const formik = useFormik({
    initialValues,
    // validationSchema,
    enableReinitialize: true,
    // onSubmit,
  });

  const showItem = (bank) => {
    console.log('selected bank', bank);
    formik.setValues(bank);
  };

  const resetValues = () => {
    formik.setValues(initialValues);
  };

  const handleUpdateStatus = async (bank) => {
    //updateOneBank
    await dispatch(
      updateOneBank({
        id: bank._id,
        data: { status: !bank?.status },
      })
    );
  };

  const onSubmit = async () => {
    try {
      //add
      if (formik.values._id == "") {
        let addObj = { ...formik.values };
        //set settingsType
        if (searchParams.get("section") == "Bank")
          addObj.settingsType = "bankType";
        else addObj.settingsType = "depositProcessType";

        console.log("addObj", addObj);


        await dispatch(addOneBank({ data: addObj }));
        // formik.setValues(initialValues);
      }
      //edit
      else {
        // console.log("formik.values",formik.values);
        // return;
       // const data=formik.values;
       if(formik.values._id=="") return;
       
        await dispatch(
          updateOneBank({
            id: formik.values._id,
            data: { ...formik.values },
          })
        );

        formik.setValues(initialValues);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const { banks } = useSelector((state) => state.banksReducer);
  console.log("banks", banks);

  let banks_typeBanks = banks?.filter((el) => el.settingsType == "bankType");
  let banks_deposite = banks?.filter(
    (el) => el.settingsType == "depositProcessType"
  );



  return (
    <div>
      <div className="d-flex justify-content-between firstContainer">
        <h5 className="h5Title">
          {searchParams.get("section") == "Bank"
            ? "اضافة نوع بنك"
            : "اضافة نوع عملية ايداع"}
        </h5>
        <div
          className="d-flex justify-content-between"
          style={{ gap: "20px", flexWrap: "wrap" }}
        >
          <Filter
            gap={"10"}
            types={[
              { text: "اليوم", value: "day" },
              { text: "الشهر", value: "month" },
              { text: "الاسبوع", value: "week" },
            ]}
          />

          <PdfBox />
        </div>
      </div>

      <div className="permissionsLayout settingLayout">
        <SelectBankOrDepositeBtn />

        {
          searchParams.get("section") == "Bank" && <BanksTabsComponent
            banks={banks_typeBanks}
            handleUpdateStatus={handleUpdateStatus}
            formik={formik}
            showItem={showItem}
            resetValues={resetValues}
            initialValues={initialValues}
            onSubmit={onSubmit}
            Deposites={banks_deposite}
          />
        }

        {
          searchParams.get("section") == "Deposite" &&<DepositeTabsComponent
          banks={banks_deposite}
          handleUpdateStatus={handleUpdateStatus}
          formik={formik}
          showItem={showItem}
          resetValues={resetValues}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
        }
        
      </div>
    </div>
  );
}
