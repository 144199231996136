import React from "react";
import PrintAndDownload from "../Utilities/PrintAndDownload";

export default function SearchComponentFooter({ deposites,archive_page }) {
  const handlePrint = () => {
    const table = document.getElementById("depositesTable");
    if (table) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write(`<style>
          /* Add your CSS styles here */
          body {
            font-family: Arial, sans-serif;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th {
            background-color: #f2f2f2;
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
          }
          td {
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
          }
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
        </style>`);
      printWindow.document.write("</head><body>");
      printWindow.document.write("<table>");
      printWindow.document.write(table.innerHTML);
      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  const handleDownload = () => {
    // Construct CSV content
    let csvContent =
      "رقم العملية,نوع العملية,المبلغ,تاريخ التحويل,اسم المحول,البنك,رقم حساب العميل,مصدر الحوالة,الحالة\n";
    deposites.forEach((deposite) => {
      csvContent += `${deposite?.operation_number},${
        deposite?.operation_type
      },${deposite?.price},${deposite?.operation_date?.split("T")[0]},${
        deposite?.adapter_name
      },${deposite?.bank_id?.nameAr},${deposite?.account_number_for_client},${
        deposite?.deposite_source
      },${deposite?.status_of_deposit}\n`;
    });

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "deposits.csv";

    // Programmatically click the <a> element to trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  let acceptedDeposites= archive_page  ? deposites : deposites?.filter(el=>el?.status_of_deposit=="accepted");

  //el?.status_of_deposit=="accepted"&&
  let totalDepositesPrice = 0;
  acceptedDeposites?.map((el) =>(totalDepositesPrice += el?.price));

  return (
    <div className="d-flex mt-1 justify-content-evenly depositesSearchResponsive" style={{marginBottom:"10px"}}>
      <div className="d-flex" style={{ gap: "12px" }}>
        <div className="title " > عدد الحوالات </div>
        <div className="desposites-box">{acceptedDeposites?.length}</div>
      </div>

      <div className="d-flex" style={{ gap: "12px" }}>
        <div className="title"> قيمة الحوالات </div>
        <div className="desposites-box">{totalDepositesPrice} </div>
      </div>

      <div className="d-flex flex-column justify-content-center">
        <PrintAndDownload onPrint={handlePrint} onDownload={handleDownload} />
      </div>
    </div>
  );
}
