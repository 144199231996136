import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import {
  addCity,
  editCity,
  getAllCities,
  getAllCountries,
} from "../../redux/slices/cities/thunk";
import add from "../../Images/add.png";
import { HiPencilSquare } from "react-icons/hi2";
import { useLocation, useParams } from "react-router-dom";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { exportToExcel } from "../Utilities/exportToExcel";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import notify from "../../hook/useNotification";



export default function AddAndEditCities() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedNameAr, setSelectedNameAr] = useState("");
  const [selectedNameEn, setSelectedNameEn] = useState("");
  const [selectedCity, setSelectedCity] = useState({});
  const location = useLocation();
  const { id } = useParams();

  const country = location.state;
  console.log("country?.nameAr", country?.nameAr);

  useEffect(() => {
    dispatch(getAllCities(id));
  }, [dispatch]);

  const cities = useSelector((state) => state.citiesReducer.cities);
  const countries = useSelector((state) => state.citiesReducer.countries);
  console.log("countries", countries);

  const onSubmit = async () => {
    if(!selectedNameAr){
      return notify("يرجي كتابة اسم البلد باللغة العربية","error")
    }
   else if(!selectedNameEn){
      return notify("يرجي كتابة اسم البلد باللغة الانجليزية","error")
    }
    const data = {
      nameAr: selectedNameAr,
      nameEn: selectedNameEn,
      country: id,
    };
    if (selectedCity?._id) {
      await dispatch(editCity({ id: selectedCity?._id, data }));
    } else {
      await dispatch(addCity({ data }));
    }

    setShowModal(false);
    setSelectedNameAr("");
    setSelectedNameEn("");
    setSelectedCity({});
  };

  const handleExport = () => {
    exportToExcel(cities, "cities");
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }
      .table-header {
        background-color: #f2f2f2;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `);
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write("<table>");

    // Table headers
    printWindow.document.write("<tr>");
    printWindow.document.write("<th class='table-header'>الاسم بالعربي</th>");
    printWindow.document.write(
      "<th class='table-header'>الاسم بالانجليزي</th>"
    );

    printWindow.document.write("</tr>");

    // Table data
    cities?.forEach((offer) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${offer?.nameAr}</td>`);
      printWindow.document.write(`<td>${offer?.nameEn}</td>`);

      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    let csvContent = "الاسم بالعربي,الاسم بالانجليزي\n";
    cities?.forEach((offer) => {
      csvContent += `${offer?.nameAr},${offer?.nameEn}\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "markting_link.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const screenID = "66b9bcde5bfa66cb94a1dc2c";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let hasEdit = false;
  let hasAdd = false;
  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div>
      <div>
       { hasAdd != undefined && hasAdd && <button
          className="addButton mb-2"
          style={{
            backgroundColor: "#0d6efd",
            color: "white",
            border: "#0d6efd",
          }}
          onClick={() => {
            setShowModal(true);
          }}
        >
          اضافة مدينة
          <img
            src={add}
            alt=""
            style={{
              cursor: "pointer",
              marginRight: "8px",
              borderColor: "black",
              marginTop: "auto",
            }}
            onClick={() => {
              setShowModal(true);
            }}
          />
        </button>}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle">إضافة مدينة</h5>

          <div className="label"> اسم المدينة (بالعربية) </div>
          <input
            value={selectedNameAr}
            onChange={(e) => setSelectedNameAr(e.target.value)}
            className="w-100"
            onKeyPress={handleKeyPress}
            style={{color:"#333"}}

          />

          <div className="label"> اسم المدينة (بالإنجليزية) </div>
          <input
            value={selectedNameEn}
            onChange={(e) => setSelectedNameEn(e.target.value)}
            className="w-100"
            onKeyPress={handleKeyPress}
            style={{color:"#333"}}

          />
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
          <Button
            onClick={onSubmit}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#0080FB" }}
          >
            تم
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="permissionsLayout" style={{ overflow: "auto" }}>
        <div
          className="permissionsTableHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            minWidth: "1000px",
          }}
        >
          {" "}
          <div className="citylabel">مدن دولة {country?.nameAr}</div>
          <PrintAndDownload
            hasExcelDownload={true}
            download_excel={handleExport}
            onDownload={handleDownload}
            onPrint={handlePrint}
          />
        </div>
        <div className="permissionsTable">
          <table style={{ width: "100%", minWidth: "1000px" }}>
            <thead>
              <tr>
                <th>رقم السريال</th>
                <th>اسم المدينة (بالعربية)</th>
                <th>اسم المدينة (بالإنجليزية)</th>
                <th>تعديل</th>
              </tr>
            </thead>
            <tbody>
              {cities?.map((city, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{city?.nameAr}</td>
                  <td>{city?.nameEn}</td>
                   <td

                    onClick={() => {
                      if(!hasEdit) return;
                      setSelectedNameAr(city?.nameAr);
                      setSelectedNameEn(city?.nameEn);
                      setShowModal(true);
                      setSelectedCity(city);
                    }}
                    style={{ color: "#FEAA00", cursor: "pointer" }}
                  >
                    تعديل{" "}
                    <span>
                      <HiPencilSquare />{" "}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
