import { useEffect, useState ,useRef } from "react";
import down_arrow from "../../Images/down_arrow.png";

export default function MultiSelect({
  label,
  width,
  options=[],
  selectedOptionsIDS=[],
  setSelectedOptionsIDS=[],
  disabled=false,
  labelForOption,
  className, 
  style,
}) 
{
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const firstRender=useRef(true);

  useEffect(() => {
    // if(firstRender){
    //   firstRender.cuuurnt=false;
    //   return;
    // }
    console.log('selectedOptionsIDS',selectedOptionsIDS);
    
     //if (selectedOptionsIDS?.length > 0) {
      let option_names = [];
      selectedOptionsIDS.map((id) => {
        let optionObj = options?.find((el) => el?._id == id);
        //console.log('optionObj',optionObj);
        if(optionObj===undefined) return;
        option_names.push(optionObj[labelForOption]);
       // if (optionObj[labelForOption]) option_names.push(optionObj[labelForOption]);
       //if (labelForOption in optionObj) option_names.push(optionObj[labelForOption])
      });
      
      setSelectedOptions(option_names);
    //  } else {
    //    setSelectedOptions([]);
    //  }
  }, [options]);

  //  console.log('selectedOptionsIDS',selectedOptionsIDS);
    console.log('options',options);
 

  function handleSelectedOption(e) {
    try {
      console.log("e.target.value", e.target.value);
      console.log("e.target.checked", e.target.checked);
      const option_name = options.find(
        (el) => el?._id === e.target.value
      )[labelForOption];

      if (e.target.checked) {
        setSelectedOptions((p) => [...p, option_name]);
        setSelectedOptionsIDS((p) => [...p, e.target.value]);
      } else {
        setSelectedOptions((p) => p.filter((el) => el != option_name));
        setSelectedOptionsIDS((p) => p.filter((el) => el != e.target.value));
      }
    } catch (error) {
        console.error(error.message);
    }
  }

  return (
    <div style={{ width: width? width :"45%", fontFamily: "Cairo" }}>
      <label className="label">{label}</label>
      <p className={`multislect_p ${className}`}         
      style={{ height: "auto", minHeight: "45px", ...style }}
      onClick={() =>{
        if(disabled) return;
        setShowOptions((p) => !p);
      } }>
        {selectedOptions.length > 0 ? selectedOptions.join(", ") : ""}
        {!disabled&&<span>
          <img src={down_arrow} alt="" />
        </span>
        }
        
      </p>
      {/* 
      selectedOptions?.includes(el[labelForOption])
      selectedOptionsIDS?.includes(el?._id) 
      */}
      {showOptions && (
        <div className={`d-flex flex-column multislect_option ${className}`}>
          {options?.map((el, i) => (
            <div key={i} className="">
              <label className="container">
                <div className="px-1">{el[labelForOption]}</div>
                <input
                  checked={selectedOptionsIDS?.includes(el?._id) }
                  onChange={(e) => handleSelectedOption(e)}
                  type="checkbox"
                  value={el?._id}
                  style={{ marginLeft: "12px" }}
                />
                <span className={`checkmark3 checkmark4`}></span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
