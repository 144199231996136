import  React ,{ useEffect, useState } from "react";
import { paymentStatus } from "../../redux/slices/payment/thunk";
import { useDispatch, useSelector } from "react-redux";
import { addOneOrder } from "../../redux/slices/orders/thunk";
import { useLocation } from "react-router-dom";


const SendOrderSuccessfully = () => {

  const invoiceId = localStorage.getItem("invoiceId");
  const orderData = JSON.parse(localStorage.getItem("orderDataJson"));

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px'
  };

  const messageBoxStyle = {
    width: '100%',
    maxWidth: '500px',
    backgroundColor: '#fff',
    fontFamily: "'Cairo', sans-serif",
    fontSize: '1rem',
    fontWeight: '700',
    height: '200px',
    border: '2px solid #000',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: '#333'
  };



  const headingStyle = {
    marginBottom: '10px'
  };

  const paragraphStyle = {
    marginTop: '0',
    color: '#666'
  };

  const location = useLocation();

  const paymentStatusResponse = useSelector(state => state.paymentReducer.paymentStatusResponse);

  const dispatch = useDispatch();

  useEffect(() => {
    if (invoiceId) {
      const data = { key: invoiceId };
      dispatch(paymentStatus({ data }));
    }
  }, [invoiceId]);

  useEffect(() => {
    if (paymentStatusResponse?.InvoiceStatus === "Paid" && orderData) {
      dispatch(addOneOrder({ data: orderData }));
    }
  }, [paymentStatusResponse?.InvoiceStatus, orderData]);
  return (
    <div style={containerStyle}>
      <div style={messageBoxStyle}>
       { paymentStatusResponse?.InvoiceStatus === "Paid" && 
       <>
       <h1 style={headingStyle}>تم ارسال الطلب بنجاح</h1>
       <p style={paragraphStyle}>Your order has been successfully sent</p>
       </>
        }
      </div>
    </div>
  );
};

export default SendOrderSuccessfully;
