import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import SquareCheckBox from "../Utilities/SquareCheckBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import TransferRequestModal from "./TransferRequestModal";
import {
  getAllOrdersWithPagination,
  updateOrder,
} from "../../redux/slices/orders/thunk";
import ChangeStatusModal from "./ChangeStatusModal";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";

export default function OrdersTableComponent({
  orders,
  transferMemberShip,
  hasEdit,
  hasAdd,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false); // New state for select all
  // دي عشان تحديد الكل
  const [selectedOrders, setSelectedOrders] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const order_status = searchParams.get("requestStatus");
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const { oneUser } = useSelector((state) => state.usersReducer);

  const oneUser2 = useSelector((state)=>state.usersReducer.oneUser)
console.log("oneUser22",oneUser2)

if(loggedUser?.isAdmin == false && loggedUser?.is_accountant == false){
  orders = orders?.data?.filter((el) => el.request_status == order_status && el.user?.id == loggedUser?._id);
}
else{
  orders = orders?.data?.filter((el) => el.request_status == order_status);
}
 const adminsOrders = orders?.filter((el) => el.admin_request === true);
  console.log("adminsOrders",adminsOrders)
  // const[searchParams,setSearchParams]=useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const get = async () => {
    let page;

    if (searchParams.get("page") == null || searchParams.get("page") == 0) {
      //console.log('pppppppppppppppppp')
      searchParams.set("page", 1);
      setSearchParams(searchParams);
    } else {
      if (page == 0) page = 1;
      page = searchParams.get("page");
      searchParams.set("page", page);
      setSearchParams(searchParams);
    }

    let query = `page=${page}`;
    //requestStatus=${searchParams.get('requestStatus')}
    query += `&requestStatus=${searchParams.get("requestStatus")}&`;
    if (user?.liquidation_user || user?.userMarketer)
      query += `user=${user?._id}`;
    if (page == 0) return;
    dispatch(getAllOrdersWithPagination(query));
  };

  const handleShowModal = (orderID) => {
    let order = orders.find((el) => el._id == orderID);
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleShowStatusModal = (orderID) => {
    let idToSend = "";
    if (selectedOrders.length == 0) {
      idToSend = [orderID];
    } else {
      const IDS = Object.keys(selectedOrders);
      if (IDS.includes(orderID)) {
        console.log("IDS", IDS);
        idToSend = IDS;
      } else {
        idToSend = [orderID];
      }
    }

    // let order = orders.find((el) => el._id == orderID);
    setSelectedOrder(idToSend);
    setShowStatusModal(true);
  };

  const updateRequest = async ({ id, data }) => {
    console.log(id, data);
    await dispatch(
      updateOrder({
        id,
        data,
      })
    );

    get();
    // await dispatch(getAllOrders(`requestStatus=${searchParams.get('requestStatus')}`));
    setShowModal(false);
    setShowStatusModal(false);
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
    setSelectedOrders(
      orders.reduce((acc, order) => {
        acc[order._id] = !selectAll;
        return acc;
      }, {})
    );
  };

  const handleCheckboxChange = (id) => {
    setSelectedOrders((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  let showPhoneNumber = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!loggedUser.isAdmin) {
    if (oneUser?.show_mobile_in_users_card_table) {
      showPhoneNumber = true;
    } else showPhoneNumber = false;
  }

  // console.log("selectedOrders",selectedOrders);

  //orders=orders.toObject();
  console.log("orders", orders);
  // const reducedAmount =(orders && Number(selectedOrders?.offer?.reducedAmount)) || 0;
  // const commissionAmount = (orders && Number(selectedOrders?.offer?.commission))|| 0;
  // const creditLimit = Number(oneUser2?.father_id?.credit_limit ? oneUser2?.father_id?.credit_limit : oneUser2?.credit_limit) || 0;



    

  // console.log("order_limit1", reducedAmount);
  // console.log("order_limit2", commissionAmount);
  // console.log("order_limit3", creditLimit);



  const handlePrint = (orderId) => {
    const order = orders.find((el) => el._id === orderId);
    const cardsContent = order?.requestCard.map((card, index) => `
      <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <tr>
          <th colspan="2" style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">
            البطاقة ${index + 1}
          </th>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">اسم صاحب الطلب</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.name || 'غير متاح'}</td>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">رقم الهوية او الجواز</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.identity_number_passport || 'غير متاح'}</td>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">رقم الجوال</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${card?.mobile || 'غير متاح'}</td>
        </tr>
      </table>
    `).join('');

    const tableContent = `
      <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <tr>
          <th colspan="2" style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">
            البيانات الاساسية
          </th>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">نوع البطاقة</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${order?.offer?.offerNameAr || 'غير متاح'}</td>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">الدولة</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${order?.country || 'غير متاح'}</td>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">المدينة</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${order?.city?.nameAr || 'غير متاح'}</td>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">العنوان</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${order?.address || 'غير متاح'}</td>
        </tr>
        <tr>
          <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 8px; text-align: center;">طريقة الدفع</th>
          <td style="border: 1px solid #dddddd; padding: 8px; text-align: center;">${order?.payment_status || 'غير متاح'}</td>
        </tr>
      </table>
    `;

    const fullContent = `
      ${tableContent}
      <h2 style="text-align: center;">تفاصيل البطاقات</h2>
      ${cardsContent}
    `;

    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write(`<style>
      body { font-family: Arial, sans-serif; direction: rtl; margin: 10px; }
      table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
      th, td { border: 1px solid #dddddd; padding: 8px; text-align: center; }
      th { background-color: #f2f2f2; }
    </style>`);
    printWindow.document.write("</head><body>");
    printWindow.document.write(fullContent);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };


  return (
    <div style={{ overflow: "auto" }}>
      {showModal && (
        <TransferRequestModal
          show={showModal}
          setShow={setShowModal}
          selectedOrder={selectedOrder}
          updateRequest={updateRequest}
        />
      )}
      {showStatusModal && (
        <ChangeStatusModal
          show={showStatusModal}
          setShow={setShowStatusModal}
          updateRequest={updateRequest}
          selectedOrder={selectedOrder}
        />
      )}

      <div className="header" style={{ minWidth: "1000px", width: "100%" }}>
        {" "}
        قائمة الطلبات
      </div>
      <div
        className="permissionsTable"
        style={{ minWidth: "1000px", width: "100%", minHeight: "300px" }}
      >
        <table id="orderTable" style={{ minWidth: "1000px", width: "100%" }}>
          <thead>
            <tr>
              <th>
                <SquareCheckBox
                  marginTop="-9px"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th> رقم الطلب </th>
              <th> اسم صاحب الطلب </th>
              {loggedUser.isAdmin && <th>اسم المندوب</th>}
               <th>اسم المسوق</th>
              <th> المدينة </th>
              {showPhoneNumber && <th>الهاتف</th>}
              <th> نوع البطاقة </th>
              <th> طريقة الدفع </th>
              <th> تاريخ الطلب </th>
              <th> استعراض </th>
              {searchParams.get("requestStatus") !== "exported" && hasEdit && (
                <th> تحديد سريع </th>
              )}
            </tr>
          </thead>
          <tbody>
            {orders?.map((order, i) => (
              <tr key={i}>
                <td>
                  <SquareCheckBox
                    checked={selectedOrders[order._id]}
                    onChange={() => handleCheckboxChange(order._id)}
                  />
                </td>
                <td>{order?.serialNumber}</td>
                <td>{order?.requestCard[0]?.name}</td>
               {loggedUser?.isAdmin && <td>{order?.user?.fullname}</td>}
                <td>{order?.marketer?.marketer_name?order?.marketer?.marketer_name:"لا يوجد"}</td>
                <td>{order?.city?.nameAr}</td>
                {showPhoneNumber && <td>{order?.requestCard[0]?.mobile}</td>}
                <td>{order?.offer?.offerNameAr}</td>
                <td>{order?.payment_status}</td>
                <td>{order?.createdAt?.split("T")[0]}</td>
                <td>
                  <FaEye
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`edit/${order?._id}`, {
                        state: { ...order, hasEdit, hasAdd },
                      })
                    }
                    color="rgba(249, 32, 122, 1)"
                    size={35}
                  />
                </td>
                {searchParams.get("requestStatus") !== "exported" &&
                  hasEdit && (
                    <td>
                      <NavDropdown
                        title={"تحديد سريع"}
                        className="dropdownstyles"
                        style={{ fontFamily: "Cairo" }}
                      >
                        {!loggedUser?.isAdmin &&!loggedUser?.is_accountant && <NavDropdown.Item
                          onClick={(e) => {{
                            // const isUserAdmin = user?.isAdmin;
                            // const walletCreditLimit = oneUser2?.father_id ? oneUser2?.father_id?.wallet_credit_limit : oneUser2?.wallet_credit_limit;
                            // const totalAmount = reducedAmount - commissionAmount + creditLimit;
                            // console.log("trywalletCreditLimit",walletCreditLimit)
                            // if (!isUserAdmin && walletCreditLimit < totalAmount) {
                            //   return notify("لا يمكن إتمام العملية لأنك وصلت إلى أقصى حد للائتمان المتاح لك", "error");
                            // }else {
                              if (selectedOrders.length == 0) {
                                transferMemberShip([e.target.id]);
                              } else {
                                //selectedOrders=selectedOrders.filter(el=>el==true);
                                //  Object.keys(selectedOrders).forEach(function(key, index) {
                                //   console.log('selectedOrders[key]',selectedOrders[key]);

                                //      if(selectedOrders[key] ==true) return selectedOrders[key] ;
                                //   });

                                //  return;

                                let IDS = Object.keys(selectedOrders);
                                console.log("IDS", IDS);
                                if (IDS.includes(e.target.id)) {
                                  console.log("IDS", IDS);
                                  transferMemberShip(IDS);
                                } else {
                                  transferMemberShip([e.target.id]);
                                }
                              }
                            // }
                          }}}
                          id={order?._id}
                          style={{ textAlign: "center", fontWeight: "600" }}
                        >
                          تصدير الي عضوية
                        </NavDropdown.Item>
                        }
                        <NavDropdown.Item
                          id={order?._id}
                          onClick={(e) => handleShowModal(e.target.id)}
                          style={{ textAlign: "center", fontWeight: "600" }}
                        >
                          تحويل الطلب
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          style={{ textAlign: "center", fontWeight: "600" }}
                          onClick={() => handlePrint(order._id)}
                        >
                          طباعة الطلب
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            navigate(`edit/${order?._id}`, { state: order })
                          }
                          style={{ textAlign: "center", fontWeight: "600" }}
                        >
                          تعديل الطلب
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          id={order?._id}
                          onClick={(e) => {
                            handleShowStatusModal(e.target.id);
                          }}
                          style={{ textAlign: "center", fontWeight: "600" }}
                        >
                          تعديل حالة الطلب
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          onClick={() => {
                            let idToSend = "";
                            // order?._id
                            if (selectedOrders.length == 0) {
                              idToSend = [order?._id];
                            } else {
                              const IDS = Object.keys(selectedOrders);
                              if (IDS.includes(order?._id)) {
                                console.log("IDS", IDS);
                                idToSend = IDS;
                              } else {
                                idToSend = [order?._id];
                              }
                            }

                            console.log("idToSend", idToSend);
                            //  return;
                            const data = {
                              request_status: "archived",
                              request_Id: idToSend,
                            };

                            updateRequest({
                              //id: idToSend,
                              data,
                            });

                            get();
                          }}
                          style={{ textAlign: "center", fontWeight: "600" }}
                        >
                          ارسال الي الارشيف
                        </NavDropdown.Item>

                        {/* <NavDropdown.Item
                      //onClick={() => logOut()}
                      style={{ textAlign: "center", fontWeight: "600" }}
                    >
                      تسليم الطلب
                    </NavDropdown.Item> */}
                      </NavDropdown>
                    </td>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
