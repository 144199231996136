import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useInsertData } from "../../../hooks/useInsertData";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";

export const addOneOrder=createAsyncThunk(
    "/ordersSlice/addOrder",
    async({data})=>{
        // const loggedUser= JSON.parse(localStorage.getItem('user'));

        try {
            const response = await useInsertData("/api/v1/requests", data);
            // if (!loggedUser){
            //     window.location.href = "http://167.99.88.192/sendOrderSuccessfully"
            // }
            notify("تم ارسال الطلب بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error"){
                // if (!loggedUser){
                // window.location.href = "http://167.99.88.192/failedToSendOrder"
                // }
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            }
            
            else{
                
                // if (!loggedUser){
                // window.location.href = "http://localhost:3000/failedToSendOrder"
                // }
                return notify('حدث خطأ حاول مرة اخري ', "error");
            }

             //notify(error.response.data, "error");
        }
    }
);

export const getAllOrders=createAsyncThunk(
    "/ordersSlice/allorders",
    async(query)=>{
        try {
            console.log('response.........');
            //requestStatus=${query}
            const response=await useGetDataToken(`/api/v1/requests?${query}`);      
            return response;

        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.message, "error");
        }
    }
);

export const updateOrder=createAsyncThunk(
    "/ordersSlice/updateOneOrder",
    async({id,data})=>{
        try {
            const response = await useUpdateData(`/api/v1/requests/${id}`, data);
            notify("تم تعديل الطلب بنجاح", "success");
            
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");
        }
    }
);

export const getAllOrdersWithPagination=createAsyncThunk(
    "/ordersSlice/getAllOrdersWithPagination",
    async(query)=>{
        try {
            console.log('response.........');
            //requestStatus=${query}
            const response=await useGetDataToken(`/api/v1/requests?${query}`);      
            return response;

        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.message, "error");
        }
    }
);



