import { FaRegAddressCard } from "react-icons/fa";
import AddEditCloseBtnGroup from "../Utilities/AddEditCloseBtnGroup";
import Input from "../Utilities/Input";
import Select from "../Utilities/Select";

export default function AddOrEditOfferComponent({formik,onSubmit,resetValues,periodTypeOptions,periodsOptions,hasAdd ,hasEdit}) {
  return (
    <>
      <div className="header">
          اضافة عرض
        </div>

        <div className="box1 font-offers">
         
            <div className="d-flex justify-content-between colouminmobile">
              <Input formik={formik} name={'offerNameAr'} label={'اسم العرض باللغة العربية'} value={formik.values.offerNameAr} onChange={formik.handleChange} UserIcon={<FaRegAddressCard />} className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
              <Input formik={formik} name={'offerNameEn'} label={'اسم العرض باللغة الانجليزية'} value={formik.values.offerNameEn} onChange={formik.handleChange} UserIcon={<FaRegAddressCard />}  className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
            </div>

            <div className="d-flex justify-content-between mt-2 colouminmobile">
              <Select name={'periodType'} label={'نوع الفترة'} value={formik.values.periodType} onChange={formik.handleChange} options={periodTypeOptions} className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
              <Select formik={formik} name={'validityPeriod'} label={'فترة الصلاحية'} UserIcon={<FaRegAddressCard />} value={formik.values.validityPeriod} onChange={formik.handleChange} options={periodsOptions} className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
              {/* <Input formik={formik} name={'validityPeriod'} label={'فترة الصلاحية'} UserIcon={<FaRegAddressCard />} value={formik.values.validityPeriod} onChange={formik.handleChange} width={'45%'} /> */}
            </div>

            <div className="d-flex justify-content-between mt-2 colouminmobile">
              <Input type="number" formik={formik} name={'originalAmount'} label={'المبلغ الاصلي'} UserIcon={<FaRegAddressCard />} value={formik.values.originalAmount} onChange={formik.handleChange}  className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
              <Input type="number" formik={formik} name={'reducedAmount'} label={'المبلغ المخفض'} UserIcon={<FaRegAddressCard />} value={formik.values.reducedAmount} onChange={formik.handleChange}className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
            </div>

            <div className="d-flex justify-content-between mt-2 colouminmobile">
              <Input type="number" formik={formik} name={'numberOfCards'} label={'عدد البطاقات'} UserIcon={<FaRegAddressCard />} value={formik.values.numberOfCards} onChange={formik.handleChange} className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
              <Input type="number" formik={formik} name={'salesCommission'} label={'عمولة المندوب'} UserIcon={<FaRegAddressCard />} value={formik.values.salesCommission} onChange={formik.handleChange} className={"width45"} classnam={"inputsReduisAndPadding2 width100"} />
            </div>

            <AddEditCloseBtnGroup hasAdd={hasAdd} hasEdit={hasEdit} onSubmit={onSubmit} resetValues={resetValues} />
          
        </div>
    </>
  )
}
