import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffers } from "../../redux/slices/offers/thunk";
import { getAllCities, getAllCountries } from "../../redux/slices/cities/thunk";
import { addOneOrder } from "../../redux/slices/orders/thunk";
import { addPayment, getPaymentMethod, paymentStatus } from "../../redux/slices/payment/thunk";
import { useSearchParams } from "react-router-dom";
import notify from "../useNotification";
import { getAllMarketers } from "../../redux/slices/marketing/thunk";
import {getAllUsers} from "../../redux/slices/users/thunk";
import { Navigate } from "react-router-dom";



export default function NewOrdersHook() {

  const [selectedOffer, setSelectedOffer] = useState(0);
  const [requestCardsArr, setRequestCardsArr] = useState([]);
  const[selectedCountry,setSelectedCountry]=useState(0);
  const [selectedCity, setSelectedCity] = useState(0);
  const [address, setAddress] = useState('');
  const[paymentType,setPaymentType]=useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);


  // const [user, setUser] = useState('6603ee320f641716748bdbe5');
  // const [userTakeComission, setuserTakeComission] = useState('6603ee320f641716748bdbe5');

  const[searchParams,setSearchParams]=useSearchParams();
  const user = JSON.parse(localStorage.getItem("user"));

  // let query=``;
  //     if(loggedUser?.isAdmin) query+=`type=offer`;
  //     else query+=`type=commission&user=${loggedUser?._id}`

  //     dispatch(getAllOffers(query));

  const dispatch = useDispatch();
  useEffect(() => {
    let offerQuery=`type=commission&user=${searchParams.get('markter')}`;
    dispatch(getAllOffers(offerQuery));
    dispatch(getAllCountries());

    let query=`marketing_link_number=${searchParams.get('marketing_link_number')}`
    dispatch(getAllMarketers(query));
  }, []);

  console.log('searchParams',searchParams.get('marketing_link_number'));

  let offers=[];
  const { offers : userOffers } = useSelector(state => state.offersReducer);
  const {cities,countries} = useSelector(state => state.citiesReducer);
  const{userAgentInfo}=useSelector(state=>state.marketingReducer);
  const Allusers = useSelector(state => state.usersReducer.users);
  const PaymentResponse = useSelector(state => state.paymentReducer.PaymentResponse);
  const paymentStatusResponse = useSelector(state => state.paymentReducer.paymentStatusResponse);
  // const paymentMethod = useSelector(state => state.paymentReducer.paymentMethod);
  // console.log("paymentMethod",paymentMethod)

  console.log("PaymentResponse",PaymentResponse)
  console.log("userAgentInfo",userAgentInfo?.sales_id)


  offers=userOffers?.map(el=>{
    return el?.offer
  });

  offers = offers?.filter(el =>
    el?.status == true && !userAgentInfo?.offers_banned?.includes(el?._id)
);

  console.log('userAgentInfo',userAgentInfo);
  const AdminsUsers = Allusers.filter(user => user.isAdmin === true);


  const handleCountryChange=async(e)=>{
    setSelectedCountry(e.target.value);
    dispatch(getAllCities(e.target.value));
  }

  //----------------------------------------------
  useEffect(() => {
    dispatch(getAllUsers()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);


  useEffect(() => {
    if (!loading) {
      console.log("AdminsUsers", AdminsUsers);
      console.log("First AdminUser", AdminsUsers[0]);
    }
  }, [loading, AdminsUsers]);
  
const inOnlinePayment = async () =>{

  for (let i = 0; i < requestCardsArr.length; i++) {
    if (requestCardsArr[i]?.mobile?.length < 8) {
      return notify("برجاء مراجعة رقم الجوال", "error");
    }
  }


  
  
  if(searchParams.get('marketing_link_number')==null || userAgentInfo?.show_all_sales==false) return notify("هذا الرابط غير صالح","error");
  if(selectedCity=='0' || selectedCountry=='0') return notify(" من فضلك تأكد من اختيار البلد والمدينة","error");
  if(address=='') return notify("من فضلك تأكد من اختيار العنوان","error");

  let selectofferToSend=[];
    selectofferToSend=userOffers?.find(el=>el?.offer==selectedOffer);
    console.log("selectofferToSend",selectofferToSend)
    console.log("selectofferToSend?.offer",selectofferToSend?.offer)



    let searchBySelectedCountryIso = ""
    let searchBySelectedCountrymobile = ""

    if(selectedCountry === "66ded666afbde9bc55517e2c")
    {
      searchBySelectedCountryIso = "QAR";
      searchBySelectedCountrymobile = "+974"
    }
    if(selectedCountry === "66d87f986d779130ba6ba49c")
    {
      searchBySelectedCountryIso = "OMR";
      searchBySelectedCountrymobile = "+968"
    }
    if(selectedCountry === "66c338b1e67a6fe5f149fdf2")
    {
      searchBySelectedCountryIso = "BHD";
      searchBySelectedCountrymobile = "+973"
    }
    if(selectedCountry === "66c33878e67a6fe5f149fdec")
    {
      searchBySelectedCountryIso = "SAR";
      searchBySelectedCountrymobile = "+966"
    }

    setShowPaymentMethodsModal(true);
return;
    console.log("getPaymentMethod")

    await dispatch(getPaymentMethod());
    return

    const data = {
      // name: requestCardsArr[0]?.name,
      // PaymentMethodId: req.body.paymentMethodId,

      displayCurrencyIso: searchBySelectedCountryIso,
      // mobileCountryCode: searchBySelectedCountrymobile,
      // mobile:requestCardsArr[0]?.mobile,
      InvoiceValue: selectofferToSend?.offer?.reducedAmount,
      // offer_name: selectofferToSend?.offer?.offerNameAr,
      CallBackUrl: "http://167.99.88.192/sendOrderSuccessfully",
      ErrorUrl:"http://167.99.88.192/failedToSendOrder",
      // language:"ar"
    };


    console.log('data online', data);


    await dispatch(addPayment({
      data
    }));
    

    // ----------------------------------------------------------------------
    // if(PaymentResponse?.IsSuccess== true){
    //   await dispatch(addOneOrder({
    //     data
    //   }))
    // }
    // ----------------------------------------------------------------------
    setPaymentType('online')
  

}
const onSubmitMethod = async (PaymentMethodId) =>{

  

  console.log("paymentMethodId",PaymentMethodId)

  let selectofferToSend=[];
  selectofferToSend=userOffers?.find(el=>el?.offer==selectedOffer);


    let searchBySelectedCountryIso = ""
    // let searchBySelectedCountrymobile = ""

   
    if(selectedCountry === "66c33878e67a6fe5f149fdec")
    {
      searchBySelectedCountryIso = "SAR";
      // searchBySelectedCountrymobile = "+966"
    }
    if(selectedCountry === "66f02a819fd9514bc4e4d6aa")
    {
      searchBySelectedCountryIso = "SAR";
      // searchBySelectedCountrymobile = "+966"
    }

    const data = {
      // name: requestCardsArr[0]?.name,
      paymentMethodId: PaymentMethodId ,

      displayCurrencyIso: searchBySelectedCountryIso,
      // mobileCountryCode: searchBySelectedCountrymobile,
      // mobile:requestCardsArr[0]?.mobile,
      price: selectofferToSend?.offer?.reducedAmount,
      // offer_name: selectofferToSend?.offer?.offerNameAr,
      callBackUrl: "http://167.99.88.192/SendOrderSuccessfully",
      errorUrl:"http://167.99.88.192/failedToSendOrder",
      // language:"ar"
    };


    console.log('data online', data);
// return;

    await dispatch(addPayment({
      data
    }));




    
    

    // ----------------------------------------------------------------------
    // if(PaymentResponse?.IsSuccess== true){
    //   await dispatch(addOneOrder({
    //     data
    //   }))
    // }
    // ----------------------------------------------------------------------
    setPaymentType('online')
  

}
const inCashPayment = async () =>{

  for (let i = 0; i < requestCardsArr.length; i++) {
    if (requestCardsArr[i]?.mobile?.length < 8) {
      return notify("برجاء مراجعة رقم الجوال", "error");
    }
  }
  
  if(searchParams.get('marketing_link_number')==null || userAgentInfo?.show_all_sales==false) return notify("هذا الرابط غير صالح","error");
  if(selectedCity=='0' || selectedCountry=='0') return notify(" من فضلك تأكد من اختيار البلد والمدينة","error");
  if(address=='') return notify("من فضلك تأكد من اختيار العنوان","error");

    let offerToSend=[];
    offerToSend=userOffers?.find(el=>el?.offer?._id==selectedOffer?._id);
    offerToSend=offerToSend;
    

    console.log('offerToSend',offerToSend);
 
    console.log('userAgentInfo',userAgentInfo);
    const startDate = new Date(userAgentInfo?.start_date);
    const endDate = new Date(userAgentInfo?.end_date);

    const now = new Date();
        console.log("startDate",startDate)
    console.log("now",now)
    console.log("AdminsUsers",AdminsUsers[0]?._id)
console.log("xxxxx",endDate<now)
    let userAgentInfoID =userAgentInfo?.sales_id;
    let adminStatus= false;

    if (startDate > now) {
      notify(`الرابط لم يتم تفعيله بعد، تاريخ البداية : ${startDate}`, "error");
      console.log("Start date is in the future:", startDate);
  }         
    if(endDate<now){
      userAgentInfoID=AdminsUsers[0]?._id;
      adminStatus = true
      console.log("EndDate")

    }
    if (!userAgentInfoID) {
      return notify("يرجي المحاولة مرة اخري ", "error");
    }
    
    //  return;
    
    const data = {
      user: userAgentInfoID,
      user_take_comission: userAgentInfoID,
      city: selectedCity,
      country:selectedCountry,
      offer: offerToSend?._id,
      address: address,
      requestCard: requestCardsArr,
      marketing_link_number:searchParams.get('marketing_link_number'),
      payment_status:'cash',
      admin_request: adminStatus,
    };

    console.log('data after', data);
    
    //return;


    await dispatch(addOneOrder({
      data
    }));
  
   
    setSelectedOffer(0);
    setRequestCardsArr([]);
    setSelectedCity(0);
    setSelectedCountry(0);
    setAddress('');
    setPaymentType('');
    setShowModal(false);
  

}

const firstOnSubmit = () =>{

  for (let i = 0; i < requestCardsArr.length; i++) {
    if (requestCardsArr[i]?.mobile?.length != 10 &&  selectedCountry == "66c33878e67a6fe5f149fdec") {
      return notify("برجاء مراجعة رقم الجوال", "error");
    }
  }


  for (let i = 0; i < requestCardsArr.length; i++) {
    if (requestCardsArr[i]?.identity_number_passport?.length != 10 && selectedCountry == "66c33878e67a6fe5f149fdec") {
      return notify("يرجاء ادخال رقم الهوية او الجواز المكون من ١٠ ارقام  ","error");
    }
    console.log("identity_number_passport?.length",requestCardsArr[i]?.identity_number_passport?.length)
  }
  
  if(searchParams.get('marketing_link_number')==null || userAgentInfo?.show_all_sales==false) return notify("هذا الرابط غير صالح","error");
  if(selectedCity=='0' || selectedCountry=='0') return notify(" من فضلك تأكد من اختيار البلد والمدينة","error");
  if(address=='') return notify("من فضلك تأكد من اختيار العنوان","error");

  setShowModal(true)
}



//   const onSubmit = async () => {

//     for (let i = 0; i < requestCardsArr.length; i++) {
//       if (requestCardsArr[i]?.mobile?.length != 10 &&  selectedCountry == "66c33878e67a6fe5f149fdec") {
//         return notify("برجاء مراجعة رقم الجوال", "error");
//       }
//     }
  
  
//     for (let i = 0; i < requestCardsArr.length; i++) {
//       if (requestCardsArr[i]?.identity_number_passport?.length != 10 && selectedCountry == "66c33878e67a6fe5f149fdec") {
//         return notify("يرجاء ادخال رقم الهوية او الجواز المكون من ١٠ ارقام  ","error");
//       }
//       console.log("identity_number_passport?.length",requestCardsArr[i]?.identity_number_passport?.length)
//     }
    
//     if(searchParams.get('marketing_link_number')==null || userAgentInfo?.show_all_sales==false) return notify("هذا الرابط غير صالح","error");
//     if(paymentType=='') return notify(" من فضلك اختر طريقة الدفع","error");
//     if(selectedCity=='0' || selectedCountry=='0') return notify(" من فضلك تأكد من اختيار البلد والمدينة","error");
//     if(address=='') return notify("من فضلك تأكد من اختيار العنوان","error");

//     let offerToSend=[];
//     offerToSend=userOffers?.find(el=>el?.offer?._id==selectedOffer?._id);
//     offerToSend=offerToSend;
    

//     console.log('offerToSend',offerToSend);
 
//     console.log('userAgentInfo',userAgentInfo);
//     const startDate = new Date(userAgentInfo?.start_date);
//     const endDate = new Date(userAgentInfo?.end_date);

//     const now = new Date();
//         console.log("startDate",startDate)
//     console.log("now",now)
//     console.log("AdminsUsers",AdminsUsers[0]?._id)
// console.log("xxxxx",endDate<now)
//     let userAgentInfoID =userAgentInfo?.sales_id;
//     let adminStatus= false;
//     if (startDate > now) {
//       notify(`الرابط لم يتم تفعيله بعد، تاريخ البداية : ${startDate}`, "error");
//       console.log("Start date is in the future:", startDate);
//   }         
//     if(endDate<now){
//       userAgentInfoID=AdminsUsers[0]?._id;
//       adminStatus = true
//       console.log("EndDate")

//     }
//     if (!userAgentInfoID) {
//       return notify("يرجي المحاولة مرة اخري ", "error");
//     }
  
//   //  return;
   
//     const data = {
//       user: userAgentInfoID,
//       user_take_comission: userAgentInfoID,
//       city: selectedCity,
//       country:selectedCountry,
//       offer: offerToSend?._id,
//       address: address,
//       requestCard: requestCardsArr,
//       marketing_link_number:searchParams.get('marketing_link_number'),
//       payment_status:paymentType,
//       admin_request: adminStatus,
//     };

//     console.log('data after', data);

//     return;

//     await dispatch(addOneOrder({
//       data
//     }));
  
   
//     setSelectedOffer(0);
//     setRequestCardsArr([]);
//     setSelectedCity(0);
//     setSelectedCountry(0);
//     setAddress('');
//   }




useEffect(() => {
  console.log("PaymentResponse",PaymentResponse)
  if (PaymentResponse != null && PaymentResponse?.IsSuccess) {
    const invoiceId = PaymentResponse?.Data?.InvoiceId;
    localStorage.setItem('invoiceId',invoiceId)
    console.log("invoiceId111",invoiceId)


    if (invoiceId) {

      // Check payment status periodically
        const statusData = { key: invoiceId };
       dispatch(paymentStatus({ data: statusData }));

        
          const userAgentInfoID = userAgentInfo?.sales_id;
          const selectofferToSend = userOffers?.find(el => el?.offer == selectedOffer);

          const orderData = {
            user: userAgentInfoID,
            city: selectedCity,
            country: selectedCountry,
            offer: selectofferToSend?._id,
            address: address,
            requestCard: requestCardsArr,
            marketing_link_number: searchParams.get('marketing_link_number'),
            payment_status: 'online',
          };

          const orderDataJson = JSON.stringify(orderData)
          localStorage.setItem("orderDataJson",orderDataJson)


          
        
      

 
      }
      window.location.href = PaymentResponse?.Data?.PaymentURL;    


  }
}, [PaymentResponse]);



  // useEffect(()=>{
  //   if(PaymentResponse != null && PaymentResponse?.IsSuccess== true ){
  //     console.log("xxxxxx",PaymentResponse?.Data?.PaymentURL)

  //   //   let statusData={
  //   //     key:PaymentResponse?.Data?.InvoiceId
  //   //   }
  //   //   console.log("statusData",statusData)
  //   //  dispatch(paymentStatus({statusData}))

  //     window.location.href = PaymentResponse?.Data?.PaymentURL
  //   }      


  // },[PaymentResponse])
  console.log("PaymentResponse?.Data?.InvoiceId",PaymentResponse?.Data?.InvoiceId) 




  return [
    offers,
    selectedOffer,
    setSelectedOffer,
    requestCardsArr, setRequestCardsArr,
    cities,
    countries,
    selectedCity, setSelectedCity,
    selectedCountry,setSelectedCountry,
    handleCountryChange,
    address, setAddress,
    paymentType,
    setPaymentType,
    // onSubmit,
    inOnlinePayment,
    showModal,
    setShowModal,
    firstOnSubmit,
    inCashPayment,
    showPaymentMethodsModal,
    setShowPaymentMethodsModal,
    onSubmitMethod,
  ];
}


