import { useSearchParams } from "react-router-dom";
import Input from "../../Utilities/Input";
import { getAllBanks } from "../../../redux/slices/banks/thunk";
import CircleCheckBoxWithIcon from "../../Utilities/CircleCheckBoxWithIcon";
import { HiPencilSquare } from "react-icons/hi2";
import AddEditCloseBtnGroup from "../../Utilities/AddEditCloseBtnGroup";
import { useEffect , useState } from "react";
import CheckForPermissionTypes from "../../Utilities/CheckForPermissionTypes";
import Select3 from "../../Utilities/Select3";

export default function BanksTabsComponent({ banks, handleUpdateStatus, formik, showItem, resetValues, onSubmit ,Deposites}) {
    const [deposit_operation_type_banks, setdeposit_operation_type_banks] = useState("0");

    const [searchParams, setSearchParams] = useSearchParams();
    let initialValues={
        _id:"",
        settingsType:"",
        nameAr: "",
        nameEn: "",
        bankAccountNumber:"",
        deposit_operation_type_banks:"0",
        status: true
      };

    useEffect(()=>{
        //console.log('use effect');
        formik.setValues(initialValues);
    },[searchParams]);


    if (searchParams.get('section') !== 'Bank') return;

    console.log('BanksTabsComponent');


    const screenID ="665f0fb670673dba321d163a";
    const loggedUser = JSON.parse(localStorage.getItem("user"));
  
     let hasEdit = false;
    let hasAdd = false;
  
    if (!loggedUser?.isAdmin) {
      hasEdit = CheckForPermissionTypes(screenID, 'edit');
      hasAdd = CheckForPermissionTypes(screenID, 'add');
  
    }
    else {
      hasEdit = true;
      hasAdd = true;
    }
  
    console.log('hasEdit', hasEdit);
    console.log('hasAdd', hasAdd);
  //================================================================

    const handeltypeChange = (e) => {
        const selectedDepositID = e.target.value; // this will now be the deposit ID

        setdeposit_operation_type_banks(selectedDepositID);
        let obj = {
          ...formik.values,
          deposit_operation_type_banks: selectedDepositID,
          type: "depositProessType",
        };
    
        console.log("initialValues", initialValues);
        formik.setValues(obj);
      };
  //================================================================
  console.log("=============", formik.values);

//   const banksFilter = banks?.filter((el)=>formik?.values?.deposit_operation_type_banks == el?.deposit_operation_type_banks)
console.log("Deposites",Deposites)

    return (
        <div>
            <br />
            <Select3
            //   name={"Deposites"}
            // value={formik.values.settingsType}
            onChange={(e) => handeltypeChange(e)}
              value={formik.values.deposit_operation_type_banks}
              options={Deposites}
              labelSelect="nameAr"
              labelSelect2="bankAccountNumber"
              defaultOption="من فضلك قم ب اختيار  البنك"
              classnam={"inputsReduisAndPadding2"}
              label={"البنك"}
            />    
                    {/* <select className="w-100 my-3" style={{height:"45px", borderRadius:"4px"}}
              id="deposit_operation_type_banks"
              value={formik.values.settingsType}
              onChange={(e) => handeltypeChange(e)}

              >
                <option value={"0"}>من فضلك اختر نوع عملية الايداع البنكي</option>
                
                <option value={"banking_transfer"}>التحويل البنكي</option> */}

{/*                 
                <option value={"0"}>من فضلك اختر نوع عملية الايداع البنكي</option>
                <option value={"banking_transfer"}>التحويل البنكي</option>
                <option value={"atm"}>الصرافات</option>
                <option value={"network"}>اجهزة الشبكة</option> */}

            {/* </select> */}
            <div className="d-flex justify-content-between responsiveFont responsiveMobileBankBtn " style={{width:"100%"}}>
                <Input name={'nameAr'} label={'اسم البنك باللغة العربية'} value={formik.values.nameAr} onChange={formik.handleChange}  className={"width45"} classnam={"inputsReduisAndPadding2 width100"}/>
                <Input name={'nameEn'} label={'اسم البنك باللغة الانجليزية'} value={formik.values.nameEn} onChange={formik.handleChange}  className={"width45"} classnam={"inputsReduisAndPadding2 width100"}  />
            </div>

            <div className="my-4">
                <Input name={'bankAccountNumber'} bankPattern={true}  label={'رقم حساب البنك'} placeholder={'XXXX-XXXX-XXXX'} value={formik.values.bankAccountNumber} onChange={formik.handleChange} width={'100%'} classnam={"inputsReduisAndPadding2"} />
            </div>

            <AddEditCloseBtnGroup hasAdd={hasAdd} hasEdit={hasEdit} onSubmit={onSubmit} resetValues={resetValues} />

            <div className="header" style={{ marginTop: '30px' }}>
                <div>انواع الحوالات الحالية</div>
            </div>

             <div className="permissionsTable stripe" style={{overflow:"auto"}}>
                <table style={{ borderCollapse: "collapse" , width:"100%"}}>
                    <thead style={{ backgroundColor: 'rgba(242, 246, 255, 1)', padding: "3px" }}>
                        <tr>
                            <th style={{ padding: '5px' }}>نوع الحوالة</th>
                            <th style={{ padding: '5px' }}>تشغيل /ايقاف</th>
                            <th style={{ padding: '5px' }}>تعديل</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            banks?.map((bank, i) => (
                                <tr key={i}>
                                    <td>{bank?.nameAr}</td>
                                    <td>
                                        <div>
                                            <CircleCheckBoxWithIcon disabled={!hasEdit} onClick={() => { handleUpdateStatus(bank) }} check={bank?.status} />
                                        </div>
                                    </td>
                                    <td onClick={() =>{
                                        if(!hasEdit) return;
                                        showItem(bank);
                                    }} style={{ color: "#FEAA00", cursor: "pointer" }}>
                                        تعديل{" "}
                                        <span >
                                            <HiPencilSquare />{" "}
                                        </span>
                                    </td>
                
                                </tr>
                            ))
                        }

                    </tbody>
                </table> 
            </div>

        </div>
    )
}
