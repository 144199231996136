import { useState } from "react";
import Input from "../Utilities/Input";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import Select from "../Utilities/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMembers,
  getAllMembersWithPagination,
} from "../../redux/slices/memberShips/thunk";
import notify from "../../hook/useNotification";
import { Button } from "react-bootstrap";
import demo_excel from "../../Images/memberships.xlsx"; // Importing the Excel file
import { exportToExcel } from "../Utilities/exportToExcel";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { FaRegFileExcel } from "react-icons/fa6";
import CustomDropdown from "../Utilities/CustomDropdown";

export default function SearchComponent({
  members,
  searchWord,
  setSearchWord,
  searchType,
  setSearchType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  sendFrom,
  setSendFrom,
  status,
  setStatus,
  isLoading,
  setIsLoading,
  salesID,
  setSalesID,
  salesSubervisersID,
  setSalesSubervisersID,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  // const [searchWord, setSearchWord] = useState("");
  // const [searchType, setSearchType] = useState(0);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [sendFrom, setSendFrom] = useState("");
  // const [status, setStatus] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const searchTypeOptions = [
    { _id: "name", nameAr: "الاسم" },
    { _id: "membership_number", nameAr: "رقم العضوية" },
    { _id: "price", nameAr: "السعر" },
  ];

  const membersTypeOptions = [
    { _id: "received", nameAr: "مستلمة" },
    { _id: "not_received", nameAr: "غير مستلمة" },
  ];
  const user = JSON.parse(localStorage.getItem("user"));

  const canclelFilter = async () => {
    setSearchWord("");
    setSearchType(0);
    setStartDate("");
    setEndDate("");
    setSendFrom("");
    setStatus("");

    let query = ``;
    if (user?.liquidation_user || user?.userMarketer)
      query += `user=${user?._id}`;

    query += "&page=1";
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    // مسوق
    //  if (user?.userMarketer) query += `user=${user?.father_id}`;

    await dispatch(getAllMembersWithPagination(query));
  };

  const handleSearch = async () => {
    let query = ``;
    // مندوب
   
    if (user?.liquidation_user || user?.userMarketer){
      if (salesSubervisersID == ''){
        query += `user=${user?._id}`};
      }
    else {
      if (salesID != '') query += `user=${salesID}`;

    }
    if (user?.liquidation_user)
    {
      if (salesSubervisersID != '') query += `&user=${salesSubervisersID}`;

    }
    
    // مسوق
    // if (user?.userMarketer) query += `user=${user?.father_id}`;

    if (sendFrom != "") query += `&sent_by=${sendFrom}`;
    if (status != "") query += `&membership_status=${status}`;
    if (startDate != "") query += `&start_date=${startDate}&end_date=${endDate}`;

    if (searchType != "0") query += `&${searchType}=${searchWord}`;


    // if (startDate = '') query += `start_date=${startDate}&end_date=${endDate}&`;
    //if (user?.liquidation_user) query += `user=${user?._id}`;
    //await dispatch(getAllMembers(query));

    query += "&page=1";
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    await dispatch(getAllMembersWithPagination(query));
  };

  const handleSearchByDate = async () => {
    if (startDate == "" || endDate == "")
      return notify("من فضلك ادخل تاريخ بداية ونهاية البحث", "error");

    let query = `start_date=${startDate}&end_date=${endDate}&`;
    if (user?.liquidation_user || user?.userMarketer)
      query += `user=${user?._id}`;
    // مسوق
    // if (user?.userMarketer) query += `user=${user?.father_id}`;
    query += "&page=1";

    setIsLoading(true);
    await dispatch(getAllMembersWithPagination(query));
    setIsLoading(false);
  };
  // const handleDownload = () => {
  //   const csv = convertToCSV(members["data"]);
  //   const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   const url = URL.createObjectURL(csvBlob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "members.csv";
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(url);
  // };
  const handleDownload_excel = () => {
    // Use demo_excel directly as the file path
    const filePath = demo_excel;

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = filePath; // Use the imported file path directly
    anchor.target = "_blank"; // Open in a new tab if needed
    anchor.download = filePath.split("/").pop(); // Set the file name for download

    // Append anchor to body and trigger the download
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
  };
  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((obj) => Object.values(obj).join(",")).join("\n");
    return headers + rows;
  };
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }
      .table-header {
        background-color: #f2f2f2;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `);
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write("<table>");

    // Table headers
    printWindow.document.write("<tr>");
    printWindow.document.write("<th class='table-header'>الاسم</th>");
    printWindow.document.write("<th class='table-header'>رقم العضوية</th>");
    printWindow.document.write("<th class='table-header'>رقم الهوية	</th>");
    printWindow.document.write("<th class='table-header'>الهاتف</th>");
    printWindow.document.write("<th class='table-header'>السعر</th>");
    printWindow.document.write("<th class='table-header'>تاريخ البدايه</th>");
    printWindow.document.write("<th class='table-header'>تاريخ النهايه</th>");

    printWindow.document.write("</tr>");

    // Table data
    members["data"]?.forEach((member) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${member?.name}</td>`);
      printWindow.document.write(`<td>${member?.membership_number}</td>`);
      printWindow.document.write(
        `<td>${member?.identity_number_passport}</td>`
      );
      printWindow.document.write(`<td>${member?.mobile}</td>`);
      printWindow.document.write(`<td>${member?.price}</td>`);
      printWindow.document.write(
        `<td>${member?.start_date.split("T")[0]}</td>`
      );
      printWindow.document.write(`<td>${member?.end_date.split("T")[0]}</td>`);

      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    let csvContent =
      "الاسم,رقم العضوية,رقم الهوية,الهاتف,السعر,تاريخ البدايه,تاريخ النهايه\n";
    members["data"]?.forEach((member) => {
      csvContent += `${member?.name},${member?.membership_number}
      ,${member?.identity_number_passport},${member?.mobile},${member?.price}
      ,${member?.start_date.split("T")[0]},${member?.end_date.split("T")[0]}\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "members.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // const handlePrint = () => {
  //   const table = document.createElement("table");
  //   const headers = Object.keys(members["data"][0]);
  //   const headerRow = table.insertRow();
  //   headers.forEach((header) => {
  //     const th = document.createElement("th");
  //     th.textContent = header;
  //     headerRow.appendChild(th);
  //   });
  //   members["data"].forEach((member) => {
  //     const row = table.insertRow();
  //     headers.forEach((header) => {
  //       const cell = row.insertCell();
  //       cell.textContent = member[header];
  //     });
  //   });

  //   const printWindow = window.open("", "", "height=600,width=800");
  //   printWindow.document.write(
  //     "<html><head><title>Members Table</title></head><body>"
  //   );
  //   printWindow.document.write("<h1>Members Table</h1>");
  //   printWindow.document.write(table.outerHTML);
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();
  //   printWindow.print();
  // };
  const searchdateHandler = () => {
  if (!startDate || !endDate) {
  if (user?.isAdmin && salesID) {
    navigate(`/members/salesRebort?salesID=${salesID}`);
  }else if(salesSubervisersID) {
    navigate(`/members/salesRebort?start_date=${startDate}&end_date=${endDate}&salesSubervisersID=${salesSubervisersID}`);
  } else {
    navigate(`/members/salesRebort`);
  }
} else {
  if (user?.isAdmin && salesID) {
    navigate(`/members/salesRebort?start_date=${startDate}&end_date=${endDate}&salesID=${salesID}`);
  } else if(salesSubervisersID) {
    navigate(`/members/salesRebort?start_date=${startDate}&end_date=${endDate}&salesSubervisersID=${salesSubervisersID}`);
  } else {
    navigate(`/members/salesRebort?start_date=${startDate}&end_date=${endDate}`);
  }
}
  }
  console.log("startDate", startDate);

  const { liquidationUsers, oneUser, users } = useSelector(
    (state) => state.usersReducer
  );

  let sendFromUsers = [];
  if (user?.isAdmin) {
    sendFromUsers = liquidationUsers.map(user => user.fullname);
  } else {
    sendFromUsers = (oneUser?.supervisors_list || []).map(user => user.fullname);
  }
  //-------------------------
 
  //-------------------------

  if (user?.userMarketer) {
    sendFromUsers = users.map(user => user.fullname);
  }

  // console.log("searchType", searchType, searchWord);

  console.log("sendFromUsers", sendFromUsers);

  console.log('salesID', salesID);
  console.log('salesSubervisersID', salesSubervisersID);

  console.log('sendFrom',sendFrom);

  const handleExport = () => {
    exportToExcel(members, "members");
  };

  return (
    <div className="mx-3">
      {isLoading && <Spinner />}

      <div
        className="d-flex justify-content-between my-3"
        style={{ flexWrap: "wrap" }}
      >
        <div className="title">محرك البحث</div>

        <div
          className="d-flex justify-content-between my-3"
          style={{ gap: "20px", flexWrap: "wrap" }}
        >
          <PrintAndDownload
            onDownload={handleDownload}
            onPrint={handlePrint}
            hasExcel={true}
            download_excel={handleExport}
          />

          <div className="d-flex">
            <div className="title">تحميل صيغه ال excel</div>
            <div
              style={{ border: "1px solid #0080fb", height: "33px" }}
              onClick={handleDownload_excel}
            >
              <FaRegFileExcel />
            </div>
          </div>
        </div>
      </div>

      <div
        className="inputContainer"
        style={{ justifyContent: "space-between" }}
      >
        <Select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          label={"بحث حسب"}
          options={searchTypeOptions}
          //labelSelect='nameAr'
          defaultOption="نوع البحث"
          width={"153px"}
          lableClass="mx-auto"
          classnam="inputsReduisAndPadding"
        />
        <Input
          value={searchWord}
          setValue={setSearchWord}
          label={"كلمات البحث"}
          width={"153px"}
          lableClass="mx-auto"
          classnam="inputsReduisAndPadding2"
        />
        {/* {
          <Select
            label={"مرسلة من"}
            options={sendFromUsers}
            value={sendFrom}
            onChange={(e) => setSendFrom(e.target.value)}
            width={"153px"}
            lableClass="mx-auto"
            defaultOption={"مرسلة من"}
            labelSelect="fullname"
            classnam="inputsReduisAndPadding"
          />
        } */}
        {
          <>
            <div style={{ width: "200px" }}>
              <Input
                value={sendFrom}
                setValue={setSendFrom}
                label={"مرسلة من"}
                width={"153px"}
                lableClass="mx-auto"
                classnam="inputsReduisAndPadding2"
              />
              {/* <CustomDropdown
                label="مرسلة من"
                options={sendFromUsers.map(fullname => ({ fullname }))}
                value={sendFrom}
                onChange={setSendFrom}
                placeholder="مرسلة من"
                labelKey="fullname"
                valueKey="fullname"
              /> */}
            </div>
            {
              (user?.isAdmin || user?.is_accountant) && <div style={{ width: "200px" }}>
                <CustomDropdown
                  label="اسم المندوب"
                  options={liquidationUsers}
                  value={salesID}
                  onChange={setSalesID}
                  placeholder="اسم المندوب"
                  labelKey="fullname"
                  valueKey="_id"
                />
              </div>
            }
            {
              ( user?.liquidation_user) && <div style={{ width: "200px" }}>
                <CustomDropdown
                  label="اسم المشرف"
                  options={oneUser?.supervisors_list}
                  value={salesSubervisersID}
                  onChange={setSalesSubervisersID}
                  placeholder="اسم المشرف"
                  labelKey="fullname"
                  valueKey="_id"
                />
              </div>
            }

          </>

        }
        {/* <Select
          label={"مرسلة الي"}
          options={searchTypeOptions}
          width={"153px"}
          lableClass="mx-auto"
        /> */}
        <Select
          label={"حالة العضوية"}
          defaultOption={"حالة العضوية"}
          options={membersTypeOptions}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          width={"153px"}
          lableClass="mx-auto"
          classnam="inputsReduisAndPadding"
        />

        <div className="d-flex flex-column justify-content-end">
          <button
            onClick={() => handleSearch()}
            className="green-filter-btn font inputsReduisAndPadding2 membersBtns flexTextCenter"
          // style={{ width: "132px", border: "none", height: "38px" }}
          >
            {" "}
            بدء البحث{" "}
          </button>
        </div>
        <div className="d-flex flex-column justify-content-end">
          <button
            onClick={() => searchdateHandler()}
            className="green-filter-btn font inputsReduisAndPadding2 membersBtns"
          // style={{ width: "132px", border: "none", height: "38px" }}
          >
            {" "}
            تقرير المبيعات{" "}
          </button>
        </div>
      </div>

      <div
        className="inputContainer w-100"
        style={{ justifyContent: "space-between" }}
      >
        <div className="d-flex justify-content-between my-3 w-100">
          <div
            className="d-flex justify-content-between w-100"
            style={{ gap: "15px", flexWrap: "wrap" }}
          >
            <div className="d-flex flex-column justify-content-end" style={{ flexDirection: "column" }}>
              <div className="title my-3 font">تاريخ بدء البحث</div>
              <div className="d-flex flex-column justify-content-center">
                <input
                  className="title font membersBtns inputsReduisAndPadding2"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ color: "rgba(121, 121, 129, 1)", height: "45px" }}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-end" style={{ flexDirection: "column" }}>
              <div className="title my-3 font">تاريخ نهاية البحث</div>
              <div className="d-flex flex-column justify-content-center">
                <input
                  className="title membersBtns inputsReduisAndPadding2"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ color: "rgba(121, 121, 129, 1)", height: "45px" }}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-end">
              <button
                onClick={() => handleSearchByDate()}
                className="green-filter-btn font membersBtns2 inputsReduisAndPadding2"
                style={{ height: "45px" }}
              >
                {" "}
                فلترة حسب التاريخ{" "}
              </button>
            </div>

            <div className="d-flex flex-column justify-content-end">
              <button
                onClick={() => canclelFilter()}
                className="green-filter-btn primary-btn font membersBtns2 inputsReduisAndPadding2"
                style={{ height: "45px" }}
              >
                {" "}
                الغاء الفلتر{" "}
              </button>
            </div>

            <div className="d-flex flex-column justify-content-end">
              <button
                className="green-filter-btn yellow-btn font membersBtns2 inputsReduisAndPadding2"
                style={{ height: "45px" }}
                onClick={() => handleExport()}
              >
                {" "}
                تصدير الي اكسل{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
