import { useDispatch, useSelector } from "react-redux";
import { getOneUser, updateOneUser, updateUserProfile } from "../../redux/slices/users/thunk";
import { useEffect, useState } from "react";
import { getAllPermissionGroups } from "../../redux/slices/permissions/thunk";
import { useFormik } from "formik";

export default function EditProfileHook() {
    // const[selectedPermissionsIDS,setSelectedPermissionsIDS]=useState([]);
    // const[selectedInheritancePermissionsIDS,setSelectedInheritancePermissionsIDS]=useState([]);

    const user=JSON.parse(localStorage.getItem("user"));
  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(getOneUser({id:user?._id}));
    dispatch(getAllPermissionGroups());
  },[]);

//   useEffect(()=>{
//     setSelectedPermissionsIDS(oneUser?.permissions);
//   },[oneUser]);

  const {oneUser,isLoading} =useSelector(state=>state.usersReducer);
  const   permissionGroups= useSelector(state => state.permissionsReducer.permissionGroups);


//   "_id": "6603ee320f641716748bdbe5",
//   "serialNumber": 1,
//   "fullname": "ayman",
//   "username": "ayman",
//   "email": "ayman@gmail.com",
//   "mobile": "123456277",
//   "city": "66037db911f96cb1ac402c28",
//   "passwordHash": "$2a$10$tUFkvHYBmApO3nT9NNP7/eC39JTmx9tmcwMclCAFiEkXcp4OwBryu",
//   "number_of_supervisors_allowed": 20,
//   "wallet_credit_limit": 42100000,
//   "custody": "",
//   "isAdmin": true,
//   "userFilter": true,
//   "userMarketer": true,
//   "show_mobile_in_users_card_table": true,
//   "printing_possibility": false,
//   "status": true,
//   "createdAt": "2024-03-27T10:00:18.485Z",
//   "updatedAt": "2024-04-18T10:39:53.418Z",
//   "__v": 0,
//   "permissions": [
//       "660e87621dec1bed70141850",
//       "6613db67aec8d3d895e0bac3",
//       "6605685e17a168627f5f8ea4",
//       "661d2f045d002e5ec932afe5"
//   ],
//   "permissions_inheritance": [
//       "660460af420558e821b13992"
//   ],
  const initialValues = {
    serialNumber: oneUser?.serialNumber || "",
    fullname: oneUser?.fullname || "",
    username: oneUser?.username || "",
    password:"",
    email: oneUser?.email || "",
    mobile: oneUser?.mobile || "",
   // city: oneUser?.city._id || cities[0]?._id,
    number_of_supervisors_allowed: oneUser?.number_of_supervisors_allowed || "",
    wallet_credit_limit: oneUser?.wallet_credit_limit || "",
   // permissions:oneUser?.permissions.length>0 ? oneUser?.permissions : permissionGroups[0]?._id ,
    custody: oneUser?.custody || "",
    isAdmin: oneUser?.isAdmin || false,
    userFilter: oneUser?.userFilter || false,
    userMarketer: oneUser?.userMarketer || false,
    show_mobile_in_users_card_table: oneUser?.show_mobile_in_users_card_table || false,
    printing_possibility: oneUser?.printing_possibility || false,
   // status: oneUser?.status || false
};

const formik = useFormik({
    initialValues,
    //validationschema here
    enableReinitialize: true,
  //  onSubmit,
});

const onSubmit=async()=>{
    await dispatch(updateUserProfile({ id:oneUser?._id, data: {...formik.values} }));
}


  console.log('oneUser',oneUser);

  return[
    oneUser,
    formik,
    isLoading,
    permissionGroups,
    onSubmit
    // selectedPermissionsIDS
  ];

}
