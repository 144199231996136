import { createAsyncThunk } from "@reduxjs/toolkit";
import { useGetDataToken } from "../../../hooks/useGetData";
import notify from "../../../hook/useNotification";
import { useUpdateData } from "../../../hooks/useUpdateData";
import { useInsertData } from "../../../hooks/useInsertData";


//get all users
export const getAllUsers = createAsyncThunk(
    "/usersSlice/users",
    async (query='') => {
        try {
            const response = await useGetDataToken(`/api/v1/users${query}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);
export const getAllAdmins = createAsyncThunk(
    "/usersSlice/adminsUsers",
    async (query='') => {
        try {
            const response = await useGetDataToken(`/api/v1/users?isAdmin=true`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//add user
export const addOneUser=createAsyncThunk(
    "/usersSlice/addUser",
    async({data})=>{
        try {
            const response=await useInsertData('/api/v1/users', data);
            console.log('res',response);
            // if(response?.errors) 
            // notify(' حدث خطأ اثناء الاضافة حاول مرة اخري','error');
            // else
            notify(" تم اضافة المستخدم بنجاح", "success");
        
            return response;

        } catch (error) {
           // console.log('uuuuuuuuuuuuuuu',error.response.data);
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else{
            console.log("error?.errors",error);
             notify(error.response.data,"error");
            return notify(" حدث خطأ اثناء الاضافة من فضلك اكمل البيانات","error");

        }
            
        }
    }
);

//get one user
export const getOneUser=createAsyncThunk(
    "/usersSlice/getOneUser",
    async({id})=>{
        try {
            const response = await useGetDataToken(`/api/v1/users/${id}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//update user
export const updateOneUser=createAsyncThunk(
    "/usersSlice/updateUser",
    async ({ id, data }, { rejectWithValue })=>{
        try {
            const response = await useUpdateData(`/api/v1/users/${id}`, data);
            notify(" تم تعديل المستخدم بنجاح ", "success");
            return response;
            
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.response.data,"error");
        }
    }
);

// updateUserProfile
export const updateUserProfile=createAsyncThunk(
    "/usersSlice/updateUserProfile",
    async ({ id, data }, { rejectWithValue })=>{
        try {
            const response = await useUpdateData(`/api/v1/users/${id}`, data);
            notify(" تم تعديل المستخدم بنجاح ", "success");
            return response;
            
        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.response.data,"error");
        }
    }
);



//get users by city
export const getUsersByCity=createAsyncThunk(
    "/usersSlice/usersByCity",
    async(cityID)=>{
        try {
            const response = await useGetDataToken(`/api/v1/users/userByCity?city=${cityID}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data,"error");
        }
    }
);
export const getUsersByCountry=createAsyncThunk(
    "/usersSlice/usersByCountry",
    async(countryID)=>{
        try {
            const response = await useGetDataToken(`/api/v1/users/userByCountry?country=${countryID}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data,"error");
        }
    }
);

//reset users
export const resetUsers=createAsyncThunk(
    "/usersSlice/resetUsers",
    async()=>{
        const users=[];
        return users;
    }
);

//get liquidationUsers
export const getLiquidationUsers=createAsyncThunk(
    "/usersSlice/getLiquidationUsers",
    async()=>{
        try {
            const response = await useGetDataToken(`/api/v1/users`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.response.data,"error");
        }
    }
);
export const getUserSummary = createAsyncThunk(
    "/usersSlice/usersummary",
    async (query='') => {
        try {
            const response = await useGetDataToken(`/api/v1/home/pref?${query}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);

//get all users
export const getAllUsersWithPagination = createAsyncThunk(
    "/usersSlice/getAllUsersWithPagination",
    async (query='') => {
        try {
            if(query=='?') return;
            
            const response = await useGetDataToken(`/api/v1/users${query}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
            return notify(error.response.data,"error");
        }
    }
);
