import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllPermissionGroups } from "../../redux/slices/permissions/thunk";
import { getAllUsers, getAllUsersWithPagination } from "../../redux/slices/users/thunk";
import { useSearchParams } from "react-router-dom";

export default function GetAllUsersHook() {
  // allUsers
  const dispatch=useDispatch();
  const loggedUser=JSON.parse(localStorage.getItem('user')); 
  const [searchParams,setSearchParams]=useSearchParams();


  useEffect(()=>{
    let page=1;
    if(searchParams.get('page')==null){
      //console.log('pppppppppppppppppp')
      searchParams.set('page',1);
      setSearchParams(searchParams);
    }
    else{
      page=searchParams.get('page');
      searchParams.set('page',page);
      setSearchParams(searchParams);
    }
    let query=`?page=${page}`;

    // مندوب
    if(loggedUser&&!loggedUser.isAdmin){
      query+=`&father_id=${loggedUser._id}`;
      //محاسب
      // if(!loggedUser.liquidation_user && !loggedUser.isAdmin) query=``;
    } 
    // مسوق
    if(loggedUser?.userMarketer){
      query+=`&father_id=${loggedUser.father_id}`;
    }

    dispatch(getAllUsersWithPagination(query));

    dispatch(getAllPermissionGroups());

    
  },[]);

    const users=useSelector(state=>state.usersReducer.usersWithPagination); 
    const permissionGroups= useSelector(state => state.permissionsReducer.permissionGroups);

      console.log('users',users);
    const handleSearch=()=>{
        //console.log('ddddddddddddddddddd');
      }

    

      return[
        handleSearch,
        users,
        permissionGroups
      ]
}
