import HeadSection from "../Utilities/HeadSection";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLiquidation } from "../../redux/slices/liquidation/thunk";
import { useSearchParams } from "react-router-dom";
import LiquidationSalesTable from "./LiquidationSalesTable";
import LiquidationsSentSalesTable from "./LiquidationsSentSalesTable";
import AddComponent from "./AddComponent";
import Search2Component from "./Search2Component";
// import { useLocation } from "react-router-dom";

export default function AllLiquidationComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  //const location=useLocation();

  // const accountantPage=location.pathname.includes('/accountant');

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    let query = ``;
    // محاسب
    // if (!user?.isAdmin && !user?.liquidation_user)
    //   query += `accountant_id=${user?._id}`;
    // مندوب
    //  else if(user?.liquidation_user) query+=`sales_id=${user?._id}`;

    if (user?.isAdmin || user?.is_accountant) query = ``;

    //console.log('query',query);

    dispatch(getAllLiquidation(query));
  }, []);


  console.log("user",user)

  const liquidation = useSelector(
    (state) => state.liquidationReducer.liquidation
  );

  // let pageLiquidations = liquidation?.filter(
  //   (item) => item?.status === searchParams.get("liquidation-type")
  // );

  // if (user?.isAdmin)
    let pageLiquidations = liquidation;

  console.log("pageLiquidations", pageLiquidations);

  const handlePrint = () => {
    console.log("11111111111111");
    const table = document.getElementById("liquidationTable");
    if (table) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write(`<style>
              /* Add your CSS styles here */
              body {
                font-family: Arial, sans-serif;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
              }
              th {
                background-color: #f2f2f2;
                border: 1px solid #dddddd;
                padding: 8px;
                text-align: left;
              }
              td {
                border: 1px solid #dddddd;
                padding: 8px;
                text-align: left;
              }
              tr:nth-child(even) {
                background-color: #f9f9f9;
              }
            </style>`);
      printWindow.document.write("</head><body>");
      printWindow.document.write("<table>");
      printWindow.document.write(table.innerHTML);
      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  };

  const handleDownload = () => {
    // Construct CSV content
    let csvContent = "رقم التصفيه,اسم المندوب,تاريخ البدايه,تاريخ النهايه\n";
    liquidation.forEach((deposite) => {
      csvContent += `${deposite?.liquidation_number},${
        deposite?.sales_id.fullname
      },${deposite?.date_from?.split("T")[0]},${
        deposite?.date_to?.split("T")[0]
      }\n`;
    });

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "liquadion.csv";

    // Programmatically click the <a> element to trigger the download
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="firstContainer mx-2">
      <HeadSection label={"التصفية"} />
      <div className="d-flex  justify-content-end  my-4">
        <PrintAndDownload onDownload={handleDownload} onPrint={handlePrint} />
      </div>

      {searchParams.get("liquidation-type") === "new" && <AddComponent />}
      {searchParams.get("liquidation-type") === "send" && (
        <Search2Component liquidation={pageLiquidations} />
      )}

      {searchParams.get("liquidation-type") === "new" && (
        <LiquidationSalesTable liquidation={pageLiquidations} />
      )}
      {searchParams.get("liquidation-type") === "send" && (
        <LiquidationsSentSalesTable liquidation={pageLiquidations} />
      )}
    </div>
  );
}
