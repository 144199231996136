import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { HiPencilSquare } from "react-icons/hi2";

export default function TableComession({ row, setRow }) {
    //const{offers}=useSelector(state=>state.offersReducer);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState({});
    const[commissionValue,setCommissionValue]=useState('');

    console.log('row',row);

    const handleDeleteRow = (id) => {
        console.log('id', id);
        setRow(
            prev => prev.filter(el => el?.offer?._id !== id)
        );
    }

    const handleEditCommission=()=>{
        console.log('handleEditCommission');
        let edittedOffer={
            ...selectedOffer,
            commission:commissionValue
        }
        console.log('edittedOffer',edittedOffer);
        setRow(
            prev=>prev.map(el=>
                el?.offer?._id===selectedOffer?.offer?._id ?
                edittedOffer
                :
                el
            )
        );

        setShowEditModal(false);
        setSelectedOffer({});
        setCommissionValue('');
    }

    // console.log('selectedOffer', selectedOffer);
    // console.log('row', row);

    return (
        <div>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} >
                <Modal.Body>
                    <div className="label"> العمولة</div>
                    <input value={commissionValue} onChange={(e) => setCommissionValue(e.target.value)} className="w-100" placeholder="اختار العمولة" />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center border-0">
                    <Button onClick={() => handleEditCommission()} variant="primary" style={{ backgroundColor: "#0080FB" }}>
                        تعديل
                    </Button>
                </Modal.Footer>
            </Modal>
            <table className="w-100">
                <thead>
                    <tr>
                        <th>العرض</th>
                        <th>العمولة</th>
                        <th> تعديل العمولة </th>
                        {/* <th>حذف</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        row && row?.map((el, i) => (
                            <tr key={i}>
                                <td>
                                    {el?.offer?.offerNameAr}
                                </td>
                                <td>
                                    {el?.commission}
                                </td>
                                <td
                                    onClick={() => {
                                        setCommissionValue(el?.commission);
                                        setShowEditModal(true);
                                        setSelectedOffer(el);
                                    }}
                                    style={{ color: 'rgb(254, 170, 0)', cursor: 'pointer' }}>
                                    تعديل <span><HiPencilSquare /> </span>
                                </td>
                                {/* <td
                                    onClick={() => handleDeleteRow(el?.offer?._id)}
                                    style={{ color: 'red', cursor: 'pointer' }}>
                                    حذف <span><HiPencilSquare /> </span>
                                </td> */}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>

    )
}
