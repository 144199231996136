import UsersHeader from "../Utilities/UsersHeader";
import AllOffersHook from "../../hook/offers/AllOffersHook";
import AddOrEditOfferComponent from "./AddOrEditOfferComponent";
import AllOffersTableComponent from "./AllOffersTableComponent";
import CheckForPermission from "../Utilities/CheckForPermission";
import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";

export default function AllOffersComponent() {
  const [
    formik,
    offers,
    showItem,
    periodTypeOptions,
    resetValues,
    handleUpdateStatus,
    periodsOptions,
    onSubmit,
  ] = AllOffersHook();

  const screenID = "665f0e3e70673dba321d15fd";
  // const loggedUser = JSON.parse(localStorage.getItem('user'));
  //if(!loggedUser?.isAdmin)CheckForPermission(screenID);

  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let hasEdit = false;
  let hasAdd = false;

  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);


  return (
    <div>
      {
        hasAdd != undefined && hasAdd && <UsersHeader title={"اضافة عرض جديد"} currentRoute={"المستخدمين"} />
      }

      <div className="offersLayout" style={{ overflow: "auto" }} >
        <AddOrEditOfferComponent
          formik={formik}
          periodTypeOptions={periodTypeOptions}
          periodsOptions={periodsOptions}
          resetValues={resetValues}
          hasAdd={hasAdd}
          hasEdit={hasEdit}
          onSubmit={onSubmit}
        />
        <div style={{ overflow: "auto" }}>
          <AllOffersTableComponent
            offers={offers}
            handleUpdateStatus={handleUpdateStatus}
            showItem={showItem}
            hasAdd={hasAdd}
            hasEdit={hasEdit}
          />
        </div>

      </div>
    </div>
  );
}
