import { useDispatch, useSelector } from "react-redux";
import CommissionrepoBtns from "../CommisonBtns/CommissionrepoBtns";
import CommissionrepoSearch from "../CommissionRepoSearch/CommissionrepoSearch";
import HeadSection from "../Utilities/HeadSection";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import { useEffect, useState } from "react";
import { getMarketerReport } from "../../redux/slices/marketing/thunk";
import { useSearchParams } from "react-router-dom";


export default function ReportPageComponent() {
   

    const [searchParams,setSearchParams]=useSearchParams();
    const dispatch=useDispatch();
    //  /api/v1/reports?marketing_link_number=58354208
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    let label= loggedInUser?  'تقرير العمولة للمستخدم':'تقرير العمولة للمسوق';

    useEffect(()=>{
      const query=`marketing_link_number=${searchParams.get('marketing_link_number')}`;
      dispatch(getMarketerReport(query));
    },[]);

    const{report}=useSelector(state=>state.marketingReducer);

    console.log('report',report);

  return (
    <div>
        <HeadSection label={label}  />
        <div className='d-flex  justify-content-end firstContainer  my-4'>
                <PrintAndDownload />
        </div>
        <CommissionrepoBtns />
        <div className="p-4 w-100 dashboard" style={{overflow:"hidden"}}>
        <CommissionrepoSearch report={report} />
    </div>
    </div>
  )
}
