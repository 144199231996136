import UserSummary from "../../Components/UserSummary/UserSummary";
import HeadSection from "../../Components/Utilities/HeadSection";
import PrintAndDownload from "../../Components/Utilities/PrintAndDownload";
import SideBar from "../../Components/Utilities/SideBar";
import CheckForPermission from "../../Components/Utilities/CheckForPermission";
import { Navigate } from "react-router-dom";



export default function UserSummaryPage() {
  const screenID="66cc3ef3750aaa0510bd5c01";
  const loggedUser = JSON.parse(localStorage.getItem('user'));

  let access = false;
  if (!loggedUser?.isAdmin) access = CheckForPermission(screenID);
  else access=true;

  if (access == false) return <Navigate to='/setting/profile?section=Profile' />

  return (
    <div
      className="d-flex justify-content-start"
      style={{ minHeight: "700px" }}
    >
      <div>
        <SideBar />
      </div>
      <div className="p-3 w-100 dashboard">
        <HeadSection label={"ملخص المستخدم  "} />
        <div className="d-flex justify-content-end my-4">
          <PrintAndDownload />
        </div>

        <div className="permissionsLayout mt-3">
          <UserSummary />
        </div>
      </div>
    </div>
  );
}
