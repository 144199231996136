import { createSlice } from "@reduxjs/toolkit";
import { getAllCities,getAllCountries, resetCities,addCountry, editCountry, addCity, editCity, getoneCountryById } from "./thunk";

const initialState={
    cities:[],
    countries:[],
    error:null
};

const citiesSlice=createSlice({
    initialState,
    name:"citiesSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllCountries.fulfilled,(state,action)=>{
            state.countries=action.payload;
        });
        builder.addCase(getAllCities.fulfilled,(state,action)=>{
            state.cities=action.payload;
        });
        builder.addCase(getAllCities.rejected,(state,action)=>{
            state.error=action.payload;
        });
        builder.addCase(resetCities.fulfilled,(state,action)=>{
            state.cities=action.payload;
        });

        builder.addCase(addCity.fulfilled,(state,action)=>{

            ///console.log('action.payload',action.payload);

            state.cities=[...state.cities,action?.payload];

        });
        builder.addCase(addCountry.fulfilled,(state,action)=>{
            state.countries=[...state.countries,action?.payload];

        });

        builder.addCase(editCountry.fulfilled, (state, action) => {
            state.countries = state.countries.map((country) =>
              country._id.toString() === action.payload._id.toString() 
            ? { ...country, ...action.payload } : country
            );
          });
        builder.addCase(editCity.fulfilled, (state, action) => {
            state.cities = state.cities.map((city) =>
                city._id.toString() === action.payload._id.toString() 
            ? { ...city, ...action.payload } : city
            );
          });
        //   builder.addCase(getoneCountryById.fulfilled,(state,action)=>{
        //     console.log("action.payload", action.payload)
        //     state.countries=action.payload;
            
        // });

        
    }
});

export default citiesSlice.reducer;