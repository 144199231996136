import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffers } from "../../redux/slices/offers/thunk";
import { getAllCities, getAllCountries } from "../../redux/slices/cities/thunk";
import { getLiquidationUsers, getOneUser } from "../../redux/slices/users/thunk";
import { useLocation } from "react-router-dom";

export default function AddNewMemberHook() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [numberOfCards, setNumOfCards] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(
    location?.state?.offer?._id ? location?.state?.offer?._id : 0
  );
  const [memberComponentNum, setMemberComponentNum] = useState(null);

  const[isLoading,setIsLoading]=useState(false);

  const loggedUser = JSON.parse(localStorage.getItem('user'));
  

  console.log('location', location.state);

  useEffect(() => {
    const get = async () => {
      let query = ``;
      if (loggedUser?.isAdmin || loggedUser?.is_accountant) query += `type=offer`;
      else {
        if (loggedUser?.liquidation_user) query += `type=commission&user=${loggedUser?._id}`;
        if (loggedUser?.userMarketer) query += `type=commission&user=${loggedUser?.father_id}`;
      }
      setIsLoading(true);
     await dispatch(getAllOffers(query));
     await dispatch(getAllCountries());
      if (location?.state?._id) {
        await  dispatch(getAllCities(location?.state?.city?.country?._id));
      }
      // dispatch(getLiquidationUsers());
      if (loggedUser?.liquidation_user) await dispatch(getOneUser({ id: loggedUser?._id }));
      else await dispatch(getLiquidationUsers());

     // console.log('after render');

      setIsLoading(false);

    }

  
    get();

  }, []);

  let offers = [];
  let commission=[];
  const { offers: userOffers } = useSelector(state => state.offersReducer);

  if (loggedUser.isAdmin) {
    offers = userOffers;
  }
  else {
    offers = userOffers?.map(el => {
      return el?.offer
    });
  }
  // const handleAddNewMemberComponent=()=>{

  // }

  //console.log(offers);


  return [
    offers,
    numberOfCards,
    setNumOfCards,
    selectedOffer,
    setSelectedOffer,
    memberComponentNum,
    setMemberComponentNum,
    isLoading,
  ];
}
