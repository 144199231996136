import React, { useState } from "react";
import { Button, Modal, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateOneDeposite } from "../../redux/slices/deposites/thunk";
import Input from "../Utilities/Input";
import { useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";


export default function DepositesTable({ deposites, pageType, hasEdit }) {
  const [note, setNote] = useState("");
  const [showRefusedModel, setShowRefusedModel] = useState(false);
  const [depositeElement, setDepositeElement]= useState(null)
  const dispatch = useDispatch();
  console.log("note1",note)


  const location=useLocation();

  const handleUpdateStatus = async (deposite, archivedstatus) => {
   
    const data = {
      // ...deposites.find((deposite) => deposite._id === id),
      // status_of_deposit: archivedstatus,
      is_archeived : !deposite.is_archeived
    };
console.log("deposite", deposite)
    await dispatch(updateOneDeposite({ id:deposite?._id, data }));
  };
console.log("hasEditInTable",hasEdit)

  return (
    <div>
      <div className="permissionsTableHeader" style={{width:"100%", minWidth:"1000px"}}> قائمة الايداعات </div>
      <div className="permissionsTable mt-2">
        <table id="depositesTable" style={{width:"100%", minWidth:"1000px"}}>
          <thead>
            <tr>
              <th> رقم العملية</th>
              <th> نوع العملية </th>
              <th> المبلغ </th>
              <th> تاريخ التحويل </th>
              <th> اسم المحول </th>
              <th> اسم المندوب </th>
              <th> البنك </th>
              <th> رقم حساب العميل </th>
              <th> مصدر الحوالة </th>
              <th> الحالة </th>
              <th> الارشفة </th>
  { <th> سبب الرفض </th>}
            </tr>
          </thead>
          <tbody>
            {
              location?.pathname=='/archive' ?
              deposites?.map((deposite, i) => (
                <tr key={i}>
                  <td>{deposite?.operation_number}</td>
                  <td>{deposite?.operation_type?.nameAr ? deposite?.operation_type?.nameAr : "المحفظة"}</td>
                  <td>{Math.abs(deposite?.price)} ريال</td>
                  <td>{deposite?.operation_date?.split("T")[0]}</td>
                  <td>{deposite?.adapter_name ? deposite?.adapter_name : "لا يوجد"}</td>
                  <td>{deposite?.adapter_name ? deposite?.adapter_name : "لا يوجد"}</td>
                  <td>
                      <span>{deposite?.bank_id?.nameAr ? deposite?.bank_id?.nameAr : "لا يوجد"}</span>
                      <span>{deposite?.bank_id?.nameAr ? " - " : ""}</span>
                      <span>{deposite?.bank_id?.bankAccountNumber}</span>
                  </td>
                  <td>{deposite?.account_number_for_client ? deposite?.account_number_for_client : "لا يوجد"}</td>
                  <td>{deposite?.deposite_source ? deposite?.deposite_source : "لا يوجد"}</td>
                  <td>
                    <BtnType
                      status={deposite?.status_of_deposit}
                      type={pageType}
                      deposite={deposite}
                      hasEdit={hasEdit}
                      note={note}
                      setNote={setNote}
                      

                      
                    />
                  </td>
                  <td>
                    <ArchivedBtnType
                      archivedstatus={deposite?.status_of_deposit}
                      type={pageType}
                      deposite={deposite}
                      onUpdateStatus={handleUpdateStatus}
                      hasEdit={hasEdit}
                      note={note}
                      setNote={setNote}
                    />
                  </td>
                { deposite?.status_of_deposit === "refused" && <td>
                  <FaEye
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                     { setDepositeElement(deposite)
                      setShowRefusedModel(true)}
                      
                    }
                    color="rgba(249, 32, 122, 1)"
                    size={35}
                  />
                </td>}
                {deposite?.status_of_deposit !== "refused" && <td>
                  لا يوجد
                  </td>}
                </tr>
              ))
              :
              deposites?.data?.map((deposite, i) => (
                <tr key={i}>
                  <td>{deposite?.operation_number}</td>
                  <td>{deposite?.operation_type?.nameAr ? deposite?.operation_type?.nameAr : "المحفظة"}</td>
                  <td>{Math.abs(deposite?.price)} ريال</td>
                  <td>{deposite?.operation_date?.split("T")[0]}</td>
                  <td>{deposite?.adapter_name ? deposite?.adapter_name : "لا يوجد"}</td>
                  <td>{deposite?.user_id ? deposite?.user_id?.fullname : "لا يوجد"}</td>
                  <td>
                      <span>{deposite?.bank_id?.nameAr ? deposite?.bank_id?.nameAr : "لا يوجد"}</span>
                      <span>{deposite?.bank_id?.nameAr ? " - " : ""}</span>
                      <span>{deposite?.bank_id?.bankAccountNumber}</span>
                  </td>
                  <td>{deposite?.account_number_for_client ? deposite?.account_number_for_client : "لا يوجد"}</td>
                  <td>{deposite?.deposite_source ? deposite?.deposite_source : "لا يوجد"}</td>
                  <td>
                    <BtnType
                      status={deposite?.status_of_deposit}
                      type={pageType}
                      deposite={deposite}
                      hasEdit={hasEdit}
                      note={note}
                      setNote={setNote}
                    />
                  </td>
                  <td>
                    <ArchivedBtnType
                      archivedstatus={deposite?.status_of_deposit}
                      type={pageType}
                      deposite={deposite}
                      onUpdateStatus={handleUpdateStatus}
                      hasEdit={hasEdit}
                      
                    />
                  </td>
                { deposite?.status_of_deposit === "refused" && <td>
                  <FaEye
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                     { setDepositeElement(deposite)
                      setShowRefusedModel(true)}
                      
                    }
                    color="rgba(249, 32, 122, 1)"
                    size={35}
                  />
                </td>}
                {deposite?.status_of_deposit !== "refused" && <td>
                  لا يوجد
                  </td>}
                </tr>
              ))}
            
           
          </tbody>
        </table>
      </div>
      <Modal show={showRefusedModel} onHide={() => setShowRefusedModel(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle"> سبب الرفض </h5>

          <div className="label" style={{ display: "flex", justifyContent: "center" }}>
          {depositeElement?.notes ? depositeElement?.notes : "لا يوجد"}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
        
          <Button
            onClick={() => {
              setShowRefusedModel(false);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#F0142F", border: "#F0142F" }}
          >
            إلغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function BtnType({ status, type, deposite, hasEdit , note , setNote }) {
  console.log("note2",note)
  return (
    <>
      {status === "accepted" && (
        <>
          {type === "accountant" && hasEdit ? (
            <DropDown
              class_name={"deposite-accepted-btn"}
              title={"مقبول"}
              deposite={deposite}
              note={note}
              setNote={setNote}
            />
          ) : (
            <div className="deposite-accepted-btn"> مقبول </div>
          )}
        </>
      )}
      {status === "pending" && (
        <>
          {type === "accountant" && hasEdit  ? (
            <DropDown
              class_name={"deposite-accepted-refused"}
              title={"معلقة"}
              deposite={deposite}
              note={note}
              setNote={setNote}
            />
          ) : (
            <div className="deposite-accepted-refused"> معلقة </div>
          )}
        </>
      )}
      {status === "refused" && (
        <>
          {type === "accountant" && hasEdit ? (
            <DropDown
              class_name={"deposite-accepted-pending"}
              title={"مرفوض"}
              deposite={deposite}
              note={note}
              setNote={setNote}
            />
          ) : (
            <div className="deposite-accepted-pending"> مرفوض </div>
          )}
        </>
      )}
    </>
  );
}

function ArchivedBtnType({ archivedstatus, type, deposite, onUpdateStatus, hasEdit }) {
  // const isArchived = archivedstatus === "archived";
  // console.log('deposite._id', deposite)

  return (
    <NavDropdown
       title={deposite?.is_archeived ? "مؤرشف" : "غير مؤرشف"}
      style={{ fontFamily: "Cairo" }}
      disabled={type === "accountant" && hasEdit ? false : true}
    >
      <NavDropdown.Item
        onClick={() =>
          onUpdateStatus(deposite)
        }
      >{deposite?.is_archeived ? "غير مؤرشف" : " مؤرشف"}     </NavDropdown.Item>

      {/* <NavDropdown.Item
        onClick={() =>
          onUpdateStatus(deposite)
        }
      >
        غير مؤرشف
      </NavDropdown.Item> */}
    </NavDropdown>
  );
}

function NotesModal({ show, setShow, HandleUpdateStatus, deposite, note , setNote }) {

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      HandleUpdateStatus("refused")
    }
  };
console.log("note",note)
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <h5 className="h5ModalTitle"> سبب الرفض </h5>
        <Input
          value={note}
          setValue={setNote}
          name={"setNote"}
          label={"سبب الرفض"}
          width={"100%"}
          onKeyPress={handleKeyPress}

        />
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100">
          <Button
            variant="primary"
            className="mx-auto d-flex"
            style={{ background: "rgb(0, 128, 251)" }}
            onClick={() => 
              {HandleUpdateStatus("refused")
              setShow(false)}

            }
          >
            حفظ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function DropDown({ class_name, title, deposite , note , setNote}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const HandleUpdateStatus = async (status) => {
  
    const data = {
      status_of_deposit: status,
      notes: status === "refused" ? note : "" 
      
    };
    // if (status === "archived") {
    //   data.is_archived = true;
    // }

    await dispatch(
      updateOneDeposite({
        id: deposite._id,
        data,
      })
    );
  };

  return (
    <>
      {showModal && (
        <NotesModal
          show={showModal}
          setShow={setShowModal}
          HandleUpdateStatus={HandleUpdateStatus}
          deposite={deposite}
          note={note}
          setNote={setNote}
        />
      )}
      <NavDropdown
        title={title}
        className={`${class_name}`}
        style={{ fontFamily: "Cairo" }}
      >
        <NavDropdown.Item
          onClick={(e) => HandleUpdateStatus(e.target.id)}
          id={"accepted"}
          style={{ textAlign: "center", fontWeight: "600" }}
        >
          مقبول
        </NavDropdown.Item>
        <NavDropdown.Item
          id={"pending"}
          onClick={(e) => HandleUpdateStatus(e.target.id)}
          style={{ textAlign: "center", fontWeight: "600" }}
        >
          معلقة
        </NavDropdown.Item>
        <NavDropdown.Item
          id={"refused"}
          onClick={(e) => setShowModal(true)}
          style={{ textAlign: "center", fontWeight: "600" }}
        >
          مرفوض
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
}
