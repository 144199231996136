import { useSelector } from "react-redux";

export default function CheckForPermissionTypes(screenID,type='') {
    const user=JSON.parse(localStorage.getItem("user"));
    const userPermissions=useSelector(state=>state.permissionsReducer.userScreens);

   // console.log('userPermissions',userPermissions);

    let pageGroup=userPermissions?.permissions?.find(el=>el?._id==screenID);
    console.log('pageGroupsssssssssssss',pageGroup);

    if(!userPermissions?._id) return;

    if(user?.isAdmin) return true;
  
   
    const permission=pageGroup?.permissionTypes?.find(el=>el?.nameEn==type);

    console.log('permission',permission);

    if(permission==undefined) return false;

    if(permission?._id) return true
    else{
      return false;
    }

}
