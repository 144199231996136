import { FaEyeSlash } from "react-icons/fa";
import Input from "../Utilities/Input";
import UsersHeader from "../Utilities/UsersHeader";
import Select from "../Utilities/Select";
import Select3 from "../Utilities/Select3";
import CheckBoxGroup from "../Utilities/CheckBoxGroup";
import add from "../../Images/add.png";
import TableComession from "../Utilities/TableComession";
import { Button, Modal } from "react-bootstrap";
import CircleCheckBox from "../Utilities/CircleCheckBox";
import AddAndEditUserHook from "../../hook/users/AddAndEditUserHook";
import MultiSelect from "../Utilities/MultiSelect";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import EyeButton from "../Utilities/EyeButton";
import AddOfferModal from "./AddOfferModal";
import notify from "../../hook/useNotification";
import { useSelector } from "react-redux";


// import { Formik } from "formik";

export default function AddAndEditUserComponent() {

  // const [mobileError, setMobileError] = useState(false);
  const [row, setRow, showModal, setShowModal, selectedOffer, setSelectedOffer, handleAddRow, formik, countries, cities, permissionGroups, selectedPermissionsIDS, setSelectedPermissionsIDS, selectedInheritancePermissionsIDS, setSelectedInheritancePermissionsIDS, onCountryChange, offers, comission, setComission, offersPanned, setOffersPanned,liquidationUsers, passwordErrorMassage] = AddAndEditUserHook();
  const { oneUser } = useSelector((state) => state.usersReducer);

console.log("permissionGroups",permissionGroups)
  const { id } = useParams();

  const [type, setType] = useState('password');
  let haveEdit = true;

  //console.log('selectedPermissionsIDS',selectedPermissionsIDS);
  console.log('offersPanned', offersPanned);
  console.log('passwordErrorMassage', passwordErrorMassage);
  // const handleAddMemberCard = async () => {
      // if (formik.values.mobile.length < 8) {
  //       setMobileError(true);
      //  return notify("برجاء مراجعة رقم الجوال", "error");
      // }
  //     } else {
  //       setMobileError(false);
  //     }
  //   }

  const loggedUser = JSON.parse(localStorage.getItem('user'));
  // محاسب
  //if (!loggedUser.liquidation_user && !loggedUser.isAdmin) return window.history.back();
  let Showreceipt = true;
  if (!loggedUser.isAdmin) {
    if (oneUser?.receipt_user) {
      Showreceipt = true;
    } else Showreceipt = false;
  }
    // -------توريث صلاحية الظباعة ----------

  let showPrint = true;
  //  لو محاسب شوف عنده الصلاحية دي ولا لا
  if (!loggedUser.isAdmin) {
    if (oneUser?.printing_possibility) {
      showPrint = true;
    } else showPrint = false;
  }
  console.log("loggedUser2",loggedUser?.country?.nameAr)
    // -------توريث صلاحية الظباعة ----------


  // useEffect(()=>{

  // },[offersPanned]);
  //---------------------------------------------
  //---------------------------------------------
  

  return (
    <div>
      {showModal &&
        <AddOfferModal
          showModal={showModal} setShowModal={setShowModal} formik={formik}
          offers={offers} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer}
          comission={comission} setComission={setComission} handleAddRow={handleAddRow}
          offersPanned={offersPanned}
        />
      }

      <UsersHeader title={'اضافة مستخدم جديد'} currentRoute={'المستخدمين'} navigateTo={'/users/add'} />
      <div className="whiteBackground addUser">
        <form onSubmit={formik.handleSubmit} style={{margin:"10px"}}  >

          <div className="inputContainer" style={{justifyContent:"space-between"}}>
            <Input disabled={!haveEdit} value={formik.values.fullname} name={'fullname'} onChange={formik.handleChange} label={'الاسم الثلاثي'} width={'45%'} classnam={"inputsReduisAndPadding2 font"}/>
            <Input disabled={!haveEdit} value={formik.values.username} name={'username'} onChange={formik.handleChange} label={'اسم المستخدم'} width={'45%'} classnam={"inputsReduisAndPadding2 font"}/>
          </div>

          <div className="inputContainer" style={{justifyContent:"space-between"}}>
            <Input disabled={!haveEdit} value={formik.values.email} name={'email'} onChange={formik.handleChange} label={'البريد الإلكتروني'} width={'45%'} classnam={"inputsReduisAndPadding2 font"} />
            {/* <Input disabled={!haveEdit} type={"number"} value={formik.values.mobile} name={'mobile'} onChange={formik.handleChange} label={'رقم الجوال'} width={'325.33px'} /> */}
            <div style={{ width: '45%' }} >
              <div className={`label`}>
                رقم الجوال
              </div>
              <input type="tel"
                id="myNumberInput"
                value={formik.values.mobile}
                name={'mobile'}
                onChange={formik.handleChange}
                label={'رقم الجوال'}
                placeholder={'رقم الجوال'}
                style={{ width: '100%' }}
                className="inputsReduisAndPadding2 font"

              />
            </div>
            {/* <Input disabled={!haveEdit} type="number" value={formik.values.mobile} name={'mobile'} onChange={formik.handleChange} label={'رقم الجوال'} width={'325.33px'} /> */}

          </div>

         { loggedUser.isAdmin && <div className="inputContainer" >
          <Select3 defaultOption={'من فضلك قم ب اختيار البلد'} disabled={!haveEdit} value={formik.values.country} name={'country'} label={'الدولة'} onChange={onCountryChange} options={countries} width={'100%'} classnam={"inputsReduisAndPadding2 font"} />
          </div>}
         { !loggedUser.isAdmin && <div className="inputContainer" >
          <Select3 defaultOption={loggedUser?.country?.nameAr} disabled={true} value={loggedUser?.country?.nameAr} name={'country'} label={'الدولة'} onChange={onCountryChange} options={countries} width={'100%'} classnam={"inputsReduisAndPadding2 font"} />
          </div>}
          <div className="inputContainer" >
            <Select3 defaultOption={'من فضلك قم ب اختيار المدينة'} disabled={!haveEdit} value={formik.values.city} name={'city'} label={'المدينة'} onChange={formik.handleChange} options={cities} width={'100%'} classnam={"inputsReduisAndPadding2 font"} />
          </div>

          <div className="inputContainer" style={{justifyContent:"space-between"}}>
            <Input disabled={!haveEdit} type={type} value={formik.values.password} name={'password'} onChange={formik.handleChange} label={'كلمة المرور'} width={'84%'} classnam={"inputsReduisAndPadding2 font"} />
            <EyeButton type={type} setType={setType} circle={false} />
{  passwordErrorMassage &&  <div style={{
        color: '#6e6e6e',
        padding: '10px',
        fontSize: '14px',
        fontWeight:"600",
        fontFamily:"Cairo"
    }}>
      يجب عليك إدخال كلمة مرور تحتوي على أحرف كبيرة وصغيرة ورقم ورمز يتكون من 8 أحرف على الأقل</div>}          </div>


          <div className="inputContainer">
            <MultiSelect labelForOption={'nameAr'} disabled={!haveEdit} label={'الصلاحيات'} selectedOptionsIDS={selectedPermissionsIDS} setSelectedOptionsIDS={setSelectedPermissionsIDS} options={permissionGroups} width={"100%"} classnam={"inputsReduisAndPadding2WithMinHight font"}/>
            {
              loggedUser.isAdmin &&
              <MultiSelect labelForOption={'nameAr'} disabled={!haveEdit} label={'توريث الصلاحيات'} selectedOptionsIDS={selectedInheritancePermissionsIDS} setSelectedOptionsIDS={setSelectedInheritancePermissionsIDS} options={permissionGroups} width={"100%"}   classnam={"inputsReduisAndPadding2WithMinHight font"}/>

            }
          </div>

          <div className="inputContainer">
            {
              loggedUser.isAdmin &&
              <MultiSelect width={'100%'} labelForOption={'offerNameAr'} disabled={!haveEdit} label={'المنتجات المحظورة'} selectedOptionsIDS={offersPanned} setSelectedOptionsIDS={setOffersPanned} options={offers} classnam={"inputsReduisAndPadding2WithMinHight font"} />
            }


          </div>

          {
            loggedUser.isAdmin && <div className="inputContainer">
              <Input disabled={!haveEdit} value={formik?.values?.custody} name={'custody'} onChange={formik.handleChange} label={'عهدة'} width={'100%'} classnam={"inputsReduisAndPadding2WithMinHight font"} />
            </div>
          }


          {
            !loggedUser.liquidation_user && loggedUser?.isAdmin && <>
              <div className="inputContainer font">
                <div className="d-flex" style={{ gap: '16px', flexWrap:'wrap' }}>
                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.isAdmin} name={'isAdmin'} onChange={formik.handleChange} title='مستخدم مدير النظام' />
                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.liquidation_user} name={'liquidation_user'} onChange={formik.handleChange} title='مستخدم مدير مبيعات' />
                  {/* <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.userMarketer} name={'userMarketer'} onChange={formik.handleChange} title='مستخدم مسوق' /> */}

                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.is_accountant} name={'is_accountant'} onChange={formik.handleChange} title='مستخدم محاسب' />

                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.show_mobile_in_users_card_table} name={'show_mobile_in_users_card_table'} onChange={formik.handleChange} title='اظهار رقم الجوال في جدول البطاقات للمستخدم' />
                {  Showreceipt&&  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.receipt_user} name={'receipt_user'} onChange={formik.handleChange} title='اصدار فاتورة' />}               
               </div>

              </div>
            </>
          }

          {
            loggedUser.liquidation_user  && <>
              <div className="inputContainer font">
                <div className="d-flex font" style={{ gap: '16px' }}>
                  {/* <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.isAdmin} name={'isAdmin'} onChange={formik.handleChange} title='مستخدم مدير النظام' />
                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.liquidation_user} name={'liquidation_user'} onChange={formik.handleChange} title='مستخدم تصفية' />
                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.userMarketer} name={'userMarketer'} onChange={formik.handleChange} title='مستخدم مسوق' />
                  <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.show_mobile_in_users_card_table} name={'show_mobile_in_users_card_table'} onChange={formik.handleChange} title='اظهار رقم الجوال في جدول البطاقات للمستخدم' /> */}
{ Showreceipt&& <CheckBoxGroup disabled={!haveEdit} value={formik?.values?.receipt_user} name={'receipt_user'} onChange={formik.handleChange} title='اصدار فاتورة' />}                </div>

              </div>
            </>
          }

          <div className="inputContainer font">
            {
              (loggedUser?.isAdmin && formik?.values?.liquidation_user)
              &&
              <Input name={'number_of_marketers_allowed'} value={formik.values.number_of_marketers_allowed} onChange={formik.handleChange} disabled={!haveEdit} label={'عدد المسوقين'} width={'100%'} classnam={"inputsReduisAndPadding2 font"} />

            }
          </div>

          {
            (loggedUser.isAdmin && formik?.values?.liquidation_user) &&
            <>
              <div className="inputContainer font" style={{display:"flex", justifyContent:"space-between"}}>
                <Input disabled={!haveEdit} value={formik.values.number_of_supervisors_allowed} name={'number_of_supervisors_allowed'} onChange={formik.handleChange} label={'عدد المشرفين المسموح'} width={'48%'} classnam={"inputsReduisAndPadding2 font"}/>
                <Input disabled={!haveEdit} value={formik.values.wallet_credit_limit} name={'wallet_credit_limit'} onChange={formik.handleChange} label={'الحد الائتماني للمحفظة'} width={'48%'} classnam={"inputsReduisAndPadding2 font"}/>
              </div>
            </>
          }

          {/* {
            formik.values.userMarketer && loggedUser?.isAdmin &&  <div className="inputContainer font" >
            <Select defaultOption={'من فضلك قم ب اختيار المندوب'} disabled={!haveEdit} value={formik.values.father_id} name={'father_id'} label={'المندوب'} onChange={formik.handleChange} labelSelect={'fullname'} options={liquidationUsers} width={'48%'} classnam={"inputsReduisAndPadding2 font"}/>
          </div>
          } */}

          {
            loggedUser?.isAdmin && <div className="inputContainer d-flex flex-column tableComession">
              <div className="header">
                <div> اسعار عمولة العروض للمستخدم</div>

                {/* {haveEdit && <div><button type="button" className="add" onClick={() => setShowModal(true)}>اضافة عمولة <span><img src={add} alt="" /></span> </button>  </div>} */}

              </div>

              <TableComession row={row} setRow={setRow} />

            </div>
          }




          <div className="inputContainer">
{   showPrint &&         <CircleCheckBox disabled={!haveEdit} value={formik?.values?.printing_possibility} name={'printing_possibility'} onChange={formik.handleChange} title={'امكانية الطباعة'} gap={'70px'} />}            <CircleCheckBox disabled={!haveEdit} value={formik?.values?.status} name={'status'} onChange={formik.handleChange} title={'حالة الحساب'} gap={'70px'} />
          </div>
          {
            haveEdit && <div className="inputContainer justify-content-center" >
              <button type="submit" className="add full" style={{marginBottom:"35px"}} > حفظ</button>
            </div>
          }


        </form>

      </div>
    </div>
  )
}
