import CheckForPermissionTypes from "../Utilities/CheckForPermissionTypes";
import UsersHeader from "../Utilities/UsersHeader";
import CreateBoxComponent from "./CreateBoxComponent";

export default function AddUserMarketingComponent() {

  const screenID = "665f0f8070673dba321d162d";
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  let hasEdit = false;
  let hasAdd = false;

  if (!loggedUser?.isAdmin) {
    hasEdit = CheckForPermissionTypes(screenID, 'edit');
    hasAdd = CheckForPermissionTypes(screenID, 'add');

  }
  else {
    hasEdit = true;
    hasAdd = true;
  }

  console.log('hasEdit', hasEdit);
  console.log('hasAdd', hasAdd);

 
  return (
    <div>
      {
       loggedUser.liquidation_user && hasAdd!=undefined&& hasAdd &&<UsersHeader title={"اضافة مسوق"}  hasAdd={hasAdd} />

      }

    {loggedUser.liquidation_user &&  <div className="permissionsLayout mt-4 p-0">
        <div className="permissionsTableHeader">
          <div> انشاء مسوق بالعمولة </div>
        </div>
      </div>}
      <CreateBoxComponent hasEdit={hasEdit} hasAdd={hasAdd} />
    </div>
  );
}
