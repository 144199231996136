import { useLocation } from "react-router-dom";
import HeadSection from "../../Utilities/HeadSection";
import PrintAndDownload from "../../Utilities/PrintAndDownload";
import MemberDetailsCard from "./MemberDetailsCard";
import MainMemberDetailsCard from "./MainMemberDetailsCard";

export default function MemberDetailsComponent() {
  const location = useLocation();
  

  console.log("location", location.state);

  return (
    <div>
      <HeadSection label={"تفاصيل العضوية"} />
      <div className="d-flex justify-content-end my-3 firstContainer">
        {/* <PrintAndDownload /> */}
      </div>

      <div className="permissionsLayout mt-3">
        <div className="mx-3" style={{ width: "97%" }}>
          <div className="permissionsTableHeader w-100 mt-2 font">
            {" "}
            تفاصيل العضوية{" "}
          </div>
          <MainMemberDetailsCard main={location.state} />
          {location.state.membershipCards.map((el, i) => (
            <MemberDetailsCard key={i} data={el} main={location.state} />
          ))}
        </div>
      </div>
    </div>
  );
}
