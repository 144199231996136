import HeadSection from "../Utilities/HeadSection";
export default function MedicalSystemComponent() {
    const cities = [
        { nameAr: 'الرياض' },
        { nameAr: 'جدة' },
        { nameAr: 'تبوك' },
        { nameAr: 'جدة' },
        { nameAr: 'الرياض' }
    ];
    return (
        <div>
            <HeadSection label={"الشبكات الطبية"} />
            <div className="permissionsLayout my-3">
                <div className="container">
                    <div className="row">
                        {cities.map((el, i) => 
                        <div key={i} className="col-4 my-2">
                            <div style={{height:'412px'}}>
                            <div className="city-container" />
                            <div className="H4-Regular">
                              <div> {el.nameAr} </div>  
                            </div>
                            </div>
                            
                        </div>
                    )}

                    </div>
                </div>
            </div>
        </div>
    )
}
