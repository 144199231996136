
export default function AddEditCloseBtnGroup({ onSubmit, resetValues, hasAdd=false
    , hasEdit=false }) {
    return (
        <div className="d-flex justify-content-center mt-4" style={{ gap: '10px', marginTop: '30px' }}>
            {hasAdd != undefined && hasAdd && <button onClick={() => onSubmit()} className="customButton save">حفظ</button>}
            {hasEdit != undefined && hasEdit && <button onClick={() => onSubmit()} className="customButton edit">تعديل</button>}
            <button onClick={() => resetValues()} className="customButton close">اغلاق</button>
        </div>
    )
}
