import { useEffect, useState } from "react";
import NewOrdersHook from "../../hook/orders/NewOrdersHook";
import RequestCardComponent from "./RequestCardComponent";
import style from "./form.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import Select3 from "../../Components/Utilities/Select3"
import { getPaymentMethod } from "../../redux/slices/payment/thunk";



export default function NewOrderPage() {

  const [offers,
    selectedOffer,
    setSelectedOffer,
    requestCardsArr, setRequestCardsArr,
    cities,
    countries,
    selectedCity, setSelectedCity,
    selectedCountry,setSelectedCountry,
    handleCountryChange,
    address, setAddress,
    paymentType,
    setPaymentType,
    // onSubmit,
    inOnlinePayment,
    showModal,
    setShowModal,
    firstOnSubmit,
    inCashPayment,
    showPaymentMethodsModal,
    setShowPaymentMethodsModal,
    onSubmitMethod
  
  ] = NewOrdersHook();

  const [iteratorArr, setIteratorArr] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null); 

  console.log("showModal",showModal)

  const paymentMethods = useSelector(state => state.paymentReducer.paymentMethods);

  console.log("paymentMethods",paymentMethods)


  // const[showIterator,setShowIterator]=useState(false);
  //console.log('selectedOffer',selectedOffer);
  

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getPaymentMethod())
  }, []);

  useEffect(() => {
    setRequestCardsArr([]);
    // setIteratorArr(true);
  }, [iteratorArr]);

  console.log('requestCardsArr', requestCardsArr);
  console.log('iteratorArr', iteratorArr);

  return (
    <div className="d-flex justify-content-center" >
      <div className={style.container}>
        <h1>التسجيل</h1>
        <br/>
        {/* <div className={style.cheakboxsContainer}>
          <div className={style.cheakbox}><label className={style.cheakboxLabel} htmlFor="onlinePayment">
            الدفع اونلاين
          </label>
            <input
              className={style.cheakboxInput}
              type="radio"
              name="payment"
              id="onlinePayment"
              value={'online'}
              onClick={() => inOnlinePayment()}
            /></div>
          <div className={style.cheakbox}><label className={style.cheakboxLabel} htmlFor="payment">
            الدفع عند الاستلام
          </label>
            <input
              className={`inputsReduisAndPadding2 ${style.cheakboxInput}`}
              type="radio"
              id="payment"
              name="payment"
              value={'cash'}
              onClick={() => setPaymentType('cash')}
            ></input></div>
        </div> */}

        <h2 className={style.choose}>اختر العرض المناسب لك</h2>

        <Select3
              name={"select"}
              classnam={"inputsReduisAndPadding2"}
              onChange={(e) =>
                setSelectedOffer(() => {
                  if (e.target.value == 0) {
                    setIteratorArr([]);
                    return;
                  }
                  const offer = offers?.find((el) => el._id == e.target.value);

                  // console.log('offer',offer);

                  let arr = [];
                  for (let i = 0; i < offer.numberOfCards; i++) {
                    arr.push(i);
                  }
                  setIteratorArr([...arr]);
                  return offer;
                })
              }
              // onChange={(e) => {
              //   let isFound = user?.offers_banned.includes(e.target.value);
              //   console.log("isFound", isFound);
              //   setSelectedOffer(e.target.value);
              // }}
              value={selectedOffer?._id ? selectedOffer?._id : selectedOffer}
              options={offers}
              width={"100%"}
              labelSelect="offerNameAr"
              defaultOption="من فضلك قم ب اختيار العرض"
              disabled={paymentType}
              optionsColor={"#797981"}
              color={"#797981"}
              
            />
      
        {
  // Render the first card (index 0) only
  // iteratorArr.length > 0 &&
   (
    <RequestCardComponent
      key={0}
      requestCardsArr={requestCardsArr}
      setRequestCardsArr={setRequestCardsArr}
      selectedOffer={selectedOffer}
      id={1000} // Add unique id for the first card
      address={address}
      setAddress={setAddress}
    />
  )
}

        <div className={style.FormCardAdd}>

          <div className={style.ItemsContainer}>

            <label className={style.ItemLabel} htmlFor="country">
              الدولة
            </label>
            <Select3
            name={"country"}
            onChange={(e) => handleCountryChange(e)}
            options={countries}
            value={selectedCountry?._id}
            width={"100%"}
            labelSelect="nameAr"
            defaultOption="    اختر البلد"
            classnam={"inputsReduisAndPadding2"}
            optionsColor={"#797981"}
            color={"#797981"}
            optionsHeight={"150px"}
            
          />
           
          </div>
          <div className={style.ItemsContainer}>

            <label className={style.ItemLabel} htmlFor="city">
              المدينة
            </label>
            <Select3
              name={"city"}
              onChange={(e) => setSelectedCity(e.target.value)}
              value={selectedCity?._id}
              options={cities}
              width={"100%"}
              labelSelect="nameAr"
              defaultOption=" اختر المدينة"
              classnam={"inputsReduisAndPadding2"}
              optionsColor={"#797981"}
              color={"#797981"}
              optionsHeight={"150px"}


              // disabled={isDisabled}

            />
          </div>
       
        </div>

{
  // Render all other cards, skipping index 0
  selectedOffer !== 0 && iteratorArr.length > 1 && iteratorArr.slice(1).map((el, i) => (
    <RequestCardComponent
      key={i + 1} // Adjust the key for subsequent cards
      requestCardsArr={requestCardsArr}
      setRequestCardsArr={setRequestCardsArr}
      selectedOffer={selectedOffer}
      address={address}
      setAddress={setAddress}
      id={el + 1000} // Continue with unique id
    />
  ))
}
        

{  <button className={style.submit} onClick={() => firstOnSubmit()}> إرسال الان </button>}
{/* {   paymentType  &&     <button className={style.submit} onClick={() => onSubmit()}> إرسال الان2 </button>}    */}



   </div>
   {
    showModal&&<Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Body>
      <h5 className="h5ModalTitle"> طريقة الدفع </h5>

      <div
        className="label"
        style={{ display: "flex", justifyContent: "center" }}
      >
        من فضلك قم باختيار طريقة الدفع
       </div>
    </Modal.Body>

    <Modal.Footer className="d-flex justify-content-center border-0">
    <div className="container mt-2">
        <button style={{
       borderRadius: '20px',
       backgroundColor: isHovered ? 'rgb(153, 180, 153)' : 'rgb(183 188 183)', // Change color on hover
       color: isHovered ? '#000' : '#fff', // Change text color on hover
       width: '90%',
       display: 'block',
       textAlign: 'center',
       margin: '0 auto',
       border: 'none',
       transition: 'background-color 0.3s, color 0.3s' 
        }}
        onClick={() => {
          inOnlinePayment()
          setShowModal(false);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="btn">
                      الدفع اونلاين
            
        </button>
    </div>
    <div className="container mt-2">
        <button style={{
       borderRadius: '20px',
       backgroundColor: isHovered2 ? 'rgb(153, 180, 153)' : 'rgb(183 188 183)', // Change color on hover
       color: isHovered2 ? '#000' : '#fff', // Change text color on hover
       width: '90%',
       display: 'block',
       textAlign: 'center',
       margin: '0 auto',
       border: 'none',
       transition: 'background-color 0.3s, color 0.3s' 
        }}
        onClick={() => {
          inCashPayment()
        }}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
        className="btn">
        الدفع عند الاستلام
            
        </button>

    </div>
     
    </Modal.Footer>
  </Modal>
   }


{
  showPaymentMethodsModal && (
    <Modal show={showPaymentMethodsModal} onHide={() => setShowPaymentMethodsModal(false)}>
      <Modal.Body>
        <h5 className="h5ModalTitle">طريقة الدفع</h5>
        <div
          className="label"
          style={{ display: "flex", justifyContent: "center" }}
        >
          من فضلك قم باختيار طريقة الدفع
        </div>

        <div className="payment-methods mt-3">
          {paymentMethods?.Data?.PaymentMethods?.length > 0 ? (
            paymentMethods.Data.PaymentMethods.map((method) => (
              <div
                key={method.PaymentMethodId}
                className="payment-method-item"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  cursor: "pointer", 
                }}
                onClick={() => {
                  onSubmitMethod(method.PaymentMethodId)
                  console.log("Selected Payment Method:", method.PaymentMethodAr);
                  setSelectedMethod(method); 
                }}
              >
                <span>{method.PaymentMethodAr}</span>
                <span>{method.PaymentMethodEn}</span>
                <img
                  src={method.ImageUrl}
                  alt={method.PaymentMethodEn}
                  style={{ width: '50px', height: 'auto' }}
                />
              </div>
            ))
          ) : (
            <div className="text-center">لا توجد طرق دفع متاحة حالياً</div>
          )}
        </div>

        {/* {selectedMethod && (
          <div className="selected-method mt-3">
            <h6>طريقة الدفع المختارة:</h6>
            <p>{selectedMethod.PaymentMethodAr} ({selectedMethod.PaymentMethodEn})</p>
          </div>
        )} */}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center border-0">
        <div className="container mt-2"></div>
      </Modal.Footer>
    </Modal>
  )

}


    </div>

  );
}
