import { useDispatch, useSelector } from "react-redux"
import { sendWithdrawalRequest } from "../../redux/slices/wallet/thunk";
import { Button, Modal } from "react-bootstrap";
import Select from "../Utilities/Select";
import { useState } from "react";
import Input from "../Utilities/Input";
import notify from "../../hook/useNotification";

export default function SendWithdrawalRequestModal({ showModal, setShowModal, wallet_balance  }) {
    const [selectedAccountant, setSelectedAccountant] = useState('0');
    const [amountToSend, setAmountToSend] = useState("");
    const dispatch = useDispatch();
    const { accountantUsers } = useSelector(state => state.usersReducer);

    const loggedUser = JSON.parse(localStorage.getItem('user'));
    //console.log('accountantUsers',accountantUsers);

    // sendWithdrawalRequest
    const handleSendWithdrawalRequest = async () => {
        setShowModal(false)

        if (isNaN(amountToSend) || amountToSend <= 0) {
            return notify("الرجاء إدخال مبلغ صحيح", "error");
        }

        if (amountToSend > wallet_balance) {
            return notify("رصيدك غير كافي", "error");
        }

        console.log("amountToSend",amountToSend)
        console.log("wallet_balance",wallet_balance)

        const data = {
            user_id: loggedUser?._id,
            accountant_id: selectedAccountant,
            amount_money_withdraw: amountToSend
        };
        console.log("data",data)

        try {
            await dispatch(sendWithdrawalRequest({data}));
        } catch (error) {
            notify("حدث خطأ أثناء إرسال الطلب", "error");
        }
        console.log("amountToSend",amountToSend)
    }

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} >

            <Modal.Body>
                <div className="label d-block text-center">
                    من فضلك قم ب اختيار المحاسب
                </div>

                <div>
                    <Select
                        value={selectedAccountant} onChange={(e) => setSelectedAccountant(e.target.value)}
                        label={" اختر المحاسب"} 
                        options={accountantUsers} 
                        defaultOption={'اختر المحاسب'} 
                        labelSelect="fullname"
                        classnam={"inputsReduisAndPadding2"}

                    />
                </div>
                <Input
            type="number"
            value={amountToSend}
            setValue={setAmountToSend}
            label={"المبلغ"}
            classnam={"inputsReduisAndPadding2"}


          />

                <div className="d-flex justify-content-between mt-3">
                    <Button onClick={() => handleSendWithdrawalRequest()} variant="primary" style={{ backgroundColor: "#0080FB" }}>
                        ارسال
                    </Button>

                    <Button onClick={() => setShowModal(false)} variant="danger">
                        الغاء
                    </Button>
                </div>

            </Modal.Body>

        </Modal>
    )
}
