import React from "react";
import DeliveredCardGraphComponent from "../DeliveredCardGraphComponent/DeliveredCardGraphComponent";
import UnDeliveredCardGraphComponent from "../UnDeliveredCardGraphComponent/UnDeliveredCardGraphComponent";
import TotalDepositsComponent from "../TotalDepositsComponent/TotalDepositsComponent";

import styles from "./MasterLineGraphConponent.module.css";

const MasterLineGraphConponent = ({ statistics }) => {
  const amount_of_deposits = statistics.map((stat) => stat.amount_of_deposits);
  const number_of_undelivered_cards = statistics.map(
    (stat) => stat.number_of_undelivered_cards
  );
  const number_of_delivered_cards = statistics.map(
    (stat) => stat.number_of_delivered_cards
  );

  return (
    <div className={styles.MasterLineGraphConponent}>
      <TotalDepositsComponent data={amount_of_deposits} />
      <UnDeliveredCardGraphComponent data={number_of_undelivered_cards} />
      <DeliveredCardGraphComponent data={number_of_delivered_cards} />
    </div>
  );
};

export default MasterLineGraphConponent;
