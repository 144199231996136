//import React from "react";
import { useDispatch } from "react-redux";
import style from "./WalletCards.module.css";
import { useState } from "react";
import SendWithdrawalRequestModal from "./SendWithdrawalRequestModal";

// sendWithdrawalRequest
const WalletCards = ({wallet, hasFilter}) => {
  const[showWithDrawModal,setShowWithDrawModal]=useState(false);

  //const dispatch=useDispatch();
  let all_deposits=0;
  let all_withdrawal=0;
  let wallet_balance=0;


  const loggedUser= JSON.parse(localStorage.getItem('user')); 

 

  //console.log('wallet loggedUser',loggedUser);
  console.log("wallet",wallet)

  wallet?.map(el=>{
    console.log("el",el)
    if(el?.type=='add'){
      all_deposits+=el?.all_deposits || 0;
      all_withdrawal+=el?.all_withdrawal || 0;
      wallet_balance+=el?.wallet_balance || 0;
    }
    if(el?.type=='sub'){
      all_deposits+=el?.all_deposits || 0;
      all_withdrawal+=el?.all_withdrawal || 0;
      wallet_balance+=el?.wallet_balance || 0;
    }
  });

  let sum=all_deposits-all_withdrawal;
  console.log("sum",sum)
  console.log("wallet_balance",wallet_balance)

  return (
    <div>
          {showWithDrawModal&&<SendWithdrawalRequestModal showModal={showWithDrawModal} setShowModal={setShowWithDrawModal} wallet_balance={wallet_balance}/>}

      <div className={style.CardsParant}>
        {/* <div className={style.card1} style={{marginBottom:"25px", marginTop: "25px"}}>
          <label className={style.card1Label}> رصيد بداية المدة </label>
          <div className={style.card1btn}>
          {wallet[0]?.start_balance ? wallet[0]?.start_balance : 0}
            </div>
        </div>
        <div className={style.card1} style={{marginBottom:"25px", marginTop: "25px"}}>
          <label className={style.card1Label}> رصيد نهاية المدة </label>
          <div className={style.card1btn}>
          {wallet[0]?.end_balance ? wallet[0]?.end_balance : 0}
          </div>
        </div> */}

        {/* <div className={style.card1}>
          <label className={style.card1Label}> الرصيد الكلي </label>
          <div className={style.card1btn}>
            {sum}
          </div>
        </div> */}

       
        
         {/* <div className={`${style.card1}`}>
        <div className={style.Cardshow}>
        <button className="btn btn-success"> ارسال طلب سحب </button>
        </div>
        </div>  */}
       

      </div>
      <div className={style.line}></div>
      <div className={style.Cardshow} style={{marginBottom:"25px", marginTop: "25px"}}>
        <div className={style.Cardshow1}>
        <label className={style.card1Label1}>   اجمالي المسحوبات من المحفظة </label>
          <div className={style.cardbotton1}>{wallet[0]?.all_withdrawal? wallet[0]?.all_withdrawal : 0}</div>

        </div>
        <div className={style.Cardshow2}>
        <label className={style.card1Label1}> اجمالي ايداعات المحفظة </label>
          {/* <div className={style.cardbotton1}>{Number(all_deposits)}</div> */}
          <div className={style.cardbotton1}>{wallet[0]?.all_deposits? wallet[0]?.all_deposits : 0}</div>
        </div>
        
      </div>
      <div className={style.line}></div>
      <div className={style.Cardshow2} style={{marginBottom:"25px", marginTop: "25px"}}>
      <label className={style.card1Label}> رصيد المحفظة  </label>
          <div className={style.cardbtn1}>
          {wallet[0]?.wallet_balance? wallet[0]?.wallet_balance : 0}

          </div>
        </div>
      
      {/* <div className={style.lastBox}>
        <label className={style.lastBoxLabel}>الحد الائتماني للمحفظة</label>
        <div className={style.lastBoxBtn}>
          <span>100,000</span> <span>ريال</span>
        </div>
      </div> */}
      {
              loggedUser?.liquidation_user && !hasFilter &&
      <div className={style.submitBox}>
        <div className={style.submit}  onClick={() => setShowWithDrawModal(true)} >طلب سحب</div>
      </div>
      }
    </div>
  );
};

export default WalletCards;
