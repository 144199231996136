import React, { useState, useEffect } from "react";
import { HiPencilSquare } from "react-icons/hi2";
import markter_link1 from "../../Images/markter_link1.png";
import markter_link2 from "../../Images/markter_link2.png";
import report_img from "../../Images/report-img.png";
import CircleCheckBoxWithIcon from "../Utilities/CircleCheckBoxWithIcon";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import ShareButtons from "../Utilities/ShareButtons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAgentUser } from "../../redux/slices/marketing/thunk";
import notify from "../../hook/useNotification";
import { MdDelete } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";


export default function TableComponent({ userAgents, formik, hasEdit }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);


  const user = JSON.parse(localStorage.getItem("user"));

  const activeUserAgents = userAgents?.filter(el => el.is_disabled == false);

  console.log("activeUserAgents",activeUserAgents)

  // useEffect(() => {
  //   dispatch(getAllUsers(user?._id));

  // }, []);

  // const Allusers = useSelector(state => state.usersReducer.users);
  // const liquidationUsers = Array.isArray(Allusers)
  // ? Allusers.filter(user => user.liquidation_user === true)
  // : [];
  
  // console.log("liquidationUsers",liquidationUsers)



  const handleUpdateStatus = async (el) => {

   // if (!hasEdit) return;
    if (el?.is_sales == true) return notify("لا يمكن التعديل علي الرابط الخاص بالمندوب", "error");
      
    await dispatch(
      updateAgentUser({
        id: el?._id,
        data: {
          show_all_sales: !el?.show_all_sales,
        },
      })
    );
    notify("تم التعديل بنجاح", "success");
  };
  const handleDelete = async (el) => {
    if(el?.is_sales) return  notify("لا يمكن حذف الرابط الخاص بالمندوب", "error")
      console.log("el",el)
    await dispatch(
      updateAgentUser({
        id: el?._id,
        data: {
          is_disabled: true,
        },
      })
    );
     notify(`تم  حذف رابط التسويق  للمسوق ${el?.marketer_name}`, "success")
  }

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      setTooltipText("تم النسخ في الحافظه");
      setShowCopyMessage(true);
      setTimeout(() => {
        setShowCopyMessage(false);
      }, 2000);
    });
  };

  const handleShareClick = (url) => {
    setShareUrl(url);
    setShowShareModal(true);
  };

  const closeShareModal = () => {
    setShowShareModal(false);
    setShareUrl(null);
  };
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }
      .table-header {
        background-color: #f2f2f2;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `);
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write("<table>");

    // Table headers
    printWindow.document.write("<tr>");
    printWindow.document.write("<th class='table-header'>اسم المسوق</th>");
    printWindow.document.write(
      "<th class='table-header'>رقم رابط التسويق</th>"
    );
    printWindow.document.write("<th class='table-header'>مبلغ العمولة	</th>");
    printWindow.document.write("<th class='table-header'>عدد الطلبات </th>");
    printWindow.document.write(
      "<th class='table-header'>العمولة المستحقة</th>"
    );

    printWindow.document.write("</tr>");

    // Table data
    userAgents?.forEach((offer) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${offer?.marketer_name}</td>`);
      printWindow.document.write(`<td>${offer?.marketing_link_number}</td>`);
      printWindow.document.write(`<td>${offer?.amount_of_commission}</td>`);
      printWindow.document.write(`<td>${offer?.num_of_requests}</td>`);
      printWindow.document.write(`<td>${offer?.due_commission}</td>`);

      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    let csvContent =
      "اسم المسوق,رقم رابط التسويق,مبلغ العمولة,عدد الطلبات,العمولة المستحقة \n";
    userAgents?.forEach((offer) => {
      csvContent += `${offer?.marketer_name},${offer?.marketing_link_number},${offer?.amount_of_commission},${offer?.num_of_requests},${offer?.due_commission}\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "markting_link.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
console.log("userAgents",userAgents)
if (userAgents?.start_date > Date.now()) {
  console.log("Bigger than Date.now");
} else {
  console.log("Lesser than Date.now");
}

console.log("userAgentsDate",userAgents[0]?.start_date)

  return (
    <div
      className="permissionsTable p-0 pt-2"
      style={{ width: "100%", overflow: "auto" }}
    >
      <div className="header mt-2" style={{ minWidth: "1000px" }}>
        <div>   المسوقين </div>
        <PrintAndDownload
          color="white"
          fontWeigth={"100"}
          onDownload={handleDownload}
          onPrint={handlePrint}
        />
      </div>
      <table
        className="pt-2"
        style={{ width: "100%", tableLayout: "fixed", minWidth: "1000px" }}
      >
        <thead style={{ background: "rgba(242, 246, 255, 1)" }}>
          <tr>
            <th> اسم المسوق </th>
            <th> رقم رابط التسويق </th>
            <th> مبلغ العمولة </th>
            <th> رابط التسويق </th>
            <th> عدد الطلبات </th>
            <th> العمولة المستحقة </th>
            <th> تقرير </th>
            <th> الحالة </th>
            <th> تعديل </th>
            <th> حذف </th>
          </tr>
        </thead>
        <tbody className="p-2 tableDarkBody">
          {activeUserAgents?.map((el, i) => (
            <tr key={i}>
              <td>{el?.marketer_name}</td>
              <td>{el?.marketing_link_number}</td>
              <td>{el?.amount_of_commission}</td>
              <td>
               

                <div className="d-flex justify-content-center gap-2">
                  {/* رابط تقرير للمسوق */}

                  <img
          onClick={() => {
            const currentDate = new Date();
            const startDate = new Date(el?.start_date); 
            const endDate = new Date(el?.end_date); 
            console.log("DateStart",startDate.toISOString());
            console.log("DateNow", currentDate.toISOString());

            if (startDate => currentDate && endDate <=currentDate ) {
              navigate(`/marketing/report?marketing_link_number=${el?.marketing_link_number}`);

            } else {
             return notify("الرابط غير صالح", "error");

            }
          }}

                  // <--------- Copy --------->
                  
                    // onClick={() =>
                    //   // handleCopyLink(
                    //   //   `${window.location.origin}/marketing/report?marketing_link_number=${el?.marketing_link_number}`
                    //   // )
                    // }
                  // <--------- Copy --------->

                    title="رابط التقرير الخارجي للمسوق"
                    src={markter_link1}
                    alt="Report Link"
                    style={{ cursor: "pointer" }}
                  />
                  {/* رابط التسويق */}
                  <img
                     onClick={() => {
                      const startDate = new Date(el?.start_date);
                      const now = new Date();
                      console.log("startDate",el?.start_date)
                      console.log("now",now)
                  
                      if (startDate > now) {
                        const formattedStartDate = startDate.toISOString().split("T")[0];
                        notify(`الرابط لم يتم تفعيله بعد، تاريخ البداية : ${formattedStartDate}`, "error");
                        console.log("Start date is in the future:", formattedStartDate);
                    }               
          else{
            navigate(`/orders/new?marketing_link_number=${el?.marketing_link_number}&markter=${user?._id}`)
          }
          
                     
                    }}
                  // <--------- Share --------->
                  // onClick={() =>
                  //     handleShareClick(
                  //       `${window.location.origin}/orders/new?marketing_link_number=${el?.marketing_link_number}&markter=${user?._id}`
                  //     )
                  //   }
                  // <--------- Share --------->
                  title="رابط التسويق"
                    src={markter_link2}
                    alt="Marketing Link"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </td>
              <td>{el?.num_of_requests}</td>
              <td>{el?.due_commission}</td>
              <td>
                <img
                 onClick={() =>
                  navigate(`/marketing/report?marketing_link_number=${el?.marketing_link_number}&sales_id=${user?._id}`)
                }
                // <--------- Copy --------->

                  // onClick={() =>
                  //   handleCopyLink(
                  //     `${window.location.origin}/marketing/report?marketing_link_number=${el?.marketing_link_number}&sales_id=${user?._id}`
                  //   )
                  // }
                // <--------- Copy --------->

                  title="رابط التقرير للمندوب"
                  src={report_img}
                  alt="Report"
                  style={{ cursor: "pointer" }}
                />
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <CircleCheckBoxWithIcon
                    onClick={() => {
                      // if (!hasEdit) return;
                      // if (el?.is_sales == true) return notify("لا يمكن التعديل علي الرابط الخاص بالمندوب", "error");

                      handleUpdateStatus(el);
                    }}
                    check={el?.show_all_sales || el?.is_sales}
                    disabled={!hasEdit || el?.is_sales}
                  />
                </div>
              </td>
              <td
                style={{ color: "#FEAA00", cursor: "pointer" }}
                onClick={() => {

                  if (!hasEdit) return;

                  if (el?.is_sales == true) return notify("لا يمكن التعديل علي الرابط الخاص بالمندوب", "error");



                  formik.setValues({
                    ...el,
                    start_date: el.start_date.split("T")[0],
                    end_date: el.end_date.split("T")[0],
                  })
                }
                } // Assuming you have an edit route
              >
                تعديل
                <span>
                  <HiPencilSquare />
                </span>
              </td>
            { !el?.is_sales &&  <td
              style={{ color: "#670000", cursor: "pointer" }}  
              onClick={ ()=>
              {
                setSelectedMember(el);
                setShowModal(true)
              }
               }
              >
                حذف
                <span>
                <MdDelete />
                </span>
              </td>}
            </tr>
          ))}
        </tbody>
      </table>
      {showCopyMessage && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            background: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "8px 16px",
            borderRadius: "10px",
            zIndex: "1000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {tooltipText}
        </div>
      )}
      {showShareModal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            zIndex: "1000",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ShareButtons url={shareUrl} />
          <button
            onClick={closeShareModal}
            className="font"
            style={{
              marginTop: "10px",
              background: "#0080FB",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
            }}
          >
            اغلاق
          </button>
        </div>
      )}

<Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <h5 className="h5ModalTitle">حذف المسوق </h5>

          <div
            className="label"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {`هل تريد حذف رابط التسويق الخاص ب ${selectedMember?.marketer_name}`} 
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center border-0">
          <Button
            onClick={() => {
              handleDelete(selectedMember)
              setShowModal(false);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#F0142F", border: "#F0142F" }}
            >
            حذف
          </Button>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            className="w-25"
            variant="primary"
            style={{ backgroundColor: "#0080FB" , border:"#0080FB" }}
          >
            إلغاء
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
